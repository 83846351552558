import { FC } from 'react'

import { Avatar, AvatarProps } from './Avatar'

export const AvatarTitle: FC<
  Omit<AvatarProps, 'title'> & { label: string }
> = ({ name, size, label, imageUrl, className, status, children, ...rest }) => {
  return (
    <div className={`flex items-center text-0 ${className || ''}`} {...rest}>
      <div>
        <Avatar
          size={size}
          name={name}
          status={status}
          imageUrl={imageUrl}
          className='mr-md'
        >
          {children}
        </Avatar>
      </div>
      <div className={`${size === 'sm' ? 'text-base' : 'text-lg'} font-semibold overflow-hidden whitespace-nowrap text-ellipsis`}>
        {label}
      </div>
    </div>
  )
}
