import { mobileDetect } from '@takle/utils/mobileDetect'
import ReactQuill from 'react-quill'

const isMobile = mobileDetect.mobile()

const moveToNewLine = (quill: ReactQuill | null, range: { index: number }) => {
  if (!quill) {
    return
  }
  const editor = quill.getEditor()
  editor.insertText(range.index, '\n')

  const isBlockquoteActive = !!editor.getFormat().blockquote
  if (isBlockquoteActive) {
    editor.format('blockquote', false)
  }
}

export const bindingKeys = (quill: ReactQuill | null) => ({
  enter: {
    key: 'enter',
    shiftKey: false,
    handler: function (range: { index: number }) {
      if (!isMobile) return
      moveToNewLine(quill, range)
    },
  },
  // New line for alt_enter, option_enter(mac)
  alt_enter: {
    key: 'Enter',
    altKey: true,
    handler: function (range: { index: number }) {
      moveToNewLine(quill, range)
    },
  },
  shift_enter: {
    key: 'Enter',
    shiftKey: true,
    handler: function (range: { index: number }) {
      moveToNewLine(quill, range)
    },
  },
})

export const modules = {
  toolbar: {
    container: [
      'bold',
      'italic',
      'strike',
      'blockquote',
      { list: 'ordered' },
      { list: 'bullet' },
      'code-block',
      'code',
      'link',
      'emoji',
    ],
  },
  clipboard: {
    matchVisual: false,
  },
  magicUrl: {
    urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
    globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
  },
  'emoji-toolbar': true,
  'emoji-textarea': false,
  'emoji-shortname': false,
}
