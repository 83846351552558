import {
  collection,
  doc,
  DocumentSnapshot,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  runTransaction,
  startAfter,
  endBefore,
  where,
} from 'firebase/firestore'
import * as Model from '@takle/models/models'
import { Document, converter } from './converter'
import { Collections, timestamp } from './constants'
import { fireStore } from './firebase'
import { handleError } from '@takle/sentry'

/*

Messages

*/

const parseMessageDocs = async (docs: Document[]) => {
  const messageOrNullArray: (Model.MessageModel | null)[] = await Promise.all(
    docs.map(async doc => {
      try {
        return await Model.decode(doc, Model.TMessageModel)
      } catch (e) {
        handleError(e, true)
        return null
      }
    }),
  )

  return messageOrNullArray.filter(Boolean) as Model.MessageModel[]
}

export function getChannelMessagesCollectionRef(channelId: string) {
  return collection(
    fireStore,
    Collections.Channels,
    channelId,
    Collections.Messages,
  ).withConverter(converter)
}

export function getDirectChannelMessagesCollectionRef(channelId: string) {
  return collection(
    fireStore,
    Collections.DirectChannels,
    channelId,
    Collections.Messages,
  ).withConverter(converter)
}

type NewerChannelMessagesQueryParams = {
  channelId: string
  before: DocumentSnapshot
}

export const getNewerChannelMessages = async ({
  channelId,
  before,
}: NewerChannelMessagesQueryParams) => {
  const constraints: QueryConstraint[] = [
    where('isDeleted', '==', false),
    orderBy(`createdAt`, 'desc'),
    endBefore(before),
  ]

  const messagesQuery = query(
    isChannelDirect(channelId)
      ? getDirectChannelMessagesCollectionRef(channelId)
      : getChannelMessagesCollectionRef(channelId),
    ...constraints,
  )

  const messagesQuerySnapshot = await getDocs(messagesQuery)

  return await parseMessageDocs(
    messagesQuerySnapshot.docs.map(d => d.data()).reverse(),
  )
}

type OlderChannelMessagesQueryParams = {
  channelId: string
  since: Date
  pageSize: number
  after?: DocumentSnapshot
}

export const getOlderChannelMessages = async ({
  channelId,
  since,
  pageSize,
  after,
}: OlderChannelMessagesQueryParams) => {
  const constraints: QueryConstraint[] = [
    where('createdAt', '>=', since),
    where('isDeleted', '==', false),
    orderBy(`createdAt`, 'desc'),
    ...(after ? [startAfter(after)] : []),
    limit(pageSize + 1),
  ]

  const messagesQuery = query(
    isChannelDirect(channelId)
      ? getDirectChannelMessagesCollectionRef(channelId)
      : getChannelMessagesCollectionRef(channelId),
    ...constraints,
  )

  const messagesQuerySnapshot = await getDocs(messagesQuery)

  return {
    messageModels: await parseMessageDocs(
      messagesQuerySnapshot.docs
        .map(d => d.data())
        .slice(0, pageSize)
        .reverse(),
    ),
    hasMoreMessages: !!messagesQuerySnapshot.docs[pageSize],
    lastLoadedDoc: messagesQuerySnapshot.docs[pageSize - 1],
  }
}

export const channelUpdatedMessagesQuery = (channelId: string, since: Date) =>
  query(
    isChannelDirect(channelId)
      ? getDirectChannelMessagesCollectionRef(channelId)
      : getChannelMessagesCollectionRef(channelId),
    where('updatedAt', '>=', since),
    orderBy(`updatedAt`, 'asc'),
  )

export const getNewMessageId = (channelId: string) =>
  doc(
    collection(
      fireStore,
      isChannelDirect(channelId)
        ? Collections.DirectChannels
        : Collections.Channels,
      channelId,
      Collections.Messages,
    ),
  ).id

const getMessageDocRef = (channelId: string, messageId: string) => {
  const isDirect = isChannelDirect(channelId)

  return doc(
    fireStore,
    isDirect ? Collections.DirectChannels : Collections.Channels,
    channelId,
    Collections.Messages,
    messageId,
  )
}

type CheckForMessagePriorDateParams = {
  channelId: string
  date: Date
}

export async function checkForMessagePriorDate({
  channelId,
  date,
}: CheckForMessagePriorDateParams) {
  const isDirect = isChannelDirect(channelId)

  const messages = await getDocs(
    query(
      isDirect
        ? getDirectChannelMessagesCollectionRef(channelId)
        : getChannelMessagesCollectionRef(channelId),
      where('isDeleted', '==', false),
      where('createdAt', '<', date),
      limit(1),
    ),
  )

  return !messages.empty
}

type CreateMessageDocumentParams = {
  text: string
  messageId: string
  workspaceId: string
  channelId: string
  uid: string
  attachments: Array<Model.MessageAttachment>
  mentionUserIds: string[]
}

export async function createMessageDocument({
  messageId,
  text,
  workspaceId,
  attachments,
  channelId,
  uid,
  mentionUserIds,
}: CreateMessageDocumentParams) {
  const messageRef = getMessageDocRef(channelId, messageId)

  await runTransaction(fireStore, async transaction => {
    const messageData: Omit<Model.MessageModel, 'id'> = {
      text,
      createdAt: timestamp(),
      updatedAt: timestamp(),
      channelId,
      workspaceId,
      userId: uid,
      isDeleted: false,
      imageAttachments: attachments.filter(a => a.isImage),
      fileAttachments: attachments.filter(a => !a.isImage),
      mentionUserIds: mentionUserIds.filter(Boolean),
    }

    transaction.set(messageRef, messageData)
  })

  return messageRef.id
}

type UpdateMessageDocumentParams = {
  text: string
  messageId: string
  channelId: string
  attachments: Array<Model.MessageAttachment>
  mentionUserIds: string[]
}

export async function updateMessageDocument({
  channelId,
  messageId,
  text,
  attachments,
  mentionUserIds,
}: UpdateMessageDocumentParams) {
  const messageRef = getMessageDocRef(channelId, messageId)

  await runTransaction(fireStore, async transaction => {
    const messageData: Partial<Model.MessageModel> = {
      text,
      updatedAt: timestamp(),
      imageAttachments: attachments.filter(a => a.isImage),
      fileAttachments: attachments.filter(a => !a.isImage),
      mentionUserIds: mentionUserIds.filter(Boolean),
    }

    transaction.set(messageRef, messageData, { merge: true })
  })

  return messageRef.id
}

type DeleteMessageDocumentParams = {
  messageId: string
  channelId: string
}

export async function deleteMessageDocument({
  channelId,
  messageId,
}: DeleteMessageDocumentParams) {
  const messageRef = getMessageDocRef(channelId, messageId)
  await runTransaction(fireStore, async transaction => {
    const messageData: Partial<Model.MessageModel> = {
      updatedAt: timestamp(),
      isDeleted: true,
    }

    transaction.set(messageRef, messageData, { merge: true })
  })

  return messageRef.id
}

export const isChannelDirect = (channelId: string) => channelId.includes('_')
