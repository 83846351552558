import {
  collection,
  doc,
  getDocs,
  query,
  runTransaction,
  updateDoc,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import * as Model from '@takle/models/models'
import { converter } from './converter'
import { v4 } from 'uuid'
import { Collections, DEFAULTS, timestamp } from './constants'
import { firebaseFunctions, fireStore } from './firebase'
import { getUserDocRef, getUsersCollectionRef } from './users'

/*

Workspaces

*/

export function getWorkspaceDocRef(workspaceId: string) {
  return doc(fireStore, Collections.Workspaces, workspaceId).withConverter(
    converter,
  )
}

export function getWorkspaceInviteEmailDocRef(inviteEmailId: string) {
  return doc(fireStore, Collections.Emails, inviteEmailId).withConverter(
    converter,
  )
}

export function getWorkspacesCollectionRef() {
  return collection(fireStore, Collections.Workspaces).withConverter(converter)
}

export function getDeletedWorkspaceDocRef(workspaceId: string) {
  return doc(
    collection(fireStore, Collections.DeletedWorkspaces),
    workspaceId,
  ).withConverter(converter)
}

export const workspaceUsersQuery = (workspaceId: string) =>
  query(
    getUsersCollectionRef(),
    where(`workspaceIds.${workspaceId}`, '==', true),
  )

export const userWorkspacesQuery = (uid: string) =>
  query(getWorkspacesCollectionRef(), where(`userIds.${uid}`, '==', true))

export const userPendingWorkspacesQuery = (uid: string) =>
  query(
    getWorkspacesCollectionRef(),
    where(`pendingUserIds.${uid}`, '==', true),
  )

export const getUserWorkspaces = async (uid: string) => {
  const workspacesQuerySnapshot = await getDocs(userWorkspacesQuery(uid))

  return await Model.decode(
    workspacesQuerySnapshot.docs.map(d => d.data()),
    Model.TWorkspaceArrayModel,
  )
}

enum WorkspaceCallableFunctions {
  JoinWorkspaceByHash = 'v2addUserToWorkspaceByInviteHash',
  RemoveUserFromWorkspace = 'v2removeUserFromWorkspace',
  AddUserToWorkspace = 'v2addUserToWorkspace',
  GetWorkspacesTotalFilesSize = 'v2getWorkspacesTotalFilesSizeByOwnerId',
  GetInviteDataByHash = 'v2getInviteDataByHash',
  SendInviteEmails = 'v2sendInviteEmails',
}

type JoinWorkspaceByHashParams = {
  inviteHash: string
}

const generateInviteHash = v4

export const joinToWorkspaceByInviteHashCallable = (
  params: JoinWorkspaceByHashParams,
) =>
  httpsCallable<JoinWorkspaceByHashParams, Model.WorkspaceModel>(
    firebaseFunctions,
    WorkspaceCallableFunctions.JoinWorkspaceByHash,
  )(params)

type GetInviteDataByHashParams = {
  inviteHash: string
}

export type GetInviteDataByHashResult = {
  workspaceId: string
  workspaceName: string
  workspaceAccountId: string
  workspaceImageUrl: string
  workspaceDescription: string
}

export const getInviteDataByHashCallable = (
  params: GetInviteDataByHashParams,
) =>
  httpsCallable<GetInviteDataByHashParams, GetInviteDataByHashResult>(
    firebaseFunctions,
    WorkspaceCallableFunctions.GetInviteDataByHash,
  )(params)

type SendInviteEmailsParams = {
  emails: string[]
  workspaceName: string
  inviteLink: string
}

type SendInviteEmailsResult = {
  id: string
}

export const sendInviteEmailsCallable = (params: SendInviteEmailsParams) =>
  httpsCallable<SendInviteEmailsParams, SendInviteEmailsResult>(
    firebaseFunctions,
    WorkspaceCallableFunctions.SendInviteEmails,
  )(params)

type RemoveUserFromWorkspaceParams = {
  uidToRemove: string
  workspaceId: string
}

export const removeUserFromWorkspaceCallable = (
  params: RemoveUserFromWorkspaceParams,
) =>
  httpsCallable<RemoveUserFromWorkspaceParams, Model.WorkspaceModel>(
    firebaseFunctions,
    WorkspaceCallableFunctions.RemoveUserFromWorkspace,
  )(params)

type AddUserToWorkspaceParams = {
  uidToAdd: string
  workspaceId: string
}

export const addUserToWorkspaceCallable = (params: AddUserToWorkspaceParams) =>
  httpsCallable<AddUserToWorkspaceParams, Model.WorkspaceModel>(
    firebaseFunctions,
    WorkspaceCallableFunctions.AddUserToWorkspace,
  )(params)

type CreateWorkspaceDocumentParams = {
  name: string
  uid: string
  accountId: string
}

export const getWorkspacesTotalFilesSizeCallable = (
  params: GetWorkspacesTotalFilesSize,
) =>
  httpsCallable<GetWorkspacesTotalFilesSize, number>(
    firebaseFunctions,
    WorkspaceCallableFunctions.GetWorkspacesTotalFilesSize,
  )(params)

type GetWorkspacesTotalFilesSize = {
  accountId: string
}

export async function createWorkspaceDocument({
  name,
  uid,
  accountId,
}: CreateWorkspaceDocumentParams) {
  const userRef = getUserDocRef(uid)
  const workspaceRef = doc(collection(fireStore, Collections.Workspaces))
  const channelRef = doc(collection(fireStore, Collections.Channels))

  await runTransaction(fireStore, async transaction => {
    const defaultChannelData: Omit<Model.ChannelModel, 'id'> = {
      name: DEFAULTS.ChannelName,
      createdAt: timestamp(),
      workspaceId: workspaceRef.id,
      ownerId: uid,
      isDefault: true,
      userIds: {
        [uid]: true,
      },
    }

    const defaultWorkspaceData: Omit<Model.WorkspaceModel, 'id'> = {
      name,
      settings: DEFAULTS.WorkspaceSettings,
      accountId,
      imageURL: null,
      description: '',
      inviteHash: generateInviteHash(),
      inviteEmailId: '',
      inviteEmail: null,
      defaultChannelId: channelRef.id,
      createdAt: timestamp(),
      userIds: {
        [uid]: true,
      },
      channelIds: {
        [channelRef.id]: true,
      },
      pendingUserIds: {},
    }

    transaction.update(userRef, {
      [`workspaceIds.${workspaceRef.id}`]: true,
    })

    transaction.set(channelRef, defaultChannelData)
    transaction.set(workspaceRef, defaultWorkspaceData)
  })

  return workspaceRef.id
}

type UpdateWorkspaceDocumentParams = {
  workspaceId: string
} & Partial<Model.WorkspaceModelToSave>

export async function updateWorkspaceDocument({
  workspaceId,
  settings,
  name,
  imageURL,
  description,
}: UpdateWorkspaceDocumentParams) {
  const workspaceRef = getWorkspaceDocRef(workspaceId)

  const workspaceData: Partial<Model.WorkspaceModel> = {
    settings,
    name,
    imageURL,
    description,
  }

  await updateDoc(workspaceRef, workspaceData)
}

export async function refreshWorkspaceInvitationHash(workspaceId: string) {
  const workspaceRef = getWorkspaceDocRef(workspaceId)

  const workspaceData: Partial<Model.WorkspaceModel> = {
    inviteHash: generateInviteHash(),
  }

  await updateDoc(workspaceRef, workspaceData)
}

export async function deleteWorkspaceDocument(
  workspaceId: UpdateWorkspaceDocumentParams['workspaceId'],
) {
  const workspaceRef = getWorkspaceDocRef(workspaceId).withConverter(converter)

  const deletedWorkspaceRef = getDeletedWorkspaceDocRef(workspaceId)

  await runTransaction(fireStore, async transaction => {
    const workspaceData = await Model.decode(
      (await transaction.get(workspaceRef)).data(),
      Model.TWorkspaceModel,
    )

    transaction.set(deletedWorkspaceRef, workspaceData)
    transaction.delete(workspaceRef)
  })
}
