import {
  DocumentChange,
  DocumentReference,
  getDoc,
  getDocs,
  onSnapshot,
  Query,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import * as Model from '@takle/models/models'
import * as t from 'io-ts'
import { converter } from './converter'

/*

Generic

*/

type SubscribeToDocUpdatesParams<T> = {
  ref: DocumentReference
  tModel: t.Type<T>
  onData: (v: T) => void
}

export function subscribeToDocUpdates<T>({
  ref,
  tModel,
  onData,
}: SubscribeToDocUpdatesParams<T>) {
  return onSnapshot(ref, async snapshot => {
    try {
      onData(await Model.decode(snapshot.data(), tModel))
    } catch (e) {
      console.warn('subscribeToDocUpdates', e)
    }
  })
}

type SubscribeToCollectionUpdatesParams<T> = {
  query: Query
  tModel: t.Type<T>
  onAdded?: (a: Array<{ model: T; atIndex: number }>) => void
  onRemovedIds?: (ids: Array<string>) => void
  onUpdated?: (a: Array<{ model: T; atIndex: number }>) => void
}

export function subscribeToCollectionUpdates<T>({
  query,
  tModel,
  onAdded,
  onRemovedIds,
  onUpdated,
}: SubscribeToCollectionUpdatesParams<T>) {
  return onSnapshot(
    query,
    async snapshot => {
      const decodeChangeOrReturnNull = async (change: DocumentChange) => {
        try {
          const model = await Model.decode(change.doc.data(), tModel)

          return {
            model,
            atIndex: change.newIndex,
          }
        } catch (e) {
          console.warn('Error decoding change', change.doc.data())
          return null
        }
      }

      const addedModels = snapshot
        .docChanges()
        .filter(change => change.type === 'added')
        .map(decodeChangeOrReturnNull)

      onAdded?.(filterNonNullable(await Promise.all(addedModels)))

      const updatedModels = await Promise.all(
        snapshot
          .docChanges()
          .filter(change => change.type === 'modified')
          .map(decodeChangeOrReturnNull),
      )

      onUpdated?.(filterNonNullable(await Promise.all(updatedModels)))

      const removedIds = snapshot
        .docChanges()
        .filter(change => change.type === 'removed')
        .map(change => change.doc.id)

      onRemovedIds?.(removedIds)
    },
    error => {
      console.error(error)
    },
  )
}

type FetchDocDataParams<T> = {
  ref: DocumentReference
  tModel: t.Type<T>
}

export async function getDocData<T>({
  ref,
  tModel,
}: FetchDocDataParams<T>): Promise<T> {
  const snapshot = await getDoc<any>(ref.withConverter(converter))
  return await Model.decode(snapshot.data(), tModel)
}

type FetchCollectionDataParams<T> = {
  query: Query
  tModel: t.Type<T>
}

export async function getCollectionData<T>({
  query,
  tModel,
}: FetchCollectionDataParams<T>): Promise<Array<T>> {
  const decodeDocOrReturnNull = async (doc: QueryDocumentSnapshot) => {
    try {
      return await Model.decode(doc.data(), tModel)
    } catch (e) {
      console.warn('Error decoding change', doc.data())
      return null
    }
  }

  const snapshot = await getDocs(query)

  return filterNonNullable(
    await Promise.all(snapshot.docs.map(decodeDocOrReturnNull)),
  )
}

function filterNonNullable<T>(items: Array<T>) {
  if (items.some(i => !i)) {
    return items.filter((a): a is NonNullable<typeof a> => a != null)
  }
  return items as NonNullable<T>[]
}
