import ReactQuill, { Quill } from 'react-quill'
import { DeltaStatic, Delta as DeltaType } from 'quill'

import { renderToString } from 'react-dom/server'
import QuillImageDropAndPaste, { ImageData } from 'quill-image-drop-and-paste'

import MagicUrl from 'quill-magic-url'
import QuillMention from 'quill-mention'

import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { CodeBlockIcon } from '@takle/components/icons/CodeBlockIcon'

import 'quill-emoji/dist/quill-emoji.css'
import './Styles/styles.css'
import './Styles/quill-mention.css'
import './Styles/quill-emoji.css'

import {
  LinkWithValidation,
  modules,
  bindingKeys,
  removeFormattingOnPaste,
  emojiConfig,
} from './config'
import { mobileDetect } from '@takle/utils/mobileDetect'

const Delta: typeof DeltaType = Quill.import('delta')

//TODO generic type
export type MentionItem = {
  value: string
  id: string
  displayName: string
  photoURL?: string
}

Quill.register(emojiConfig, true)
Quill.register(LinkWithValidation)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
Quill.register('modules/mentions', QuillMention)
Quill.register('modules/magicUrl', MagicUrl)

const icons = ReactQuill.Quill.import('ui/icons')

// code and code-block has the same icons in quill
icons['code-block'] = renderToString(
  <CodeBlockIcon className='ql-fill ql-code-block' />,
)

export type MessageInputProps = {
  onSubmit: () => Promise<any> | void
  onChange: (d: DeltaStatic, m: string[]) => void
  onEsc?: () => void
  defaultValue?: string
  bodyArea?: () => ReactNode
  disabled?: boolean
  loading?: boolean
  onLoading?: (val: boolean) => void
  children?: ReactNode
  onImagePaste?: (file: File) => void
  getMentionList?: () => MentionItem[]
  renderMentionItem?: (i: MentionItem) => JSX.Element
}

export type MessageData = { text: string; mentions?: MentionItem[] }

export type MessageInputComponent = {
  focus: () => void
  setContent: (val: DeltaStatic) => void
}

export const MessageInput = forwardRef<
  MessageInputComponent,
  MessageInputProps
>(
  (
    {
      onImagePaste,
      onSubmit,
      defaultValue,
      onChange,
      disabled,
      loading,
      onLoading,
      children,
      onEsc,
      getMentionList,
      renderMentionItem,
    },
    ref,
  ) => {
    const [isFocused, setFocused] = useState(false)
    const [mentionListOpened, setMentionListOpened] = useState(false)
    const quillRef = useRef<ReactQuill>(null)
    const isMobile = mobileDetect.mobile()

    const userOs =
      window.navigator.appVersion.indexOf('Win') !== -1 ? 'quill-win' : ''

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          quillRef.current?.focus()
        },
        setContent: (value: DeltaStatic) => {
          quillRef.current?.getEditor().setContents(value, 'silent')
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [quillRef.current],
    )

    const onChangeText = useCallback(() => {
      const delta = quillRef.current?.editor?.getContents()
      if (!delta) {
        return
      }

      const mentions = delta.reduce<Array<string>>((acc, delta) => {
        const mention = delta.insert['mention']

        return mention ? [...acc, mention.id] : acc
      }, [])

      onChange(delta, mentions)
    }, [onChange])

    const submitMessage = useCallback(async () => {
      if (disabled) {
        return
      }
      const text = quillRef.current?.editor?.getText().trim()
      if (text && text.length > 10000) {
        return
      }

      await onSubmit()
    }, [disabled, onSubmit])

    const onKeyDown = useCallback(
      async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (mentionListOpened) {
          return
        }
        const newLineKeyPressed = e.shiftKey || e.ctrlKey || e.altKey

        if (e.key === 'Enter' && !newLineKeyPressed && !isMobile) {
          e.preventDefault()
          submitMessage()
          return
        }
        if (e.key === 'Escape' && !!onEsc) {
          e.preventDefault()
          onEsc()
        }
      },
      [isMobile, mentionListOpened, onEsc, submitMessage],
    )

    const onFocus = useCallback(() => setFocused(true), [])
    const onBlur = useCallback(() => setFocused(false), [])
    const source = useCallback(
      (
        searchTerm: string,
        renderItem: (data: any, searchText: string) => void,
      ) => {
        const data = quillRef.current?.editor?.getContents()
        const mentionList = getMentionList?.()

        const mentions = data?.reduce<MentionItem[]>((acc, delta) => {
          const mention = delta.insert['mention'] as MentionItem
          if (mention) {
            acc.push(mention)
          }
          return acc
        }, [])

        const mentionsToList =
          mentionList?.filter(
            av =>
              av.value.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 &&
              !mentions?.some(m => m.id === av.id),
          ) || []

        renderItem(mentionsToList, searchTerm)
      },
      [getMentionList],
    )
    const onSelect = useCallback(
      (
        item: MentionItem,
        insertItem: (item: MentionItem, val: boolean) => void,
      ) => {
        insertItem(item, true)
        if (mentionListOpened) {
          setMentionListOpened(false)
        }
      },
      [mentionListOpened],
    )

    const quillModules = useMemo(
      () => ({
        ...modules,
        keyboard: {
          bindings: bindingKeys(quillRef.current),
        },
        imageDropAndPaste: {
          handler: (
            _imageDataUrl: string,
            _type: string,
            imageData: ImageData,
          ) => {
            const file = imageData.toFile('Image')

            if (!file) return
            onImagePaste?.(file)
          },
        },
        clipboard: {
          matchers: [
            ['*', removeFormattingOnPaste],
            // Image is handled by imageDropAndPaste, but for other (like videos)
            // we're just ignoring them
            ['img', () => new Delta()],
          ],
        },
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö\u0400-\u04FF]*$/,
          mentionDenotationChars: ['@'],
          onSelect,
          renderItem: (item: MentionItem) => {
            return renderToString(renderMentionItem?.(item) ?? <></>)
          },
          source,
          onOpen: () => setMentionListOpened(true),
          defaultMenuOrientation: 'top',
        },
      }),

      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onSelect, renderMentionItem, source, quillRef.current, onImagePaste],
    )

    return (
      <>
        <div
          className={`bg-800 rounded-xs border transition-colors ${
            isFocused ? 'border-blue' : 'border-400'
          } `}
        >
          <ReactQuill
            ref={quillRef}
            modules={quillModules}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={defaultValue}
            onChange={onChangeText}
            onKeyDown={onKeyDown}
            readOnly={loading}
            className={userOs}
            placeholder='Write a message'
          />
          {children}
        </div>
        <div
          className={`text-xs text-right px-sm pt-xxs transition-colors ${
            isFocused ? 'text-400' : 'text-transparent'
          }`}
        >
          {!isMobile && (
            <>
              <b>Shift+Enter</b> to add a new line
            </>
          )}
        </div>
      </>
    )
  },
)
