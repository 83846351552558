import { action, makeObservable, observable } from 'mobx'

import { Store } from '.'
import { ReactNode } from 'react'
import { ImagePreviewOverlayProps } from '@takle/components/ImagePreviewOverlay'

type ToastData = {
  title?: string
  message: ReactNode
  isWarning?: boolean
}

export type Toast = ToastData & { hide: () => void }

export type Alert = {
  title?: string
  preventDismiss?: boolean
  message: ReactNode
  buttons: {
    label: ReactNode
    onClick?: () => any
  }[]
}

type ImagePreview = Omit<ImagePreviewOverlayProps, 'onClose'>

export class UiStore {
  toasts: Map<number, Toast> = new Map()
  alert: Alert | null = null
  imagePreview: ImagePreview | null = null

  store: Store
  constructor(store: Store) {
    this.store = store

    makeObservable(this, {
      alert: observable,
      toasts: observable,
      imagePreview: observable,
      addToast: action,
      removeToast: action,
      setAlert: action,
      openImagePreview: action,
      closeImagePreview: action,
    })
  }

  showWarningToast(v: ReactNode) {
    this.addToast({
      message: v,
      isWarning: true,
    })
  }

  showInfoToast(v: ReactNode) {
    this.addToast({
      message: v,
      isWarning: false,
    })
  }

  addToast(v: ToastData) {
    const id = new Date().valueOf()
    this.toasts.set(id, { ...v, hide: () => this.removeToast(id) })
  }

  removeToast(id: number) {
    this.toasts.delete(id)
  }

  setAlert(v: Alert | null) {
    this.alert = v
  }
  openImagePreview(v: ImagePreview) {
    this.imagePreview = v
  }
  closeImagePreview() {
    this.imagePreview = null
  }
}
