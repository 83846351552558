import { FirebaseApp, initializeApp } from 'firebase/app'
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
} from 'firebase/firestore'
import { connectDatabaseEmulator, getDatabase } from 'firebase/database'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from 'firebase/remote-config'
import { Capacitor } from '@capacitor/core';
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  connectAuthEmulator, //web only
} from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics'
import { __DEV__ } from '@takle/constants'
import {
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseDatabaseUrl,
  firebaseMeasurementId,
  firebaseMessageAppId,
  firebaseMessageSenderId,
  firebaseProjectId,
  firebaseStorageBucket,
  DEV_EMULATORS_ENABLED,
} from '@takle/config'

const PREFIX = '[Firebase]: '

export const firebaseApp = initializeApp({
  apiKey: firebaseApiKey,
  databaseURL: firebaseDatabaseUrl,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessageSenderId,
  appId: firebaseMessageAppId,
  measurementId: firebaseMeasurementId,
})

initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true })

const getFirebaseAuth = (firebaseApp: FirebaseApp) => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    return getAuth();
  }
};

export const firebaseAuth = getFirebaseAuth(firebaseApp)
export const fireStore = getFirestore(firebaseApp)
export const fireDB = getDatabase(firebaseApp)
export const firebaseFunctions = getFunctions(firebaseApp)
export const fireStorage = getStorage(firebaseApp)
export const fireAnalytics = getAnalytics(firebaseApp)
const remoteConfig = getRemoteConfig(firebaseApp)
remoteConfig.settings.minimumFetchIntervalMillis = 3600000

export enum RemoteConfigFieldName {
  UPLOAD_MAX_FILE_SIZE_BYTES = 'uploadMaxFileSizeBytes',
  MESSAGES_VISIBLE_IN_DAYS_FREE = 'messageVisibleInDays__Free',
  STORAGE_SPACE_BYTES_FREE = 'storageSpaceBytes__Free',
  STORAGE_SPACE_BYTES_PRO = 'storageSpaceBytes__PRO',
  SHOW_QUESTIONNAIRE = 'showQuestionnaire',
}

// Default config value, check firebase remote config
// https://console.firebase.google.com/u/0/project/takle-dev/config
const defaultConfigJson = require('../remoteConfig.json')
remoteConfig.defaultConfig = defaultConfigJson
remoteConfig.settings.fetchTimeoutMillis = 10 * 1000
remoteConfig.settings.minimumFetchIntervalMillis = 60 * 1000

fetchAndActivate(remoteConfig).then(() => {
  if (__DEV__) {
    for (const key of Object.keys(RemoteConfigFieldName)) {
      console.log(
        PREFIX,
        'Remote config value',
        key,
        '=',
        //@ts-expect-error
        getValue(remoteConfig, RemoteConfigFieldName[key]).asNumber(),
      )
    }
  }
})

export const getConfigValue = (v: RemoteConfigFieldName) =>
  getValue(remoteConfig, v)

if (DEV_EMULATORS_ENABLED) {
  if (Capacitor.isNativePlatform()) {
    throw new Error('Simulators support for native platfrom not integrated yet')
  }
  const emulators = {
    hosting: {
      port: 5002,
    },
    functions: {
      port: 5001,
    },
    firestore: {
      port: 8080,
    },
    ui: {
      enabled: true,
    },
    auth: {
      port: 9099,
    },
    storage: {
      port: 9199,
    },
    database: {
      port: 9000,
    },
  }

  console.log(PREFIX, 'Starting emulated firebase environment:')
  console.log(PREFIX, JSON.stringify(emulators, null, '\t '))

  connectAuthEmulator(firebaseAuth, `http://localhost:${emulators.auth.port}`)
  connectFirestoreEmulator(fireStore, 'localhost', emulators.firestore.port)
  connectFunctionsEmulator(
    firebaseFunctions,
    'localhost',
    emulators.functions.port,
  )
  connectStorageEmulator(fireStorage, 'localhost', emulators.storage.port)
  connectDatabaseEmulator(fireDB, 'localhost', emulators.database.port)
}

/*

Document refs

*/
