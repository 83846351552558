import { collection, doc } from 'firebase/firestore'
import { Collections } from './constants'
import { converter } from '@takle/firebase/converter'
import { fireStore } from './firebase'

export function getAccountsCollectionRef() {
  return collection(fireStore, Collections.Accounts).withConverter(converter)
}

export function getAccountDocRef(accountId: string) {
  return doc(fireStore, Collections.Accounts, accountId).withConverter(
    converter,
  )
}
