import {
  differenceInCalendarDays,
  format,
  isFuture,
  isToday,
  isTomorrow,
  isYesterday,
} from 'date-fns'

export const formatName = <T>({
  firstname,
  lastname,
}: T & { firstname: string; lastname: string }) =>
  `${firstname || ''} ${lastname || ''}`.trim()

export const formatTime = (date: Date, showDayIfDifferent: boolean = true) => {
  const isDateToday = isToday(date)
  return format(
    date,
    `HH:mm${showDayIfDifferent && !isDateToday ? ' (MMM do)' : ''}`,
  )
}

export const formatFullDate = (date: Date) => {
  return format(date, `MMMM do`)
}

export const formatRelativeDate = (date: Date, shortDayName?: boolean) => {
  if (isYesterday(date)) {
    return 'Yesterday'
  }
  if (isToday(date)) {
    return 'Today'
  }
  if (isTomorrow(date)) {
    return 'Tomorrow'
  }

  if (Math.abs(differenceInCalendarDays(date, new Date())) <= 6) {
    return `${isFuture(date) ? 'Next' : 'Last'} ${format(
      date,
      shortDayName ? 'EEE' : 'EEEE',
    )}`
  }
  return formatFullDate(date)
}

export const formatChannelName = (val: string) =>
  val.replace(' ', '-').replace(/[^0-9a-zа-я_-]/gi, '')

export const formatFileSize = (fileSize: number) => {
  let fileSizeInBytes = fileSize
  var i = -1
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  do {
    fileSizeInBytes /= 1024
    i++
  } while (fileSizeInBytes > 1024)

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}
