import Compressor from 'compressorjs'
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import * as Model from '@takle/models/models'
import { fireStorage } from './firebase'
import { v4 } from 'uuid'
import { handleError } from '@takle/sentry'

/*

Storage

*/

export const getUserAvatarPath = (userId: string) => `/users/${userId}/avatar/`
export const getWorkspaceAvatarPath = (workspaceId: string) =>
  `/workspace/${workspaceId}/avatar/`

export async function uploadAvatarImage({
  path,
  file,
}: {
  path: string
  file: File
}): Promise<string> {
  const id = v4()

  const storageRef = ref(fireStorage, `${path}/${id}`)
  const compressedFile = await compressSquareImage(file, 350, 350)
  const uploadTask = await uploadBytesResumable(storageRef, compressedFile)
  return await getDownloadURL(uploadTask.ref)
}

export type UploadProgress = {
  fileId: string
  progress: number
  onCancel: () => void
}

export async function uploadMessageFile(options: {
  messageId: string
  workspaceId: string
  channelId: string
  direct: boolean
  fileData: Model.DraftMessageFile
  uploadProgress: ({ fileId, progress, onCancel }: UploadProgress) => void
}): Promise<Model.MessageAttachment & { fileId: string }> {
  const {
    messageId,
    channelId,
    direct,
    uploadProgress,
    workspaceId,
    fileData,
  } = options
  const path = `/workspace/${workspaceId}/${
    direct ? 'direct-channel' : 'channel'
  }/${channelId}/${messageId}`

  const storageRef = ref(fireStorage, `${path}/${fileData.id}`)
  const metadata = {
    customMetadata: { workspaceId },
  }

  const file = fileData.isImage
    ? await compressImage(fileData.file)
    : fileData.file

  const uploadTask = uploadBytesResumable(storageRef, file, metadata)

  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
      )
      uploadProgress({
        progress,
        fileId: fileData.id,
        onCancel: uploadTask.cancel,
      })
    },
    e => handleError(e),
  )

  const snapshot = await uploadTask
  const fileRef = snapshot.ref

  const url = await getDownloadURL(fileRef)

  return {
    isImage: fileData.isImage,
    fileId: fileData.id,
    name: fileData.file.name,
    type: fileData.file.type,
    size: file.size,
    storageLink: fileRef.fullPath,
    url,
  }
}

export async function deleteFile({
  path,
}: {
  path: string
}): Promise<{ path: string }> {
  const storageRef = ref(fireStorage, path)

  await deleteObject(storageRef)

  return {
    path,
  }
}

const compressImage = async (
  file: File,
  maxWidth: number = 2500,
  maxHeight: number = 2500,
) =>
  new Promise<File | Blob>((resolve, reject) => {
    new Compressor(file, {
      quality: 0.75,
      maxWidth,
      maxHeight,
      success(result) {
        resolve(result)
      },
      error(err) {
        reject(err)
      },
    })
  })

const compressSquareImage = async (
  file: File,
  width: number = 1000,
  height: number = 1000,
) =>
  new Promise<File | Blob>((resolve, reject) => {
    new Compressor(file, {
      quality: 0.75,
      resize: 'cover',
      width,
      height,
      success(result) {
        resolve(result)
      },
      error(err) {
        reject(err)
      },
    })
  })
