import { observer } from 'mobx-react-lite'
import { FC, HTMLAttributes, useCallback } from 'react'
import { X, File, Refresh } from 'tabler-icons-react'
import SimpleBar from 'simplebar-react'

import { Avatar } from '@takle/components/Avatar'
import { Text } from '@takle/components/Text'
import {
  DraftMessage,
  EditingMessage,
  Message,
  PendingMessage,
} from '@takle/store/channelsStore'
import { formatFileSize, formatRelativeDate } from '@takle/utils/format'
import {
  DraftMessageFile,
  MessageAttachment,
  PendingMessageFile,
} from '@takle/models/models'

import { Caption } from '@takle/components/Caption'
import { CircleProgress } from '@takle/components/CircleProgress'
import { store } from '@takle/store'
import { User } from '@takle/store/usersStore'
import { downloadStorageFile } from '@takle/utils/download'

type MessageFilesProps = {
  author: User
  message: Message
  onImageLoad?: () => void
}

export const MessageFiles: FC<MessageFilesProps> = observer(
  ({ message, author, onImageLoad }) => {
    const openImageAttachment = useCallback(
      (initialImageIndex: number) => () => {
        store.uiStore.openImagePreview({
          images: message.imageAttachments,
          initialImageIndex,
          content: (
            <Text size='sm' color='200'>
              Uploaded by {author.displayName}{' '}
              {formatRelativeDate(message.createdAt)}
            </Text>
          ),
        })
      },
      [message.imageAttachments, message.createdAt, author.displayName],
    )

    const handleFileClick = (file: MessageAttachment) => {
      downloadStorageFile(file)
    }

    if (!message.fileAttachments.length && !message.imageAttachments.length)
      return null

    return (
      <div className='flex flex-col gap-sm'>
        {!!message.imageAttachments.length && (
          <>
            <Caption label='images' />
            <div className='flex flex-wrap gap-xs'>
              {message.imageAttachments.map((a, i) => (
                <AttachmentImagePreview
                  key={a.storageLink}
                  onClick={openImageAttachment(i)}
                  attachment={a}
                  onImageLoad={onImageLoad}
                  className={`
                  cursor-pointer
                  ${
                    message.imageAttachments.length !== 1
                      ? 'max-w-[120px]'
                      : 'max-w-[240px]'
                  }
                `}
                />
              ))}
            </div>
          </>
        )}

        {!!message.fileAttachments.length && (
          <>
            <Caption label='files' />
            <div className='max-w-xs flex flex-col gap-xs'>
              {message.fileAttachments.map(file => (
                <FilePreview
                  key={file.storageLink}
                  onClick={() => handleFileClick(file)}
                  fileName={file.name}
                  className='!w-[240px] cursor-pointer hover:border-blue hover:ring-2 ring-blue/30'
                >
                  <Text color='400' size='xs'>
                    {formatFileSize(file.size)}
                  </Text>
                </FilePreview>
              ))}
            </div>
          </>
        )}
      </div>
    )
  },
)

type DraftMessageFilesProps = {
  message: DraftMessage
}

export const DraftMessageFiles: FC<DraftMessageFilesProps> = observer(
  ({ message }) => {
    if (!message.files.length) return null

    return (
      <HorizontalContainer>
        {message.files.map(f => (
          <DraftFileListItem
            key={f.id}
            file={f}
            onRemove={file => message.removeFile(file)}
          />
        ))}
      </HorizontalContainer>
    )
  },
)

type EditingMessageFilesProps = {
  message: EditingMessage
}

export const EditingMessageFiles: FC<EditingMessageFilesProps> = observer(
  ({ message }) => {
    if (!message.attachments.length) return null

    return (
      <HorizontalContainer>
        {message.attachments.map(attachment => (
          <EditAttachmentListItem
            key={attachment.storageLink}
            attachment={attachment}
            onRemove={attachment => message.removeAttachment(attachment)}
          />
        ))}
      </HorizontalContainer>
    )
  },
)

type PendingMessageFilesProps = {
  message: PendingMessage
}

export const PendingMessageFiles: FC<PendingMessageFilesProps> = observer(
  ({ message }) => {
    if (!message.files.size) return null

    return (
      <HorizontalContainer>
        {Array.from(message.files.values()).map(f => (
          <PendingFileListItem
            key={f.id}
            file={f}
            onRemove={file => message.cancelPendingFile(file)}
            onRetry={file => message.retryFileUpload(file)}
          />
        ))}
        {message.attachments.map((a, i) => (
          <AttachmentListItem key={i} attachment={a} />
        ))}
      </HorizontalContainer>
    )
  },
)

type DraftFileListItemProps = {
  file: DraftMessageFile
  onRemove: (f: DraftMessageFile) => void
}

const DraftFileListItem: FC<DraftFileListItemProps> = ({
  file: f,
  onRemove,
}) => {
  const fileSize = formatFileSize(f.file.size)
  const fileExtension = f.file.name.split('.').reverse()[0]

  if (f.isImage)
    return (
      <SquareImagePreview url={f.url}>
        <RemoveButton onClick={() => onRemove(f)} />
      </SquareImagePreview>
    )

  return (
    <FilePreview fileExtension={fileExtension} fileName={f.file.name}>
      <Text color='400' size='xs'>
        {fileExtension}, {fileSize}
      </Text>
      <RemoveButton onClick={() => onRemove(f)} />
    </FilePreview>
  )
}

type EditAttachmentListItemProps = {
  attachment: MessageAttachment
  onRemove: (f: MessageAttachment) => void
}

const EditAttachmentListItem: FC<EditAttachmentListItemProps> = ({
  attachment: a,
  onRemove,
}) => {
  if (a.isImage)
    return (
      <SquareImagePreview url={a.url}>
        <RemoveButton onClick={() => onRemove(a)} />
      </SquareImagePreview>
    )

  const fileSize = formatFileSize(a.size)
  const fileExtension = a.name.split('.').reverse()[0]
  return (
    <FilePreview fileExtension={fileExtension} fileName={a.name}>
      <Text color='400' size='xs'>
        {fileExtension}, {fileSize}
      </Text>
      <RemoveButton onClick={() => onRemove(a)} />
    </FilePreview>
  )
}

type PendingFileListItemProps = {
  file: PendingMessageFile
  onRemove: (f: PendingMessageFile) => void
  onRetry: (f: PendingMessageFile) => void
}

const PendingFileListItem: FC<PendingFileListItemProps> = ({
  file: f,
  onRetry,
  onRemove,
}) => {
  if (f.isImage)
    return (
      <SquareImagePreview
        url={f.url}
        className={`${f.hasError && 'border-red'} relative`}
      >
        <div className='absolute rounded-xs bg-800/60 left-0 right-0 top-0 bottom-0 flex flex-col justify-center items-center'>
          {!!f.progress && (
            <CircleProgress progress={f.progress} className='absolute' />
          )}
          {f.hasError && (
            <div className='absolute p-sm'>
              <Refresh onClick={() => onRetry(f)} className='text-0' />
            </div>
          )}
        </div>

        <RemoveButton onClick={() => onRemove(f)} />
      </SquareImagePreview>
    )

  const fileSize = formatFileSize(f.file.size)
  const fileExtension = f.file.name.split('.').reverse()[0]
  return (
    <FilePreview
      fileExtension={fileExtension}
      fileName={f.file.name}
      className={f.hasError ? 'border-red' : undefined}
    >
      <Text color='400' size='xs'>
        {fileExtension}, {fileSize}
      </Text>

      <div className='absolute bg-800/60 left-sm rounded-xs top-sm w-9 h-9 flex flex-col justify-center items-center'>
        {!!f.progress && (
          <CircleProgress progress={f.progress} className='absolute' />
        )}
        {f.hasError && (
          <div className='absolute p-sm'>
            <Refresh onClick={() => onRetry(f)} className='text-0' />
          </div>
        )}
      </div>

      <RemoveButton onClick={() => onRemove(f)} />
    </FilePreview>
  )
}

type AttachmentListItemProps = {
  attachment: MessageAttachment
}

const AttachmentListItem: FC<AttachmentListItemProps> = ({ attachment: a }) => {
  if (a.isImage) return <SquareImagePreview url={a.url} />

  const fileSize = formatFileSize(a.size)
  const fileExtension = a.name.split('.').reverse()[0]
  return (
    <FilePreview fileExtension={fileExtension} fileName={a.name}>
      <Text color='400' size='xs'>
        {fileExtension}, {fileSize}
      </Text>
    </FilePreview>
  )
}

type SquareImagePreviewProps = {
  url: string
} & HTMLAttributes<HTMLImageElement>

const SquareImagePreview: FC<SquareImagePreviewProps> = ({
  url,
  children,
  className,
  ...rest
}) => (
  <div className='w-14 h-14'>
    <div
      className={`group relative w-14 h-14 rounded-xs border border-600 bg-600 ${
        className || ''
      }`}
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${url})`,
        backgroundPosition: 'center center',
      }}
      {...rest}
    >
      {children}
    </div>
  </div>
)

type AttachmentImagePreviewProps = {
  attachment: MessageAttachment
  onImageLoad?: () => void
} & HTMLAttributes<HTMLImageElement>

const AttachmentImagePreview: FC<AttachmentImagePreviewProps> = ({
  attachment,
  className,
  onImageLoad,
  ...rest
}) => (
  <div>
    <img
      alt={attachment.name}
      src={attachment.url}
      onLoad={onImageLoad}
      className={`ring-2 transition-all ring-blue/0 hover:ring-blue rounded-xs ${
        className || ''
      }`}
      {...rest}
    />
  </div>
)

type FilePreviewProps = {
  fileExtension?: string
  fileName: string
} & HTMLAttributes<HTMLDivElement>

const FilePreview: FC<FilePreviewProps> = ({
  children,
  fileExtension,
  fileName,
  className,
  ...rest
}) => (
  <div>
    <div
      className={`group relative h-14 w-[160px] pr-sm py-sm pl-14 rounded-xs border flex flex-col border-600 bg-900 ${
        className || ''
      }`}
      {...rest}
    >
      <div className='absolute left-sm top-sm'>
        <Avatar size='md' name={fileExtension}>
          <File className='text-800' />
        </Avatar>
      </div>
      <div className='text-ellipsis overflow-hidden text-base text-0 w-full'>
        {fileName}
      </div>
      {children}
    </div>
  </div>
)

const RemoveButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <div
    onClick={onClick}
    className='absolute -right-3 -top-3 m-2 transition-opacity opacity-0 group-hover:opacity-100 cursor-pointer rounded-full bg-red w-4 h-4 flex justify-center items-center'
  >
    <X className='text-0' size={21} />
  </div>
)

const HorizontalContainer: FC = ({ children }) => (
  <SimpleBar className='h-20 w-full' forceVisible='x'>
    <div className='flex flex-nowrap gap-sm px-sm py-2'>{children}</div>
  </SimpleBar>
)
