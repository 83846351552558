import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react'

type CircleProgressProps = {
  progress: number
  size?: number
} & HTMLAttributes<SVGElement>

export const CircleProgress: FC<CircleProgressProps> = ({
  progress,
  size = 28,
  className,
  ...rest
}) => {
  const [offset, setOffset] = useState(0)
  const circleRef = useRef<SVGCircleElement>(null)
  const strokeWidth = 2
  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference
    setOffset(progressOffset)

    if (circleRef.current)
      circleRef.current.style.transition =
        'transition: stroke-dashoffset 850ms ease-in-out'
  }, [setOffset, progress, circumference, offset])

  return (
    <svg
      width={size}
      height={size}
      className={`animate-spin ${className || ''}`}
      {...rest}
    >
      <circle
        className='svg-circle stroke-200'
        ref={circleRef}
        cx={center}
        cy={center}
        r={radius}
        fill='none'
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
    </svg>
  )
}
