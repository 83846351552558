import { logEvent } from 'firebase/analytics'
import { trackEventFb } from '@takle/facebook'
import { fireAnalytics } from '@takle/firebase'

export const accountMessagesSentMap: Record<number, string> = {
  1: 'firstAccountMessageSent',
  5: '5AccountMessageSent',
  10: '10AccountMessageSent',
  25: '25AccountMessageSent',
  50: '50AccountMessageSent',
  100: '100AccountMessageSent',
  250: '250AccountMessageSent',
  500: '500AccountMessageSent',
  1000: '1000AccountMessageSent',
  2000: '2000AccountMessageSent',
} as const

// для событий без payload
export type AnalyticsEvent<T> = { kind: T; trackOnceForUserId?: string }

// для событий с payload
export type AnalyticsEventWithPayload<T, P> = {
  payload: P
} & AnalyticsEvent<T>

// ✅
export type RegistrationCompleted = AnalyticsEventWithPayload<
  'registrationCompleted',
  { type: string; workspaceId?: string; accountId: string }
>

export type RegistrationCompletedByInvite = AnalyticsEventWithPayload<
  'registrationCompletedByInvite',
  { type: string; workspaceId?: string; accountId: string }
>

type AccountMessageSentMap = typeof accountMessagesSentMap

export type AccountMessageSent = AnalyticsEventWithPayload<
  AccountMessageSentMap[keyof AccountMessageSentMap],
  {
    workspaceId: string
    accountId: string
  }
>

// ✅
export type WorkspaceCreated = AnalyticsEventWithPayload<
  'workspaceCreated',
  {
    workspaceId: string
    accountId: string
  }
>

// ✅
export type ChannelCreated = AnalyticsEventWithPayload<
  'channelCreated',
  {
    workspaceId: string
    accountId: string
  }
>

// ✅
export type MessageSent = AnalyticsEventWithPayload<
  'messageSent',
  {
    workspaceId: string
    type: 'direct' | 'channel'
    attach: boolean
    attachSizeKb: number // (kb)
    accountId: string
  }
>

// ✅
export type FirstConversationStarted = AnalyticsEventWithPayload<
  'firstConversationStarted',
  {
    workspaceId: string
    accountId: string
  }
>

// ✅
export type InviteLinkCopied = AnalyticsEventWithPayload<
  'inviteLinkCopied',
  {
    workspaceId: string
    accountId: string
  }
>

// ✅
export type SubscriptionScreenOpened = AnalyticsEventWithPayload<
  'subscriptionScreenOpened',
  {
    accountId: string
  }
>

// ✅
export type InitiatedPurchase = AnalyticsEventWithPayload<
  'initiatedPurchase',
  {
    accountId: string
  }
>

export type RegistrationStarted = AnalyticsEvent<'registrationStarted'>
export type RegistrationStartedByInvite = AnalyticsEventWithPayload<
  'registrationStartedByInvite',
  {
    workspaceId: string
    accountId: string
  }
>

export type AnalyticsTypedEvent =
  | RegistrationCompleted
  | WorkspaceCreated
  | ChannelCreated
  | MessageSent
  | FirstConversationStarted
  | InviteLinkCopied
  | SubscriptionScreenOpened
  | InitiatedPurchase
  | RegistrationStarted
  | RegistrationCompletedByInvite
  | RegistrationStartedByInvite
  | AccountMessageSent

export const trackFirebaseEvent = (e: AnalyticsTypedEvent) => {
  // Все события дополнительно отправляем в firebase
  try {
    const payload = 'payload' in e ? e.payload : undefined
    logEvent(fireAnalytics, e.kind, payload)
  } catch (error) {
    // capture(error)
    console.error(error)
  }
}

export const trackFacebookEvent = (e: AnalyticsTypedEvent) => {
  switch (e.kind) {
    case 'registrationCompleted':
      trackEventFb({ kind: 'CompleteRegistration' })
      break
    case 'channelCreated':
      trackEventFb({ kind: 'CustomizeProduct' })
      break
  }
}

export const trackConsoleEvent = (e: AnalyticsTypedEvent) => {
  console.log('tracked analytics event', e)
}

type AnalyticsProvider = (e: AnalyticsTypedEvent) => void

let currentAnalyticsProviders: AnalyticsProvider[] = []

export const setAnalyticsProviders = (providers: AnalyticsProvider[]) => {
  currentAnalyticsProviders = providers
}

export const trackEvent = async (e: AnalyticsTypedEvent) => {
  if (e.trackOnceForUserId) {
    const eventId = `${e.kind}:${e.trackOnceForUserId}`

    const hasTracked = !!localStorage.getItem(eventId)
    if (hasTracked) return

    localStorage.setItem(eventId, 'true')
  }

  currentAnalyticsProviders.forEach(provider => {
    provider(e)
  })
}
