import { Store } from '.'
import * as Model from '../models/models'
import { getDocData } from '@takle/firebase'
import { getAccountStatisticsDocRef } from '@takle/firebase/statistics'

export class StatisticsStore {
  store: Store

  constructor(store: Store) {
    this.store = store
  }

  async getAccountStatistics(accountId: string) {
    const accountStatisticsData = await getDocData({
      ref: getAccountStatisticsDocRef(accountId),
      tModel: Model.TAccountStatisticsData,
    })

    return accountStatisticsData
  }
}
