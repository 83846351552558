import { observer } from 'mobx-react-lite'
import { FC, HTMLAttributes } from 'react'

import { Button, LinkButton } from '@takle/components/Button'
import { Text } from '@takle/components/Text'
import { store } from '@takle/store'
import { Channel, DirectChannel } from '@takle/store/channelsStore'
import { formatRelativeDate } from '@takle/utils/format'
import { exhaustCheck } from '@takle/utils/exhaustCheck'

import { AddPeopleButton } from './AddPeopleButton'
import { ArticleOff } from 'tabler-icons-react'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { OlderMessagesLoader } from './MessagesLoader'
import { FT_SHOW_UPGRADE_BUTTON } from '@takle/constants'

type ChannelOverviewProps = {
  channel: Channel | DirectChannel
} & HTMLAttributes<HTMLDivElement>

export const ChannelOverview: FC<ChannelOverviewProps> = observer(
  ({ channel, className, ...rest }) => {
    const currentUser = store.usersStore.currentLoggedInUser
    const currentAccount = store.accountsStore.currentAccount
    const navigate = useStrictNavigate()
    const workspace = store.workspacesStore.workspaces.find(
      w => w.id === channel.workspaceId,
    )

    if (!workspace) return null

    const renderContent = () => {
      switch (channel.type) {
        case 'Channel': {
          const owner = store.usersStore.userById(channel.ownerId)

          return (
            <>
              <Text color='600' size='display-sm' bold>
                #{channel.name}
              </Text>
              <Text color='200'>
                <Text color='0' size='lg'>
                  @{owner.displayName} created this channel{' '}
                  {formatRelativeDate(channel.createdAt)}.
                </Text>
                <br />
                This is the very beginning of the #{channel.name} channel.
                {channel.isDefault &&
                  ' This is default channel so all workspace users have access to it.'}
              </Text>
              <AddPeopleButton
                workspace={workspace}
                channel={channel}
                renderButton={onOpenModal => (
                  <LinkButton onClick={onOpenModal}>Add people</LinkButton>
                )}
              />
            </>
          )
        }

        case 'DirectChannel': {
          const withUserId = channel.userIds.find(id => id !== currentUser.id)
          const isTalkingToYourself = !withUserId
          const userId = withUserId || currentUser.id
          const user = store.usersStore.userById(userId)

          return (
            <>
              <Text color='600' size='display-sm' bold>
                @{user.displayName}
              </Text>
              <Text color='200'>
                This is the very beginning of your direct message history with{' '}
                {!isTalkingToYourself ? `@${user.displayName}` : 'yourself'}.
                {isTalkingToYourself &&
                  ' Please be patient and respond accordingly. You can also save some notes, drafts and some lists here.'}
              </Text>
            </>
          )
        }

        default:
          return exhaustCheck(channel)
      }
    }

    return (
      <div className={`flex flex-col gap-md ${className || ''}`} {...rest}>
        {renderContent()}
        {channel.hasMessagesCroppedByDate && (
          <div className='flex gap-md md:gap-lg p-sm md:p-md pr-md md:pr-lg border bg-800 border-600 rounded-xs md:rounded-sm'>
            <ArticleOff size={48} className='text-600 hidden md:block' />
            <div className='flex-1'>
              <h3>
                <Text bold className='text-base md:text-lg text-0'>
                  Some of channel messages were hidden due to workspace plan
                  limits
                </Text>
              </h3>
              {FT_SHOW_UPGRADE_BUTTON && (
                <Text className='text-200 hidden md:block'>
                  Consider upgrading workspace plan to have a full history of
                  your communications and lots of other features
                </Text>
              )}
              <div>
                {workspace.accountId === currentAccount?.id && FT_SHOW_UPGRADE_BUTTON && (
                  <Button
                    className='mt-md'
                    color='orange'
                    onClick={() => navigate(AuthorizedRoutePath.Upgrade, {})}
                  >
                    Upgrade<span className='hidden md:block ml-xxs'>now</span>
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
        <OlderMessagesLoader channel={channel} />
      </div>
    )
  },
)
