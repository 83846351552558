import { ChangeEvent, useState, FC } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, InverseButton, LinkButton } from '@takle/components/Button'
import { Input } from '@takle/components/Input'
import { Logo } from '@takle/components/Logo'
import { Text } from '@takle/components/Text'
import { store } from '@takle/store'
import { exhaustCheck } from '@takle/utils/exhaustCheck'
import { isEmail } from '@takle/utils/isEmail'
import { getLocalStorageItem, setLocalStorageItem } from '@takle/localStorage'
import { SearchParams } from '@takle/constants'

import { EmailIcon } from './components/EmailIcon'
import { GoogleLogo } from './components/GoogleLogo'
import { handleError } from '@takle/sentry'
import { buildUrl, makeExternalUrl } from '@takle/navigation/paths'
import { UnauthorizedRoutePath } from '@takle/navigation/UnauthorizedRoutes'
import { trackEvent } from '@takle/analytics'
import { AppleLogo } from './components/AppleLogo'

export enum LoginFlow {
  Login,
  CreateAccount,
  CheckEmail,
}

type LoginPageProps = {
  initialFlow: LoginFlow
}

type EmailState = {
  email: string
  error: string
}

export const LoginPage: FC<LoginPageProps> = observer(({ initialFlow }) => {
  const [flow, setFlow] = useState<LoginFlow>(initialFlow)
  const [isLoading, setLoading] = useState(false)
  const [emailState, setEmailState] = useState<EmailState>({
    email: '',
    error: '',
  })

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailState({
      email: e.target.value,
      error: '',
    })
  }

  const onEmailLogin = async () => {
    const { email } = emailState

    if (!isEmail(email)) {
      return setEmailState(s => ({
        ...s,
        error: 'Please enter valid email address',
      }))
    }
    setLoading(true)
    try {
      setLocalStorageItem('emailForSignIn', email)
      const invitationId = getLocalStorageItem('invitationId')
      const url = new URL(
        window.location.origin +
          buildUrl(UnauthorizedRoutePath.CompleteRegistration, {}),
      )
      if (invitationId) {
        url.searchParams.append(SearchParams.InvitationId, invitationId)
      }
      const urlToShare = makeExternalUrl(url.toString())
      await store.usersStore.sendLinkToEmail({ email, url: urlToShare})
      setFlow(LoginFlow.CheckEmail)
    } catch (e) {
      handleError(e)
      store.uiStore.showWarningToast(
        'An error occurred while signing in using email, please try again',
      )
    }
    setLoading(false)
  }

  const trackEventRegistrationStartedWithInvite = async () => {
    const invitationId = getLocalStorageItem('invitationId')

    if (invitationId) {
      const inviteData = await store.workspacesStore.getInvitationDataByHash(
        invitationId,
      )

      trackEvent({
        kind: 'registrationStartedByInvite',
        payload: {
          workspaceId: inviteData.data.workspaceId,
          accountId: store.accountsStore.currentAccount.id,
        },
      })
    }
  }

  const renderContent = () => {
    switch (flow) {
      case LoginFlow.Login:
        return (
          <>
            <div className='text-center'>
              <h1 className='mb-md'>
                <Text size='display' bold testId='title-sign-in'>
                  Sign in
                </Text>
              </h1>
              <Text size='lg' color='400' bold>
                Work with people outside your company has never been easier
              </Text>
            </div>
            <GoogleButton
              onClick={() => {
                store.usersStore.signInWithGoogle()
                trackEventRegistrationStartedWithInvite()
              }}
            />
            <AppleButton 
              onClick={() => {
                store.usersStore.signInWithApple()
                trackEventRegistrationStartedWithInvite()
              }}
             />
            <Separator />
            <EmailInputWithButton
              emailState={emailState}
              onEmailLogin={() => {
                onEmailLogin()
                trackEventRegistrationStartedWithInvite()
              }}
              onEmailChange={onEmailChange}
              isLoading={isLoading}
            />
            <LinkButton onClick={() => setFlow(LoginFlow.CreateAccount)}>
              Create an account
            </LinkButton>
          </>
        )

      case LoginFlow.CreateAccount:
        return (
          <>
            <div className='text-center'>
              <h1 className='mb-md'>
                <Text size='display' bold testId='title-new-account'>
                  New Account
                </Text>
              </h1>
              <Text size='lg' bold color='400'>
                Work with people outside your company has never been easier
              </Text>
            </div>
            <EmailInputWithButton
              emailState={emailState}
              onEmailLogin={() => {
                onEmailLogin()
                trackEvent({ kind: 'registrationStarted' })
              }}
              onEmailChange={onEmailChange}
              isLoading={isLoading}
            />
            <Separator />
            <GoogleButton
              label='Continue with Google'
              onClick={() => {
                store.usersStore.signInWithGoogle()
                trackEvent({ kind: 'registrationStarted' })
              }}
            />
            <AppleButton
              label='Continue with Apple'
              onClick={() => {
                store.usersStore.signInWithApple()
                trackEvent({ kind: 'registrationStarted' })
              }}
            />
            <LinkButton onClick={() => setFlow(LoginFlow.Login)}>
              I have an account
            </LinkButton>
          </>
        )

      case LoginFlow.CheckEmail:
        return (
          <>
            <div className='text-center flex flex-col items-center'>
              <EmailIcon />
              <h1 className='my-md'>
                <Text size='display-sm' bold testId='title-check-email'>
                  Check your Email
                </Text>
              </h1>
              <Text color='200'>
                Confirmation email was sent to your email address{' '}
                <Text color='blue' testId='check-email-sent-to' bold>
                  {emailState.email}
                </Text>
              </Text>
            </div>
          </>
        )

      default:
        return exhaustCheck(flow)
    }
  }

  return (
    <div className='flex flex-1 flex-col justify-center items-center'>
      <div className='flex flex-col gap-xxl items-center max-w-sm mx-xl my-lg'>
        <Logo />
        {renderContent()}
      </div>
    </div>
  )
})

const GoogleButton: FC<{ label?: string; onClick: () => void }> = ({
  onClick,
  label = 'Sign in with Google',
}) => {
  return (
    <InverseButton
      testId='google-login-button'
      className='w-full'
      onClick={onClick}
    >
      <GoogleLogo width={22} className='mr-sm' />
      {label}
    </InverseButton>
  )
}

const AppleButton: FC<{ label?: string; onClick: () => void }> = ({
  onClick,
  label = 'Sign in with Apple',
}) => {
  return (
    <InverseButton
      testId='apple-login-button'
      className='w-full'
      onClick={onClick}
    >
      <AppleLogo width={22} className='mr-sm' />
      {label}
    </InverseButton>
  )
}

const Separator: FC = () => {
  return (
    <div className='flex items-center self-stretch'>
      <div className='border-b border-600 flex-1' />
      <Text bold className='mx-md' color='400'>
        or
      </Text>
      <div className='border-b border-600 flex-1' />
    </div>
  )
}

type EmailInputWithButtonProps = {
  emailState: EmailState
  isLoading?: boolean
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void
  onEmailLogin: () => void
}

const EmailInputWithButton: FC<EmailInputWithButtonProps> = ({
  emailState,
  isLoading,
  onEmailChange,
  onEmailLogin,
}) => {
  return (
    <div className='self-stretch'>
      <Input
        testId='input-email'
        value={emailState.email}
        showError={emailState.error}
        placeholder='your@email.here'
        onChange={onEmailChange}
        className='w-full'
      />
      <Button
        testId='button-submit-email'
        isFetching={isLoading}
        className='mt-md w-full'
        disabled={!emailState.email || isLoading}
        onClick={onEmailLogin}
      >
        Continue with Email
      </Button>
    </div>
  )
}
