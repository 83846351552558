export function parseBoolEnv(value: string | null): boolean | null {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return null
  }
}
