import { FC } from 'react'

export const ActivityIndicator: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...rest
}) => (
  <div
    className={`flex justify-center flex-1 items-center ${className || ''}`}
    {...rest}
  >
    <div className='spinnerAnimationContainer spinnerAnimation'>
      <div className='spinnerShape spinnerShape1 bg-purple'></div>
      <div className='spinnerShape spinnerShape2 bg-pink'></div>
      <div className='spinnerShape spinnerShape3 bg-khaki'></div>
      <div className='spinnerShape spinnerShape4 bg-orange'></div>
    </div>
  </div>
)
