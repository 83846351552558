import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { Logo } from '@takle/components/Logo'
import { Button, LinkButton } from '@takle/components/Button'
import { store } from '@takle/store'

import { UserButton } from './UserButton'
import { useStrictNavigate } from '@takle/navigation/paths'
import { UnauthorizedRoutePath } from '@takle/navigation/UnauthorizedRoutes'

export const HeaderBarWithLinks: FC = observer(() => {
  const user = store.usersStore.currentUser
  const navigate = useStrictNavigate()

  return (
    <div className='flex justify-between self-stretch items-center'>
      <Link to='/'>
        <Logo />
      </Link>
      <div className='flex gap-xxl items-center'>
        <LinkButton
          testId='header-pricing'
          bold
          onClick={() => navigate(UnauthorizedRoutePath.Pricing, {})}
        >
          Pricing
        </LinkButton>
        {user ? (
          <UserButton user={user} />
        ) : (
          <>
            <LinkButton
              testId='header-sign-in'
              bold
              onClick={() => navigate(UnauthorizedRoutePath.Login, {})}
            >
              Sign in
            </LinkButton>
            <Button
              testId='header-sign-up'
              bordered
              onClick={() => navigate(UnauthorizedRoutePath.SignUp, {})}
            >
              Sign up
            </Button>
          </>
        )}
      </div>
    </div>
  )
})
