import { doc } from 'firebase/firestore'
import { Collections } from './constants'
import { converter } from '@takle/firebase/converter'
import { fireStore } from './firebase'

export function getAccountStatisticsDocRef(accountId: string) {
  return doc(
    fireStore,
    Collections.AccountsStatistics,
    accountId,
  ).withConverter(converter)
}
