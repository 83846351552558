import { FC, useState } from 'react'
import { HeaderBar } from '@takle/components/HeaderBar/HeaderBar'
import { Text } from '@takle/components/Text'
import { observer } from 'mobx-react-lite'
import { getConfigValue, RemoteConfigFieldName } from '@takle/firebase'

import { WorkspacesBar } from '@takle/components/WorkpacesBar'
import { Avatar } from '@takle/components/Avatar'
import { IconButton } from '@takle/components/Button'
import { Dots } from 'tabler-icons-react'
import { WorkspacesList } from './components/WorkspacesList'
import { PopupButton } from '@takle/components/Popup'
import { UserPopupContent } from '@takle/components/UserPopup/UserPopup'
import { CurrentUser } from '@takle/store/usersStore'
import { store } from '@takle/store'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { SubscriptionInfo } from './components/SubscriptionInfo'
import { Questionnaire } from './components/Questionnaire'
import { UserCompleteProfileModal } from '@takle/components/UserCompleteProfileModal'
import { disableQuestionnaire } from '@takle/config'

export const DashboardPage: FC = observer(() => {
  const navigate = useStrictNavigate()
  const currentUser = store.usersStore.currentLoggedInUser
  const currentAccount = store.accountsStore.currentAccount
  const workspaces = store.workspacesStore.workspaces
  const pendingWorkspaces = store.workspacesStore.pendingWorkspaces
  const userHasNoNameAdded = !!currentUser && !currentUser.profileNameIsSet()
  const [openCompleteProfileModal, setOpenCompleteProfileModal] =
    useState(userHasNoNameAdded)

  const onChangeWorkspace = (workspaceId: string) => {
    navigate(AuthorizedRoutePath.Workspace, { workspaceId })
  }

  const showQuestionnaire =
    !!currentUser &&
    getConfigValue(RemoteConfigFieldName.SHOW_QUESTIONNAIRE) &&
    !currentUser.registrationSurveyCompleted &&
    !disableQuestionnaire

  return (
    <>
      <div className='flex flex-1 flex-col'>
        <HeaderBar showUser={false} />
        {showQuestionnaire ? (
          <Questionnaire />
        ) : (
          <div className='flex flex-1'>
            <WorkspacesBar onChangeWorkspace={onChangeWorkspace} />
            <div className='flex flex-1 flex-col px-lg md:px-xl lg:px-xxl pt-lg gap-lg'>
              <UserInfo user={currentUser} />
              <SubscriptionInfo
                account={currentAccount}
                workspaces={workspaces}
              />
              <WorkspacesList
                workspaces={workspaces}
                pendingWorkspaces={pendingWorkspaces}
                onChangeWorkspace={onChangeWorkspace}
              />
            </div>
          </div>
        )}
      </div>
      {!showQuestionnaire && openCompleteProfileModal && (
        <UserCompleteProfileModal
          onClose={() => setOpenCompleteProfileModal(false)}
        />
      )}
    </>
  )
})

const UserInfo: FC<{ user: CurrentUser }> = observer(({ user }) => {
  return (
    <>
      <div className='flex self-stretch justify-between items-center'>
        <div className='flex gap-md'>
          <Avatar size='lg' name={user.displayName} imageUrl={user.photoURL} />
          <div>
            <Text
              bold
              testId='user-info-hello'
              color='400'
              className='text-base lg:text-lg'
            >
              Hello
            </Text>
            <h2 className='max-w-[150px] md:max-w-full text-0 text-lg md:text-xl overflow-hidden whitespace-nowrap text-ellipsis'>
              <Text
                bold
                testId='user-info-user-display-name'
                className='text-lg md:text-xl'
              >
                {user.displayName}
              </Text>
            </h2>
          </div>
        </div>
        <PopupButton
          renderButton={openPopup => (
            <IconButton testId='user-popup-button' onClick={openPopup}>
              <Dots />
            </IconButton>
          )}
        >
          {onClose => <UserPopupContent onClose={onClose as any} />}
        </PopupButton>
      </div>
    </>
  )
})
