import { FC } from 'react'
const PageBgImage = require('./page-bg.jpg')

export const PageGradientBackground: FC = () => (
  <div
    className='absolute left-0 top-0 right-0 bottom-0 bg-900'
    style={{
      backgroundImage: `url(${PageBgImage})`,
      backgroundPosition: 'top left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }}
  />
)
