import {
  ChangeEvent,
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { observer } from 'mobx-react-lite'
import { BrowserPlus, X } from 'tabler-icons-react'

import { trackEvent } from '@takle/analytics'
import { store } from '@takle/store'
import { formatChannelName } from '@takle/utils/format'

import { Modal } from './Modal'
import { Text } from './Text'
import { Button, IconButton } from './Button'
import { Input } from './Input'
import { ReactNode } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { handleError } from '@takle/sentry'

export type ChannelCreateModalProps = {
  workspaceId: string
  onClose: () => void
  onCreated: (channelId: string) => void
}

const ChannelCreateModal: FC<ChannelCreateModalProps> = observer(
  ({ onClose, workspaceId, onCreated }) => {
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const inputRef = useRef<HTMLInputElement | null>(null)

    const currentAccount = store.accountsStore.currentAccount

    useEffect(() => {
      inputRef.current?.focus()
      inputRef.current?.scrollIntoView()
    }, [])

    const isSaveDisabled =
      !name.trim() || name.startsWith('-') || name.endsWith('-')

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(formatChannelName(e.target.value))
      setError('')
    }

    const onCreate = useCallback(async () => {
      if (isSaveDisabled || loading || !currentAccount) return

      const channelAlreadyExists = store.channelsStore.channels.some(
        channel => channel.workspaceId === workspaceId && channel.name === name,
      )
      if (channelAlreadyExists) {
        setError('Channel with this name already exists')
        return
      }

      setLoading(true)
      try {
        const channelId = await store.channelsStore.createChannel({
          name,
          workspaceId,
          accountId: currentAccount.id,
        })
        trackEvent({
          kind: 'channelCreated',
          payload: { workspaceId, accountId: currentAccount.id },
        })
        store.uiStore.showInfoToast(`Channel ${name} created`)
        setLoading(false)
        onCreated(channelId)
      } catch (e) {
        handleError(e)
        store.uiStore.showWarningToast(
          'Something wrong happened while creating a new channel, please try again',
        )
        setLoading(false)
      }
    }, [isSaveDisabled, loading, currentAccount, workspaceId, name, onCreated])

    useHotkeys('esc', onClose, { enableOnFormTags: true })
    useHotkeys('return', onCreate, { enableOnFormTags: true }, [onCreate])

    return (
      <Modal onClose={onClose}>
        <div className='flex flex-col gap-md'>
          <div className='flex items-center self-stretch justify-between'>
            <BrowserPlus size={36} className='text-200' />
            <IconButton onClick={onClose}>
              <X />
            </IconButton>
          </div>
          <Text size='xl' bold color='700'>
            Create Channel
          </Text>
          <div className='flex flex-col'>
            <Text color='600' bold>
              Channel name
            </Text>
            <Text color='400' size='sm'>
              Must be a lowercase, replacing spaces with dash
            </Text>
          </div>
          <div>
            <Input
              ref={inputRef}
              autoFocus
              invertedColors
              value={name}
              placeholder='new-channel-name'
              onChange={onNameChange}
              showError={error}
              className='w-full'
            />
          </div>
          <div className='flex justify-end'>
            <Button
              disabled={isSaveDisabled}
              onClick={onCreate}
              isFetching={loading}
            >
              Create
            </Button>
          </div>
        </div>
      </Modal>
    )
  },
)

type ChannelCreateButtonProps = {
  workspaceId: string
  renderButton: (v: () => void) => ReactNode
  onCreated: (channelId: string) => void
  isInitiallyOpened?: boolean
} & HTMLAttributes<HTMLDivElement>

export const ChannelCreateButton: FC<ChannelCreateButtonProps> = ({
  workspaceId,
  renderButton,
  onCreated,
  isInitiallyOpened = false,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(isInitiallyOpened)

  return (
    <div {...rest}>
      {renderButton(() => setOpen(true))}
      {isOpen && (
        <ChannelCreateModal
          workspaceId={workspaceId}
          onCreated={id => {
            setOpen(false)
            onCreated(id)
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  )
}
