import { v4 } from 'uuid'
import { startOfDay, subDays } from 'date-fns'
import { Store } from '.'
import { makeObservable, observable, action, computed } from 'mobx'
import {
  subscribeToCollectionUpdates,
  createChannelDocument,
  userChannelsQuery,
  createMessageDocument,
  removeUnreadDocument,
  createDirectChannelDocument,
  userDirectChannelsQuery,
  getNewMessageId,
  uploadMessageFile,
  deleteMessageDocument,
  updateMessageDocument,
  setChannelOpened,
  updateChannelDocument,
  deleteChannelDocument,
  addUserToChannelCallable,
  removeUserFromChannelCallable,
  checkForMessagePriorDate,
  getConfigValue,
  RemoteConfigFieldName,
  channelUpdatedMessagesQuery,
  getOlderChannelMessages,
} from '@takle/firebase'
import * as Model from '../models/models'
import { DocumentSnapshot } from 'firebase/firestore'
import {
  CHANNEL_MESSAGES_PAGESIZE,
  THE_VERY_FIRST_DAY_OF_UNIVERSE,
} from '@takle/constants'
import { DEV_EMULATORS_ENABLED } from '@takle/config'
import { awaitTimeout } from '@takle/utils/awaitTimeout'
import { randomInt } from '@takle/utils/randomInt'

type MessageSentHandler = (
  message: DraftMessage,
  channel: DirectChannel | Channel,
) => void

export class ChannelsStore {
  uid: string | null = null
  channels: Array<Channel> = []
  directChannels: Array<DirectChannel> = []
  store: Store
  isInitialized: boolean = false

  private currentlyOpenedChannelId: string | null = null
  private channelsUnsubscriber: (() => void) | null = null
  private directChannelsUnsubscriber: (() => void) | null = null

  private messageSentHandlers: Map<string, MessageSentHandler> = new Map()
  registerMessageSentHandler(id: string, handler: MessageSentHandler) {
    this.messageSentHandlers.set(id, handler)
    return () => this.messageSentHandlers.delete(id)
  }

  constructor(store: Store) {
    this.store = store
    makeObservable(this, {
      isInitialized: observable,
      channels: observable,
      directChannels: observable,
      resetStore: action,
      addChannels: action,
      removeChannelsById: action,
      addDirectChannels: action,
      removeDirectChannelsById: action,
    })

    store.usersStore.registerUserChangeHandler(
      'initializeChannels',
      (action, user) => {
        action === 'login' ? this.initializeStore(user.id) : this.resetStore()
      },
    )
  }

  runMessageSentHandlers(
    message: DraftMessage,
    channel: DirectChannel | Channel,
  ) {
    const handlers = this.messageSentHandlers.values()

    for (const handler of handlers) {
      handler(message, channel)
    }
  }

  async sendMessage(message: DraftMessage, channel: DirectChannel | Channel) {
    this.runMessageSentHandlers(message, channel)
  }

  async createChannel({
    name,
    workspaceId,
    accountId,
  }: {
    name: string
    workspaceId: string
    accountId: string
  }) {
    if (!this.uid) throw new Error('You must be logged in to create channel')
    return await createChannelDocument({
      name,
      workspaceId,
      uid: this.uid,
      accountId,
    })
  }

  async deleteChannel(channelId: string) {
    return await deleteChannelDocument({ channelId })
  }

  async createDirectChannel({
    withUserId,
    workspaceId,
  }: {
    withUserId: string
    workspaceId: string
  }) {
    if (!this.uid)
      throw new Error('You must be logged in to create direct channel')
    return await createDirectChannelDocument({
      userId: this.uid,
      workspaceId,
      withUserId,
    })
  }

  addChannels(changes: { model: Model.ChannelModel; atIndex: number }[]) {
    if (!this.isInitialized) this.isInitialized = true
    const addedIds = changes.map(c => c.model.id)
    const updated = this.channels.slice().filter(m => !addedIds.includes(m.id))
    for (const change of changes) {
      updated.splice(change.atIndex, 0, new Channel(change.model, this))
    }
    this.channels = updated
  }

  updateChannels(changes: { model: Model.ChannelModel }[]) {
    changes.map(change => {
      const exitingChannel = this.channels.find(c => c.id === change.model.id)
      exitingChannel?.updateChannelData(change.model)
      return null
    })
  }

  removeChannelsById(removedIds: string[]) {
    this.channels = this.channels.filter(w => !removedIds.includes(w.id))
  }

  addDirectChannels(
    changes: { model: Model.DirectChannelModel; atIndex: number }[],
  ) {
    const addedIds = changes.map(c => c.model.id)
    const updated = this.directChannels
      .slice()
      .filter(m => !addedIds.includes(m.id))
    for (const change of changes) {
      updated.splice(change.atIndex, 0, new DirectChannel(change.model, this))
    }
    this.directChannels = updated
  }

  removeDirectChannelsById(removedIds: string[]) {
    this.directChannels = this.directChannels.filter(
      w => !removedIds.includes(w.id),
    )
  }

  private initializeStore(uid: string) {
    this.uid = uid
    this.channelsUnsubscriber = subscribeToCollectionUpdates({
      query: userChannelsQuery(uid),
      tModel: Model.TChannelModel,
      onAdded: changes => this.addChannels(changes),
      onUpdated: changes => this.updateChannels(changes),
      onRemovedIds: removedIds => this.removeChannelsById(removedIds),
    })

    this.directChannelsUnsubscriber = subscribeToCollectionUpdates({
      query: userDirectChannelsQuery(uid),
      tModel: Model.TDirectChannelModel,
      onAdded: changes => this.addDirectChannels(changes),
      onRemovedIds: removedIds => this.removeDirectChannelsById(removedIds),
    })
  }

  resetStore() {
    this.channelsUnsubscriber?.()
    this.directChannelsUnsubscriber?.()
    this.isInitialized = false
    this.uid = null
    this.channels = []
  }

  setOpenedChannelId(channelId: string | null) {
    if (!this.uid) return

    const clientId = this.store.clientId
    const userId = this.uid

    if (this.currentlyOpenedChannelId) {
      setChannelOpened({
        clientId,
        userId,
        channelId: this.currentlyOpenedChannelId,
        isOpened: false,
      })
    }

    if (channelId) {
      setChannelOpened({
        clientId,
        userId,
        channelId,
        isOpened: true,
      })
    }

    this.currentlyOpenedChannelId = channelId
  }

  channelById(channelId: string): Channel | undefined {
    return this.channels.find(c => c.id === channelId)
  }

  directChannelById(directChannelId: string): DirectChannel | undefined {
    return this.directChannels.find(c => c.id === directChannelId)
  }
}

class ChannelWithMessages {
  type: 'Channel' | 'DirectChannel'
  id: string
  workspaceId: string
  messages: Message[] = []
  channelsStore: ChannelsStore
  editingMessage: EditingMessage | null = null
  draftMessage: DraftMessage = new DraftMessage(this)
  pendingMessages: PendingMessage[] = []
  hasMessagesCroppedByDate: boolean = false

  // Pagination
  hasMoreToLoad: boolean = true
  isLoadingMore: boolean = false
  unsubscribedAt?: Date
  lastLoadedMessageDoc?: DocumentSnapshot
  messagesUpdatesQueue: Model.MessageModel[] = []

  messagesUnsubscriber: (() => void) | null = null
  constructor(
    data: Pick<Model.ChannelModel, 'id' | 'workspaceId'>,
    channelsStore: ChannelsStore,
    type: 'Channel' | 'DirectChannel',
  ) {
    this.type = type
    this.id = data.id
    this.workspaceId = data.workspaceId
    this.channelsStore = channelsStore

    channelsStore.store.usersStore.registerUserChangeHandler(
      'unsubscribeChannelMessages',
      action => {
        action === 'logout' && this.unsubscribeFromMessagesUpdates()
      },
    )
  }

  setEditingMessage(message: Message | null) {
    if (message && message.userId !== this.channelsStore.uid)
      throw new Error("You're not allowed to edit others messages")
    this.editingMessage = message ? new EditingMessage(message) : null
  }

  addPendingFromDraftMessage(draftMessage: DraftMessage) {
    this.draftMessage = new DraftMessage(this)
    this.pendingMessages = [
      ...this.pendingMessages,
      new PendingMessage(draftMessage),
    ]
  }

  removePendingMessageById(pendingMessageId: string) {
    const pendingIds = this.pendingMessages.map(m => m.id)
    if (!pendingIds.includes(pendingMessageId)) return

    this.pendingMessages = this.pendingMessages.filter(
      p => p.id !== pendingMessageId,
    )
  }

  prependMessages(messagesData: Model.MessageModel[]) {
    this.messages.splice(0, 0, ...messagesData.map(m => new Message(m, this)))
  }

  appendMessages(messagesData: Model.MessageModel[]) {
    this.messages.push(...messagesData.map(m => new Message(m, this)))
  }

  handleMessagesFromChange(changes: { model: Model.MessageModel }[]) {
    const models = changes.map(c => c.model)
    const createdMessages = models.filter(
      m => m.createdAt.valueOf() === m.updatedAt.valueOf(),
    )
    const createdMessagesIds = createdMessages.map(m => m.id)
    this.appendMessages(createdMessages)

    const updatedMessages = models.filter(
      m => !createdMessagesIds.includes(m.id),
    )

    if (this.isLoadingMore) {
      this.messagesUpdatesQueue = [
        ...this.messagesUpdatesQueue,
        ...updatedMessages,
      ]
      return
    }

    this.handleMessageUpdates(updatedMessages)

    for (const createdMessage of createdMessages) {
      this.removePendingMessageById(createdMessage.id)
    }
  }

  handleMessageUpdates(messagesData: Model.MessageModel[]) {
    this.removeMessagesByIds(
      messagesData.filter(m => m.isDeleted).map(m => m.id),
    )

    for (const messageData of messagesData) {
      const exitingMessage = this.messages.find(m => m.id === messageData.id)
      if (!exitingMessage) return
      exitingMessage?.updateMessageData(messageData)
    }
  }

  removeMessagesByIds(removedIds: string[]) {
    this.messages = this.messages.filter(m => !removedIds.includes(m.id))
  }

  unsubscribeFromMessagesUpdates() {
    this.messagesUnsubscriber?.()
  }

  async loadOlderMessages() {
    this.setLoadingMore(true)

    if (DEV_EMULATORS_ENABLED) await awaitTimeout(randomInt(1000, 3000))

    const since = this.cropMessagesSinceDate || THE_VERY_FIRST_DAY_OF_UNIVERSE

    const { messageModels, lastLoadedDoc, hasMoreMessages } =
      await getOlderChannelMessages({
        channelId: this.id,
        since,
        pageSize: CHANNEL_MESSAGES_PAGESIZE,
        after: this.lastLoadedMessageDoc,
      })

    this.setHasMoreToLoad(hasMoreMessages)
    this.prependMessages(messageModels)

    this.lastLoadedMessageDoc = lastLoadedDoc
    this.handleMessageUpdates(this.messagesUpdatesQueue)
    this.messagesUpdatesQueue = []
    this.setLoadingMore(false)

    return messageModels
  }

  async removeUserChannelUnread() {
    const currentUser = this.channelsStore.store.usersStore.currentUser
    if (!currentUser) return null

    if (!currentUser.channelUnread.has(this.id)) return

    await removeUnreadDocument({
      userId: currentUser.id,
      channelId: this.id,
    })
  }

  async checkMessagesCroppedByDate() {
    if (!this.cropMessagesSinceDate) {
      this.setMessagesCroppedByDate(false)
      return
    }

    const hasMessagePriorCroppedDate = await checkForMessagePriorDate({
      date: this.cropMessagesSinceDate,
      channelId: this.id,
    })

    this.setMessagesCroppedByDate(hasMessagePriorCroppedDate)
  }

  subscribeToMessagesUpdates() {
    this.messagesUnsubscriber = subscribeToCollectionUpdates({
      query: channelUpdatedMessagesQuery(
        this.id,
        this.unsubscribedAt ?? new Date(),
      ),
      tModel: Model.TMessageModel,
      onAdded: changes => this.handleMessagesFromChange(changes),
      onUpdated: changes => this.handleMessagesFromChange(changes),
    })

    this.checkMessagesCroppedByDate()

    return () => {
      this.unsubscribeFromMessagesUpdates()
      this.unsubscribedAt = new Date()
    }
  }

  setMessagesCroppedByDate(v: boolean) {
    this.hasMessagesCroppedByDate = v
  }

  setHasMoreToLoad(v: boolean) {
    this.hasMoreToLoad = v
  }

  setLoadingMore(v: boolean) {
    this.isLoadingMore = v
  }

  get cropMessagesSinceDate(): Date | null {
    const workspace = this.channelsStore.store.workspacesStore.workspaces.find(
      w => w.id === this.workspaceId,
    )
    if (!workspace || !workspace.owner.subscriptionPlan) {
      return startOfDay(
        subDays(
          new Date(),
          getConfigValue(
            RemoteConfigFieldName.MESSAGES_VISIBLE_IN_DAYS_FREE,
          ).asNumber(),
        ),
      )
    }

    return null
  }

  get unreadInfo() {
    const currentUser = this.channelsStore.store.usersStore.currentUser
    if (!currentUser) return null

    return currentUser.channelUnread.get(this.id) || null
  }

  get messagesAndPendingMessages() {
    const existingIds = this.messages.map(m => m.id)

    return [
      ...this.messages,
      // It's done to eliminate blinking of messages,
      // while we'd received updates and removing pending messages
      ...this.pendingMessages.filter(m => !existingIds.includes(m.id)),
    ]
  }
}

export class Channel extends ChannelWithMessages {
  type: 'Channel' = 'Channel'
  id: string
  name: string
  ownerId: string
  createdAt: Date
  userIds: Record<string, boolean>
  isDefault: boolean
  channelsStore: ChannelsStore

  constructor(data: Model.ChannelModel, channelsStore: ChannelsStore) {
    super(data, channelsStore, 'Channel')
    this.id = data.id
    this.name = data.name
    this.ownerId = data.ownerId
    this.isDefault = data.isDefault
    this.createdAt = data.createdAt
    this.userIds = data.userIds
    this.channelsStore = channelsStore

    makeObservable(this, {
      name: observable,
      messages: observable,
      userIds: observable,
      pendingMessages: observable,
      hasMessagesCroppedByDate: observable,
      isLoadingMore: observable,
      draftMessage: observable,
      editingMessage: observable,
      hasMoreToLoad: observable,
      setEditingMessage: action,
      prependMessages: action,
      appendMessages: action,
      addPendingFromDraftMessage: action,
      removePendingMessageById: action,
      updateChannelData: action,
      setLoadingMore: action,
      setMessagesCroppedByDate: action,
      removeMessagesByIds: action,
      setHasMoreToLoad: action,
      messagesAndPendingMessages: computed,
      cropMessagesSinceDate: computed,
      unreadInfo: computed,
    })
  }

  updateChannelData(data: Model.ChannelModel) {
    this.name = data.name
    this.userIds = data.userIds
  }

  async setChannelName(name: string) {
    return await updateChannelDocument({ name, channelId: this.id })
  }

  async addUser(uidToAdd: string) {
    return await addUserToChannelCallable({
      uidToAdd,
      channelId: this.id,
    })
  }

  async removeUser(uidToRemove: string) {
    return await removeUserFromChannelCallable({
      uidToRemove,
      channelId: this.id,
    })
  }
}

export class DirectChannel extends ChannelWithMessages {
  type: 'DirectChannel' = 'DirectChannel'
  id: string
  createdAt: Date
  userIds: [string, string]
  channelsStore: ChannelsStore

  constructor(data: Model.DirectChannelModel, channelsStore: ChannelsStore) {
    super(data, channelsStore, 'DirectChannel')
    this.id = data.id
    this.createdAt = data.createdAt
    this.userIds = data.userIds
    this.channelsStore = channelsStore

    makeObservable(this, {
      messages: observable,
      userIds: observable,
      pendingMessages: observable,
      draftMessage: observable,
      editingMessage: observable,
      hasMoreToLoad: observable,
      hasMessagesCroppedByDate: observable,
      isLoadingMore: observable,
      setEditingMessage: action,
      prependMessages: action,
      appendMessages: action,
      addPendingFromDraftMessage: action,
      removePendingMessageById: action,
      removeMessagesByIds: action,
      setLoadingMore: action,
      setMessagesCroppedByDate: action,
      setHasMoreToLoad: action,
      messagesAndPendingMessages: computed,
      unreadInfo: computed,
      cropMessagesSinceDate: computed,
    })
  }
}

export class Message {
  type: 'Message' = 'Message'
  id: string
  text: string
  createdAt: Date
  updatedAt: Date
  userId: string
  workspaceId: string
  channelId: string
  channel: ChannelWithMessages
  imageAttachments: Array<Model.MessageAttachment>
  fileAttachments: Array<Model.MessageAttachment>
  mentionUserIds: string[]

  constructor(data: Model.MessageModel, channel: ChannelWithMessages) {
    this.id = data.id
    this.text = data.text
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.workspaceId = data.workspaceId
    this.channelId = data.channelId
    this.imageAttachments = data.imageAttachments
    this.fileAttachments = data.fileAttachments
    this.channel = channel
    this.mentionUserIds = data.mentionUserIds.filter(Boolean) as string[]

    makeObservable(this, {
      text: observable,
      updatedAt: observable,
      imageAttachments: observable,
      fileAttachments: observable,
      userId: observable,
      mentionUserIds: observable,
    })
  }

  delete() {
    return deleteMessageDocument({
      channelId: this.channelId,
      messageId: this.id,
    })
  }

  updateMessageData(data: Model.MessageModel) {
    this.text = data.text
    this.updatedAt = data.updatedAt
    this.imageAttachments = data.imageAttachments
    this.fileAttachments = data.fileAttachments
    this.mentionUserIds = data.mentionUserIds.filter(Boolean) as string[]
  }
}

const IMAGE_TYPES = ['image/jpeg', 'image/x-png', 'image/png', 'image.jpg']
export const DRAFT_MAX_FILES = 10

export enum DraftMessageError {
  UnknownError = 'UnknownError',
  TotalFileSize = 'TotalFileSize',
  SingleFileSize = 'SingleFileSize',
}

export class DraftMessage {
  type: 'DraftMessage' = 'DraftMessage'
  id: string = v4()
  text: string = ''
  channel: ChannelWithMessages
  files: Model.DraftMessageFile[] = []
  error: DraftMessageError | null = null
  mentionUserIds: string[] = []

  constructor(channel: ChannelWithMessages) {
    this.channel = channel
    makeObservable(this, {
      text: observable,
      files: observable,
      error: observable,
      mentionUserIds: observable,
      setText: action,
      setError: action,
      addFiles: action,
      removeFile: action,
      setMentionUserIds: action,
    })
  }

  addFiles(files: File[]) {
    this.files = [
      ...this.files,
      ...files.map(f => ({
        file: f,
        id: v4(),
        url: URL.createObjectURL(f),
        isImage: IMAGE_TYPES.includes(f.type),
      })),
    ].slice(0, DRAFT_MAX_FILES)
    this.validateMessage().then(error => this.setError(error))
  }

  removeFile(file: Model.DraftMessageFile) {
    this.files = this.files.filter(f => f.id !== file.id)
    this.validateMessage().then(error => this.setError(error))
  }

  setText(text: string) {
    this.text = text
  }

  setMentionUserIds(mentionsIds: string[]) {
    this.mentionUserIds = mentionsIds
  }

  async save() {
    const error = await this.validateMessage()
    if (error) {
      this.setError(error)
      return
    }

    this.channel.addPendingFromDraftMessage(this)
  }

  async validateMessage(): Promise<DraftMessageError | null> {
    const maxFileSize = getConfigValue(
      RemoteConfigFieldName.UPLOAD_MAX_FILE_SIZE_BYTES,
    ).asNumber()

    if (this.files.length) {
      if (this.files.some(f => f.file.size > maxFileSize)) {
        return DraftMessageError.SingleFileSize
      }
      const workspace =
        this.channel.channelsStore.store.workspacesStore.workspaces.find(
          w => w.id === this.channel.workspaceId,
        )

      if (!workspace) return DraftMessageError.UnknownError

      const totalFilesSize = this.files.reduce((acc, f) => acc + f.file.size, 0)
      const availableSpace = await workspace.getOwnerStorageFreeSpace()

      if (totalFilesSize > availableSpace) {
        return DraftMessageError.TotalFileSize
      }
    }

    return null
  }

  setError(v: DraftMessageError | null) {
    this.error = v
  }
}

export class PendingMessage {
  type: 'PendingMessage' = 'PendingMessage'
  id: string
  userId: string
  createdAt: Date = new Date()
  text: string
  channel: ChannelWithMessages
  hasError?: boolean = true
  attachments: Array<Model.MessageAttachment> = []
  files: Map<string, Model.PendingMessageFile> = new Map()
  mentionUserIds: Array<string> = []

  constructor(draftMessage: DraftMessage) {
    this.userId = draftMessage.channel.channelsStore.uid || ''
    this.text = draftMessage.text
    this.channel = draftMessage.channel
    this.id = getNewMessageId(draftMessage.channel.id)
    this.mentionUserIds = draftMessage.mentionUserIds

    const files: typeof this.files = new Map()

    for (const file of draftMessage.files) {
      files.set(file.id, { ...file, hasError: false })
    }

    this.files = files

    makeObservable(this, {
      text: observable,
      hasError: observable,
      files: observable,
      attachments: observable,
      mentionUserIds: observable,
      send: action,
      addAttachment: action,
      updateFile: action,
      deleteFile: action,
    })
    this.send()
  }

  cancel() {
    this.channel.removePendingMessageById(this.id)
  }

  async uploadFile(file: Model.PendingMessageFile) {
    if (file.hasError || !!file.onCancel) return

    try {
      return await uploadMessageFile({
        messageId: this.id,
        channelId: this.channel.id,
        direct: this.channel.type === 'DirectChannel',
        workspaceId: this.channel.workspaceId,
        fileData: file,
        uploadProgress: progress => this.updatePendingFileProgress(progress),
      })
    } catch (e) {
      this.updateFile({
        ...file,
        hasError: true,
        onCancel: undefined,
        progress: undefined,
      })
      throw e
    }
  }

  updatePendingFileProgress({
    fileId,
    progress,
    onCancel,
  }: {
    fileId: string
    progress: number
    onCancel: () => void
  }) {
    const file = this.files.get(fileId)
    file && this.updateFile({ ...file, progress, onCancel, hasError: false })
  }

  addAttachment(a: Model.MessageAttachment) {
    this.attachments.push(a)
  }
  deleteFile(f: Model.PendingMessageFile) {
    this.files.delete(f.id)
  }

  updateFile(file: Model.PendingMessageFile) {
    if (this.files.has(file.id)) this.files.set(file.id, file)
  }

  cancelPendingFile(file: Model.PendingMessageFile) {
    file.onCancel?.()
    this.deleteFile(file)
    this.send()
  }

  retryFileUpload(file: Model.PendingMessageFile) {
    file.onCancel?.()
    const clearedFiled = {
      ...file,
      hasError: false,
      onCancel: undefined,
      progress: undefined,
    }

    this.updateFile(clearedFiled)
    this.uploadFile(clearedFiled)
  }

  async send() {
    this.hasError = false

    if (!this.text && !this.attachments.length) {
      this.cancel()
      return
    }

    const files = Array.from(this.files.values())

    try {
      if (files.length) {
        const attachments = await Promise.all(
          files.map(a => this.uploadFile(a)),
        )

        attachments.forEach(a => {
          if (a) {
            this.files.delete(a.fileId)
            this.addAttachment(a)
          }
        })
      }

      await createMessageDocument({
        uid: this.userId,
        messageId: this.id,
        text: this.text,
        channelId: this.channel.id,
        workspaceId: this.channel.workspaceId,
        attachments: this.attachments,
        mentionUserIds: this.mentionUserIds,
      })
      if (files.length) files.forEach(f => this.deleteFile(f))
    } catch (e) {
      this.hasError = true
      throw e
    }
  }
}

export class EditingMessage {
  type: 'EditingMessage' = 'EditingMessage'
  id: string
  text: string
  mentionUserIds: string[]
  userId: string
  channelId: string
  attachments: Array<Model.MessageAttachment>

  constructor(message: Message) {
    this.id = message.id
    this.text = message.text
    this.userId = message.userId
    this.channelId = message.channelId
    this.attachments = [...message.imageAttachments, ...message.fileAttachments]
    this.mentionUserIds = message.mentionUserIds

    makeObservable(this, {
      text: observable,
      attachments: observable,
      setText: action,
      removeAttachment: action,
    })
  }

  setText(text: string) {
    this.text = text
  }

  setMentionUserIds(mentionUserIds: string[]) {
    this.mentionUserIds = mentionUserIds
  }

  removeAttachment(attachment: Model.MessageAttachment) {
    this.attachments = this.attachments.filter(
      a => a.storageLink !== attachment.storageLink,
    )
  }

  save() {
    return updateMessageDocument({
      channelId: this.channelId,
      messageId: this.id,
      text: this.text,
      attachments: this.attachments,
      mentionUserIds: this.mentionUserIds,
    })
  }
}
