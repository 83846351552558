import { FC, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Text } from '@takle/components/Text'

import { ActivityIndicator } from '@takle/components/ActivityIndicator'
import { store } from '@takle/store'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '@takle/localStorage'
import { SearchParams } from '@takle/constants'
import { buildUrl, useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { Logo } from '@takle/components/Logo'
import { handleError } from '@takle/sentry'
import { Avatar } from '@takle/components/Avatar'
import { Button, LinkButton } from '@takle/components/Button'
import { GetInviteDataByHashResult } from '@takle/firebase'

export const JoinPage: FC = observer(() => {
  const currentAccount = store.accountsStore.currentAccount
  const navigate = useStrictNavigate()

  const [inviteData, setInviteData] =
    useState<GetInviteDataByHashResult | null>(null)
  const [searchParams] = useSearchParams()
  const invitationId =
    searchParams.get(SearchParams.InvitationId) ||
    getLocalStorageItem('invitationId')

  useEffect(() => {
    if (!invitationId) return

    store.workspacesStore
      .getInvitationDataByHash(invitationId)
      .then(res => setInviteData(res.data))
      .catch(e => {
        handleError(e)
        store.uiStore.showWarningToast(
          'Your invitation link must be expired or user revoked this invitation',
        )
        navigate(AuthorizedRoutePath.Dashboard, {})
      })
  }, [navigate, invitationId])

  if (!invitationId) return <Navigate to={AuthorizedRoutePath.Dashboard} />

  if (!inviteData) return <ActivityIndicator />

  const currentUserWorkspaceIds = {
    ...currentAccount.workspaceIds,
  }
  const isUserAdded = currentUserWorkspaceIds[inviteData.workspaceId]
  if (isUserAdded)
    return (
      <Navigate
        to={buildUrl(AuthorizedRoutePath.Workspace, {
          workspaceId: inviteData.workspaceId,
        })}
      />
    )

  return <JoinToWorkspace invitationId={invitationId} inviteData={inviteData} />
})

type JoinToWorkspaceProps = {
  invitationId: string
  inviteData: GetInviteDataByHashResult
}

const JoinToWorkspace: FC<JoinToWorkspaceProps> = observer(
  ({ inviteData, invitationId }) => {
    const navigate = useStrictNavigate()
    const [isFetching, setFetching] = useState(false)

    const user = store.usersStore.currentLoggedInUser

    const handleJoin = useCallback(async () => {
      setFetching(true)
      try {
        const { data: workspace } =
          await store.workspacesStore.joinToWorkspaceByInvitationHash(
            invitationId,
          )

        const isUserPending = workspace.pendingUserIds[user.id]
        store.uiStore.showInfoToast(
          isUserPending
            ? `Waiting for an approval from owner to join "${workspace.name}"`
            : `You are joined to "${workspace.name}" workspace`,
        )

        isUserPending || !user.registrationSurveyCompleted
          ? navigate(AuthorizedRoutePath.Dashboard, {})
          : navigate(AuthorizedRoutePath.Workspace, {
              workspaceId: workspace.id,
            })
        removeLocalStorageItem('invitationId')
      } catch {
        store.uiStore.showWarningToast(
          'Your invitation link must be expired or user revoked this invitation',
        )
      }
      setFetching(false)
    }, [invitationId, navigate, user.id, user.registrationSurveyCompleted])

    const cancelWorkspaceJoining = useCallback(() => {
      removeLocalStorageItem('invitationId')
      navigate(AuthorizedRoutePath.Dashboard, {})
    }, [navigate])

    const account = store.accountsStore.accountById(
      inviteData.workspaceAccountId,
    )
    const owner = store.usersStore.userById(account.ownerId)
    const ownerName = owner.displayName

    return (
      <div className='flex flex-1 flex-col justify-center items-center'>
        <div className='flex flex-col gap-xxl items-center max-w-sm mx-xl my-lg'>
          <Logo />
          <div className='flex flex-col gap-xs'>
            <Text className='text-center' bold size='display-sm'>
              You're about to join workspace
            </Text>
            <Text className='text-center' bold size='lg' color='400'>
              Work with people outside your company has never been easier
            </Text>
          </div>
          <div className='bg-0 self-stretch ring ring-3 ring-blue/50 rounded-md px-xl py-xxl flex flex-col gap-md items-center'>
            <Avatar
              size='xl'
              name={inviteData.workspaceName}
              imageUrl={inviteData.workspaceImageUrl}
            />

            <Text className='text-center' bold size='xl' color='900'>
              {inviteData.workspaceName}
            </Text>
            <div className='flex gap-xs items-center'>
              by
              <Avatar size='sm' imageUrl={owner.photoURL} name={ownerName} />
              <Text className='text-center' bold color='600'>
                by {ownerName}
              </Text>
            </div>
            <Button
              isFetching={isFetching}
              onClick={handleJoin}
              className='w-full my-md'
            >
              All fine. Let’s go
            </Button>
            <LinkButton onClick={cancelWorkspaceJoining}>
              No, I don’t want to do that
            </LinkButton>
          </div>
        </div>
      </div>
    )
  },
)
