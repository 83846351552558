import { FC, HTMLAttributes } from 'react'

export type TextSize =
  | 'xs'
  | 'sm'
  | 'base'
  | 'lg'
  | 'xl'
  | 'display-sm'
  | 'display'
  | 'display-lg'

export type TextColor =
  | 'transparent'
  | '900'
  | '800'
  | '700'
  | '600'
  | '400'
  | '200'
  | '0'
  | 'blue'
  | 'green'
  | 'purple'
  | 'beige'
  | 'khaki'
  | 'pink'
  | 'red'
  | 'orange'

export type TextProps = {
  bold?: boolean
  center?: boolean
  testId?: string
  color?: TextColor
  size?: TextSize
} & HTMLAttributes<HTMLSpanElement>

export const Text: FC<TextProps> = ({
  testId,
  children,
  bold,
  center,
  className,
  size = 'base',
  color = '0',
  ...rest
}) => (
  <span
    data-testid={testId}
    className={`text-${size} text-${color} ${bold ? 'font-bold' : ''} ${
      center ? 'text-center' : ''
    } ${className || ''}`}
    {...rest}
  >
    {children}
  </span>
)
