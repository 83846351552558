import { FC } from 'react'

type ProgressBarProps = {
  progress: number
  total: number
}

export const ProgressBar: FC<ProgressBarProps> = ({ progress, total }) => {
  const percentage = Math.round((progress / total) * 100)
  return (
    <div className='w-full rounded-full bg-700'>
      <div
        className='transition-all duration-500 ease-out bg-blue/80 text-xs font-medium text-0 text-center p-0.5 leading-none rounded-full'
        style={{ width: `${percentage}%` }}
      />
    </div>
  )
}
