import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Caption } from '../Caption'
import { Input } from '../Input'

export type UserProfileMainInfoSectionProps = {
  displayName: string
  fullName: string
  setDisplayName: (name: string) => void
  setFullName: (name: string) => void
  email?: string
}

export const UserProfileMainInfoSection: FC<UserProfileMainInfoSectionProps> =
  observer(({ displayName, fullName, setDisplayName, setFullName, email }) => {
    return (
      <>
        {email && (
          <div>
            <Caption label='your email' className='mb-xs' />
            <Input
              value={email}
              placeholder='your@email.com'
              className='w-full'
              disabled
            />
          </div>
        )}
        <div>
          <Caption label='display name' className='mb-xs' />
          <Input
            value={displayName}
            placeholder='John'
            maxLength={20}
            className='w-full'
            onChange={e => setDisplayName(e.target.value)}
            showError={!displayName}
          />
        </div>
        <div>
          <Caption label='full name' className='mb-xs' />
          <Input
            value={fullName}
            placeholder='John Smith'
            maxLength={100}
            className='w-full'
            onChange={e => setFullName(e.target.value)}
            showError={!fullName}
          />
        </div>
      </>
    )
  })
