import { FC, HTMLAttributes } from 'react'

export type AvatarProps = {
  name?: string
  imageUrl?: string | null
  status?: 'online' | 'offline'
  size?: 'lg' | 'md' | 'sm' | 'xl'
  isWorkspace?: boolean
} & HTMLAttributes<HTMLDivElement>

export const Avatar: FC<AvatarProps> = ({
  name = '',
  size = 'md',
  status,
  imageUrl,
  isWorkspace,
  onClick,
  children,
  className,
  ...rest
}) => {
  const initials =
    !imageUrl &&
    !isWorkspace &&
    name
      ?.split(' ')
      .slice(0, 2)
      .map(w => w[0])
      .join('')

  const sizeClass =
    size === 'xl'
      ? 'w-24 h-24 rounded-sm text-lg'
      : size === 'lg'
      ? 'w-12 h-12 rounded-sm text-lg'
      : size === 'md'
      ? 'w-9 h-9 rounded-xs text-base'
      : 'w-6 h-6 rounded-xxs text-sm'

  return (
    <div
      onClick={onClick}
      style={
        imageUrl
          ? {
              backgroundSize: 'cover',
              backgroundImage: `url(${imageUrl})`,
              backgroundPosition: 'center center',
            }
          : undefined
      }
      className={`${sizeClass} relative uppercase bg-600 font-semibold  flex justify-center items-center aspect-square
        ${
          isWorkspace &&
          'text-blue normal-case !text-sm !font-normal border border-600 border-solid !bg-800'
        }
        ${
          !initials || !name
            ? 'text-200'
            : `text-800 ${getColorFromString(name)}`
        }
        ${onClick ? 'cursor-pointer' : ''}
        ${className || ''}`}
      {...rest}
    >
      {children || initials || (isWorkspace && !imageUrl && name)}
      {status && (
        <div
          className={`absolute right-0 bottom-0 ring-2 ring-800 w-[6px] h-[6px] rounded-xs ${
            status === 'online' ? 'bg-green' : 'bg-800 border border-400'
          }`}
        />
      )}
    </div>
  )
}

const BG_COLORS = [
  'bg-beige',
  'bg-purple',
  'bg-khaki',
  'bg-pink',
  'bg-red',
  'bg-orange',
  'bg-green',
]

const getColorFromString = (s: string) => {
  const index =
    (s.split('').reduce((acc, c) => acc + c.charCodeAt(0), 0) *
      s.charCodeAt(0)) %
    BG_COLORS.length

  return BG_COLORS[index] || BG_COLORS[0]
}
