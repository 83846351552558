import { FC } from 'react'
import { Home, Plus } from 'tabler-icons-react'
import SimpleBar from 'simplebar-react'
import { observer } from 'mobx-react-lite'

import { IconButton } from '@takle/components/Button'
import { Avatar } from '@takle/components/Avatar'
import { store } from '@takle/store'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { UnreadCount } from './UnreadCount'

export type WorkspacesBarProps = {
  activeWorkspaceId?: string
  onChangeWorkspace: (id: string) => void
}

export const WorkspacesBar: FC<WorkspacesBarProps> = observer(
  ({ activeWorkspaceId, onChangeWorkspace }) => {
    const workspaces = store.workspacesStore.workspaces
    const navigate = useStrictNavigate()

    return (
      <div className='border-r border-600 p-lg relative'>
        <IconButton className='pointer-events-none' />
        <div className='absolute left-0 top-0 right-0 bottom-0'>
          <SimpleBar className='w-full h-full'>
            <div className='p-lg gap-lg flex flex-col'>
              <IconButton
                active={!activeWorkspaceId}
                className={
                  !activeWorkspaceId
                    ? 'ring-2 ring-blue ring-offset-900 ring-offset-2 pointer-events-none'
                    : undefined
                }
                onClick={() => navigate(AuthorizedRoutePath.Dashboard, {})}
              >
                <Home />
              </IconButton>
              {workspaces.map(w => {
                const isActive = activeWorkspaceId === w.id
                return (
                  <div className='relative' key={w.id}>
                    <Avatar
                      imageUrl={w.imageURL}
                      onClick={() => onChangeWorkspace(w.id)}
                      data-test-id='workspace'
                      name={w.name}
                      className={
                        isActive
                          ? 'ring-2 ring-blue ring-offset-900 ring-offset-2 pointer-events-none'
                          : 'hover:ring-2 hover:ring-blue/50 hover:ring-offset-900 hover:ring-offset-2 cursor-pointer'
                      }
                    />
                    <UnreadCount
                      count={w.unreadCount}
                      className='!absolute -top-xxs left-5 min-w-[15px] text-center !bottom-auto'
                    />
                  </div>
                )
              })}
              <IconButton
                onClick={() =>
                  navigate(AuthorizedRoutePath.CreateWorkspace, {})
                }
              >
                <Plus />
              </IconButton>
            </div>
          </SimpleBar>
        </div>
      </div>
    )
  },
)
