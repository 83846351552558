/*

  THIS FILE IS USED ACROSS FIREBASE FUNCTIONS AND APPS
  Please keep it free of unnecessary imports

*/

import * as t from 'io-ts'
import reporter from 'io-ts-reporters'

export const TTimestamp = new t.Type<Date, any, unknown>(
  'Timestamp',
  (input: unknown): input is Date => {
    return typeof input === 'object' && (input as any).toDate() instanceof Date
  },
  // `t.success` and `t.failure` are helpers used to build `Either` instances
  (input, context) => {
    return input &&
      typeof input === 'object' &&
      (input as any).toDate() instanceof Date
      ? t.success((input as any).toDate() as Date)
      : t.failure(input, context)
  },
  // `A` and `O` are the same, so `encode` is just the identity function
  t.identity,
)

/*

Stripe products

*/

export const TProductIdModel = t.keyof({
  Business: null,
  PRO: null,
})

export const TProductModel = t.type({
  id: t.string,
  description: t.string,
  name: t.string,
  metadata: t.type({
    firebaseRole: t.string,
    takleProductId: TProductIdModel,
    takleProductTitle: t.string,
  }),
})

export type ProductIdModel = t.TypeOf<typeof TProductIdModel>
export type ProductModel = t.TypeOf<typeof TProductModel>

export const TProductPriceModel = t.type({
  id: t.string,
  currency: t.string,
  description: t.union([t.string, t.null]),
  product: t.string,
  interval: t.string,
  unit_amount: t.number,
})

export type ProductPriceModel = t.TypeOf<typeof TProductPriceModel>

/*

Users

*/
export const TAccountSubscriptionPlanModel = t.union([TProductIdModel, t.null])
export type AccountSubscriptionPlanModel = t.TypeOf<
  typeof TAccountSubscriptionPlanModel
>

export const TUserNotificationsSettingsModel = t.type({
  notifyOnDirectChannelsUpdates: t.boolean,
  notifyChannelsUpdates: t.boolean,
  notifyOnMentions: t.boolean,
})
export type UserNotificationsSettingsModel = t.TypeOf<
  typeof TUserNotificationsSettingsModel
>

export const TUserOnboardingModel = t.type({})
export type UserOnboarding = t.TypeOf<typeof TUserOnboardingModel>

export const TUserModelToSave = t.type({
  displayName: t.string,
  fullName: t.string,
  photoURL: t.union([t.undefined, t.string, t.null]),
  notificationsSettings: TUserNotificationsSettingsModel,
  onboarding: t.union([TUserOnboardingModel, t.undefined]),
  registrationSurveyCompleted: t.union([t.boolean, t.undefined]),
})
export type UserModelToSave = t.TypeOf<typeof TUserModelToSave>

export const TUserModelToDelete = t.type({
  displayName: t.string,
  fullName: t.string,
  photoURL: t.null,
  email: t.string,
  notificationsSettings: TUserNotificationsSettingsModel,
})
export type UserModelToDelete = t.TypeOf<typeof TUserModelToDelete>

export const TUserStatus = t.keyof({
  online: null,
  offline: null,
})

export type UserStatus = t.TypeOf<typeof TUserStatus>

export const TUserModel = t.intersection([
  TUserModelToSave,
  t.type({
    id: t.string,
    registeredAt: TTimestamp,
    email: t.string,
    latestActivityTimestamp: t.union([TTimestamp, t.null]),
    status: TUserStatus,
    signedUpBy: t.union([t.string, t.undefined]),
    registrationSurveyCompleted: t.union([t.boolean, t.undefined]),
    onboarding: t.union([TUserOnboardingModel, t.undefined]),
    accountIds: t.record(t.string, t.boolean),
    stripeId: t.union([t.string, t.undefined]),
    stripeLink: t.union([t.string, t.undefined]),
  }),
])
export type UserModel = t.TypeOf<typeof TUserModel>

export const TAccountModel = t.type({
  id: t.string,
  subscriptionPlan: TAccountSubscriptionPlanModel,
  subscriptionId: t.union([t.string, t.null, t.undefined]),
  ownerId: t.string,
  workspaceIds: t.record(t.string, t.boolean),
  name: t.string,
})

export type AccountModel = t.TypeOf<typeof TAccountModel>

export const TAccountStatisticsData = t.type({
  totalMessagesSent: t.number,
})

export type AccountStatisticsData = t.TypeOf<typeof TAccountStatisticsData>

export const TOpenedChannel = t.type({
  userId: t.string,
  channelId: t.string,
  clientId: t.string,
  openedAt: t.union([TTimestamp, t.null]),
})

export type OpenedChannel = t.TypeOf<typeof TOpenedChannel>

/*

Workspaces

*/

export const TInviteEmailModel = t.type({
  delivery: t.type({
    attempts: t.number,
    endTime: t.union([t.undefined, TTimestamp]),
    error: t.union([t.undefined, t.string, t.null]),
    startTime: TTimestamp,
    state: t.string,
  }),
  message: t.type({ html: t.string, subject: t.string }),
  to: t.array(t.string),
})

export type InviteEmailModel = t.TypeOf<typeof TInviteEmailModel>

export const TWorkspaceSettingsModel = t.type({
  ownerApprovesInvites: t.boolean,
  usersCanCreateChannels: t.boolean,
})

export type WorkspaceSettingsModel = t.TypeOf<typeof TWorkspaceSettingsModel>

export const TWorkspaceModelToSave = t.type({
  name: t.string,
  imageURL: t.union([t.undefined, t.string, t.null]),
  description: t.string,
  settings: TWorkspaceSettingsModel,
})
export type WorkspaceModelToSave = t.TypeOf<typeof TWorkspaceModelToSave>

export const TWorkspaceModel = t.intersection([
  TWorkspaceModelToSave,
  t.type({
    id: t.string,
    accountId: t.string,
    inviteHash: t.string,
    inviteEmailId: t.string,
    inviteEmail: t.union([TInviteEmailModel, t.null]),
    createdAt: TTimestamp,
    userIds: t.record(t.string, t.boolean),
    channelIds: t.record(t.string, t.boolean),
    defaultChannelId: t.string,
    pendingUserIds: t.record(t.string, t.boolean),
  }),
])

export type WorkspaceModel = t.TypeOf<typeof TWorkspaceModel>

export const TWorkspaceArrayModel = t.array(TWorkspaceModel)

/*

Channels

*/

export const TChannelModelToSave = t.type({
  name: t.string,
})

export type ChannelModelToSave = t.TypeOf<typeof TChannelModelToSave>

export const TChannelModel = t.intersection([
  TChannelModelToSave,
  t.type({
    id: t.string,
    ownerId: t.string,
    createdAt: TTimestamp,
    isDefault: t.boolean,
    workspaceId: t.string,
    userIds: t.record(t.string, t.boolean),
  }),
])

export type ChannelModel = t.TypeOf<typeof TChannelModel>

export const TDirectChannelModel = t.type({
  id: t.string,
  createdAt: TTimestamp,
  workspaceId: t.string,
  userIds: t.tuple([t.string, t.string]),
})

export type DirectChannelModel = t.TypeOf<typeof TDirectChannelModel>

/*

User-Channel Unread

*/

export const TUserChannelUnread = t.type({
  userId: t.string,
  channelId: t.string,
  firstUnreadMessageId: t.string,
  firstUnreadTimestamp: TTimestamp,
  unreadCount: t.number,
  unreadMentionCount: t.number,
})

export type UserChannelUnread = t.TypeOf<typeof TUserChannelUnread>

/*

Messages

*/

export const TMessageModelToSave = t.type({
  text: t.string,
})

export type MessageModelToSave = t.TypeOf<typeof TMessageModelToSave>

const TMessageAttachment = t.type({
  isImage: t.boolean,
  name: t.string,
  type: t.string,
  size: t.number,
  storageLink: t.string,
  url: t.string,
})

export type MessageAttachment = t.TypeOf<typeof TMessageAttachment>

export const TMessageModel = t.intersection([
  TMessageModelToSave,
  t.type({
    id: t.string,
    createdAt: TTimestamp,
    updatedAt: TTimestamp,
    userId: t.string,
    workspaceId: t.string,
    channelId: t.string,
    isDeleted: t.boolean,
    imageAttachments: t.array(TMessageAttachment),
    fileAttachments: t.array(TMessageAttachment),
    // This is a hotfix for null uid in mentions
    // TODO: figure out why this is happening
    mentionUserIds: t.array(t.union([t.string, t.null])),
  }),
])

// export const TMessageArrayModel = t.array(TMessageModel)

export type MessageModel = t.TypeOf<typeof TMessageModel>

export type DraftMessageFile = {
  id: string
  file: File
  url: string
  isImage: boolean
}

export type PendingMessageFile = DraftMessageFile & {
  hasError: boolean
  progress?: number
  onCancel?: () => void
}

/*

User FCM Token

*/

export const TUserTokenModel = t.type({
  id: t.string,
  tokens: t.record(t.string, t.boolean),
})

export type UserTokenModel = t.TypeOf<typeof TUserTokenModel>

export function decode<T>(
  value: any,
  type: t.Type<T>,
  errorMsg: string = 'Decode error',
): Promise<T> {
  const validation = type.decode(value)

  switch (validation._tag) {
    case 'Left':
      const message = `[DECODE]: ${errorMsg}, error data model – ${JSON.stringify(
        value,
        null,
        '\t',
      )}`

      console.log(message)
      const errors = reporter.report(validation)
      console.warn('[DECODE]: Errors ', errors)
      return Promise.reject(new Error(message))

    case 'Right':
      return Promise.resolve(validation.right)
    default:
      return exhaustCheck(validation)
  }
}

export function exhaustCheck(_unused: never): never {
  throw new Error('Exhaust switch error')
}
