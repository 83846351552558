import { Settings, UserPlus, InfoSquare } from 'tabler-icons-react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'

import { IconButton } from '@takle/components/Button'
import { store } from '@takle/store'
import { ActivityIndicator } from '@takle/components/ActivityIndicator'
import { Channel, DirectChannel } from '@takle/store/channelsStore'
import { Workspace } from '@takle/store/workspacesStore'
import { ChannelEditModalButton } from '@takle/components/ChannelEditModal'
import { AddPeopleButton } from './AddPeopleButton'
import { ChannelAvatar } from '@takle/components/ChannelAvatar'

import { MessagesListWithInput } from './MessagesListWithInput'
import { VirtualizedMessagesList } from './VirtualizedMessagesList'

export type ChannelInfoControlProps = {
  isSidebarOpened: boolean
  setSidebarOpened: (v: boolean) => void
}

type ChannelChatProps = {
  isChatOpenedOnMobile: boolean
  workspace: Workspace
  channelId: string
  onOpenChannel?: (channelId: string) => void
} & ChannelInfoControlProps

const containerStyle = (isMobileChatOpened: boolean) =>
  `md:flex md:animate-none md:relative flex-1 flex-col ${
    isMobileChatOpened
      ? 'absolute flex left-0 right-0 bottom-0 top-0 bg-900 animate-fade-from-right'
      : 'hidden'
  }`

export const ChannelChat: FC<ChannelChatProps> = observer(
  ({ channelId, workspace, isChatOpenedOnMobile, onOpenChannel, ...rest }) => {
    const channel = store.channelsStore.channels.find(c => c.id === channelId)

    useEffect(() => {
      if (!channel) return
      channel.removeUserChannelUnread()
      const unsubscribe = channel.subscribeToMessagesUpdates()
      return unsubscribe
    }, [channel])

    return (
      <div className={containerStyle(isChatOpenedOnMobile)}>
        {!channel ? (
          <ActivityIndicator />
        ) : (
          <MessagesListWithInput channel={channel} workspace={workspace}>
            <ChannelMessagesList
              channel={channel}
              workspace={workspace}
              onOpenChannel={onOpenChannel}
              isChatOpenedOnMobile={isChatOpenedOnMobile}
              {...rest}
            />
          </MessagesListWithInput>
        )}
      </div>
    )
  },
)

type DirectChannelChatProps = {
  isChatOpenedOnMobile: boolean
  workspace: Workspace
  directChannelId: string
} & ChannelInfoControlProps

export const DirectChannelChat: FC<DirectChannelChatProps> = observer(
  ({ directChannelId, isChatOpenedOnMobile, workspace, ...rest }) => {
    const currentUser = store.usersStore.currentLoggedInUser

    const channel = store.channelsStore.directChannelById(directChannelId)

    useEffect(() => {
      if (!channel) {
        const withUserId = directChannelId
          .split('_')
          .find(id => id !== currentUser.id)

        store.channelsStore.createDirectChannel({
          workspaceId: workspace.id,
          withUserId: withUserId || currentUser.id,
        })

        return
      }

      channel.removeUserChannelUnread()

      const unsubscribe = channel.subscribeToMessagesUpdates()
      return unsubscribe
    }, [channel, currentUser.id, workspace.id, directChannelId])

    return (
      <div className={containerStyle(isChatOpenedOnMobile)}>
        {!channel ? (
          <ActivityIndicator />
        ) : (
          <MessagesListWithInput workspace={workspace} channel={channel}>
            <DirectChannelMessagesList
              channel={channel}
              isChatOpenedOnMobile={isChatOpenedOnMobile}
              {...rest}
            />
          </MessagesListWithInput>
        )}
      </div>
    )
  },
)

type MessagesListProps = {
  channel: Channel
  workspace: Workspace
  onOpenChannel?: (channelId: string) => void
  isChatOpenedOnMobile: boolean
} & ChannelInfoControlProps

const ChannelMessagesList: FC<MessagesListProps> = observer(
  ({
    channel,
    workspace,
    isSidebarOpened,
    setSidebarOpened,
    onOpenChannel,
    isChatOpenedOnMobile,
  }) => {
    const channelOwner =
      store.usersStore.currentLoggedInUser.id === channel.ownerId

    const workspaceOwner =
      store.usersStore.currentLoggedInUser.id === workspace.accountId

    return (
      <>
        <div
          className={
            isChatOpenedOnMobile
              ? 'hidden md:flex px-lg pt-lg pb-xs items-center justify-between'
              : 'px-lg pt-lg pb-xs flex items-center justify-between'
          }
        >
          <ChannelAvatar channel={channel} />
          <div className='flex gap-md'>
            {(channelOwner || workspaceOwner) && (
              <ChannelEditModalButton
                channel={channel}
                renderButton={onOpenModal => (
                  <IconButton onClick={onOpenModal}>
                    <Settings />
                  </IconButton>
                )}
                onOpenChannel={onOpenChannel}
              />
            )}
            <AddPeopleButton
              workspace={workspace}
              channel={channel}
              renderButton={onOpenModal => (
                <IconButton onClick={onOpenModal}>
                  <UserPlus />
                </IconButton>
              )}
            />
            <IconButton
              active={isSidebarOpened}
              onClick={() => setSidebarOpened(!isSidebarOpened)}
            >
              <InfoSquare />
            </IconButton>
          </div>
        </div>
        <VirtualizedMessagesList key={channel.id} channel={channel} />
      </>
    )
  },
)

type DirectMessagesListProps = {
  channel: DirectChannel
  isChatOpenedOnMobile: boolean
} & ChannelInfoControlProps

const DirectChannelMessagesList: FC<DirectMessagesListProps> = observer(
  ({ channel, isSidebarOpened, setSidebarOpened, isChatOpenedOnMobile }) => {
    return (
      <>
        <div
          className={
            isChatOpenedOnMobile
              ? 'hidden md:flex px-lg pt-lg pb-xs items-center justify-between'
              : 'px-lg pt-lg pb-xs flex items-center justify-between'
          }
        >
          <ChannelAvatar channel={channel} />
          <div className='flex gap-md'>
            <IconButton
              active={isSidebarOpened}
              onClick={() => setSidebarOpened(!isSidebarOpened)}
            >
              <InfoSquare />
            </IconButton>
          </div>
        </div>
        <VirtualizedMessagesList key={channel.id} channel={channel} />
      </>
    )
  },
)
