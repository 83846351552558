import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CurrentUser } from './store/usersStore'
import { __DEV__ } from '@takle/constants'

export function initSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.npm_package_version,
    environment: process.env.REACT_APP_ENV_NAME,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['takle-dev.firebaseapp.com', /^\//],
      }),
    ],
    tracesSampleRate: 0.2,
  })
}

export function setUser(user: CurrentUser | null) {
  user
    ? Sentry.setUser({
        email: user.email || 'email not provided',
        username: user.displayName || 'displayName not provided',
      })
    : Sentry.setUser(null)
}

const PREFIX = '[Error handled]:'

export const handleError = (e: unknown, forceConsoleWarning = false) => {
  if (__DEV__ || forceConsoleWarning) {
    console.warn(PREFIX, e)
    return
  }

  Sentry.captureException(e)
}
