import { HTMLAttributes, MouseEventHandler, ReactNode, useState } from 'react'
import { FC, useEffect, useRef } from 'react'
import { useSpring, animated } from '@react-spring/web'

import { Portal } from './Portal'

type Position = {
  x: number
  y: number
}

const OFFSET = 10

type PopupProps = {
  onClose: () => void
} & HTMLAttributes<HTMLDivElement>

export const Popup: FC<PopupProps> = ({
  onClose,
  children,
  className,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState<Position | null>(null)

  const [fadeIn] = useSpring(
    {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    [],
  )

  const [fadeInFromBottom] = useSpring(
    {
      from: {
        translateY: 20,
        opacity: 0,
      },
      to: {
        translateY: 0,
        opacity: 1,
      },
      config: {
        tension: 600,
        friction: 50,
        mass: 1,
      },
      delay: 200,
      pause: !position,
    },
    [position],
  )

  useEffect(() => {
    if (!ref.current || !containerRef.current) return

    const { left, top } = containerRef.current.getBoundingClientRect()

    setPosition({
      x: left - ref.current.offsetWidth,
      y: top + OFFSET,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, containerRef.current])

  return (
    <div className='w-0 h-0' ref={containerRef}>
      <Portal layer='popup'>
        <animated.div
          style={fadeIn}
          className='absolute w-screen h-screen top-0 left-0 bg-800/40'
          onClick={onClose}
        />
        <animated.div
          ref={ref}
          style={{ left: position?.x, top: position?.y, ...fadeInFromBottom }}
          className={`absolute rounded-xs overflow-hidden bg-800 ring ring-3 ring-blue/50 ${
            position ? 'visible' : 'invisible'
          } ${className || ''}`}
          {...rest}
        >
          {children}
        </animated.div>
      </Portal>
    </div>
  )
}

type PopupButtonProps = {
  renderButton: (v: MouseEventHandler) => ReactNode
  isInitiallyOpened?: boolean
  children: (v: MouseEventHandler) => ReactNode
} & HTMLAttributes<HTMLDivElement>

export const PopupButton = ({
  className,
  renderButton,
  children,
  isInitiallyOpened = false,
  ...rest
}: PopupButtonProps) => {
  const [isOpen, setOpen] = useState(isInitiallyOpened)

  return (
    <div className={`flex ${className || ''}`} {...rest}>
      {renderButton(e => {
        e.preventDefault()
        setOpen(true)
      })}
      {isOpen && (
        <Popup onClose={() => setOpen(false)}>
          {children(() => setOpen(false))}
        </Popup>
      )}
    </div>
  )
}
