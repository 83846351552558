import { Control, Controller, Path, RegisterOptions } from 'react-hook-form'
import { FormFieldLabel } from './FormFieldLabel'
import { Input } from './Input'

type FormInputProps<FV, FI> = {
  fieldItem: FI
  control: Control<FV, any>
  loading?: boolean
  classNames?: string
  rules?: Omit<
    RegisterOptions<FV, Path<FV>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  error?: string
}

export const FormInput = <
  FV extends {},
  FI extends { name: Path<FV>; label?: string },
>({
  fieldItem,
  control,
  loading,
  classNames,
  rules,
  error,
}: FormInputProps<FV, FI>) => {
  return (
    <Controller
      name={fieldItem.name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <div className={classNames}>
          {fieldItem.label && <FormFieldLabel label={fieldItem.label} />}
          <Input
            {...field}
            className='w-full'
            placeholder='Type here'
            disabled={loading}
            showError={error}
          />
        </div>
      )}
    />
  )
}
