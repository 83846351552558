import { FC, HTMLAttributes, useCallback, useState } from 'react'
import { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, LinkButton } from '@takle/components/Button'

import { Modal } from '@takle/components/Modal'
import { Text } from '@takle/components/Text'
import { Caption } from '@takle/components/Caption'
import { store } from '@takle/store'
import { Switch } from '@takle/components/Switch'

import { CurrentPlanCard } from './CurrentPlanCard'
import { handleError } from '@takle/sentry'
import { Trash } from 'tabler-icons-react'
import { ProfileDeleteModalButton } from '../ProfileDeleteModal'
import { UserProfileMainInfoSection } from './UserProfileMainInfoSection'
import { UserProfileAvatarUpload } from './UserProfileAvatarUpload'

export type CreateWorkspaceModalProps = {
  onClose: () => void
}

export const UserProfileModal: FC<CreateWorkspaceModalProps> = observer(
  ({ onClose }) => {
    const user = store.usersStore.currentLoggedInUser
    const [displayName, setDisplayName] = useState(user.displayName)
    const [fullName, setFullName] = useState(user.fullName)
    const [notificationsSettings, setNotificationsSettings] = useState(
      user.notificationsSettings,
    )
    const [loading, setLoading] = useState(false)

    const onCreate = useCallback(async () => {
      if (loading) return

      setLoading(true)
      try {
        await user.setUserProfileData({
          displayName: displayName.trim(),
          fullName: fullName.trim(),
          photoURL: user.photoURL,
          notificationsSettings,
        })
        onClose()
      } catch (e) {
        handleError(e)
        store.uiStore.showWarningToast(
          'Something wrong happened updating your profile, please try again',
        )
        setLoading(false)
      }
    }, [onClose, loading, displayName, fullName, user, notificationsSettings])

    const isSaveDisabled = !displayName.trim() || !fullName.trim()
    return (
      <Modal onClose={onClose} className='lg:w-[600px] bg-800 py-lg'>
        <div className='flex flex-col gap-lg'>
          <div className='flex items-center self-stretch justify-between'>
            <Text size='xl' bold color='200'>
              Edit your profile
            </Text>
          </div>
          <div className='-mx-lg'>
            <CurrentPlanCard />
          </div>
          <div className='flex gap-lg'>
            <UserProfileAvatarUpload
              displayName={user.displayName}
              photoURL={user.photoURL}
              uploadUserPhoto={(file) => user.uploadUserPhoto(file)}
            />
            <div className='flex-1 flex flex-col gap-lg'>
              <UserProfileMainInfoSection
                displayName={displayName}
                fullName={fullName}
                setDisplayName={setDisplayName}
                setFullName={setFullName}
                email={user.email}
              />
              <div className='flex flex-col gap-sm'>
                <Caption label='Notifications Settings' className='mb-xs' />
                <Switch
                  label='Notify on Channel Updates'
                  value={notificationsSettings.notifyChannelsUpdates}
                  onChangeValue={v =>
                    setNotificationsSettings(s => ({
                      ...s,
                      notifyChannelsUpdates: v,
                    }))
                  }
                />
                <Switch
                  label='Notify on Direct Messages'
                  value={notificationsSettings.notifyOnDirectChannelsUpdates}
                  onChangeValue={v =>
                    setNotificationsSettings(s => ({
                      ...s,
                      notifyOnDirectChannelsUpdates: v,
                    }))
                  }
                />
                <Switch
                  label='Notify on Mentions'
                  value={notificationsSettings.notifyOnMentions}
                  onChangeValue={v =>
                    setNotificationsSettings(s => ({
                      ...s,
                      notifyOnMentions: v,
                    }))
                  }
                />
                <ProfileDeleteModalButton
                  renderButton={onOpenModal => (
                    <LinkButton
                      className=' text-red'
                      size='sm'
                      onClick={onOpenModal}
                    >
                      <Trash size={16} className='mr-xxs inline-block' />
                      Delete profile
                    </LinkButton>
                  )}
                />
              </div>
            </div>
          </div>

          <div className='flex justify-end border-t border-600 -mx-xl px-xl pt-md -mb-sm'>
            <Button bordered onClick={onClose} className='mr-md'>
              Cancel
            </Button>
            <Button
              onClick={onCreate}
              isFetching={loading}
              disabled={isSaveDisabled}
            >
              Save changes
            </Button>
          </div>
        </div>
      </Modal>
    )
  },
)

type UserProfileModalButtonProps = {
  onCloseParent: () => void
  renderButton: (v: () => void) => ReactNode
  isInitiallyOpened?: boolean
} & HTMLAttributes<HTMLDivElement>

export const UserProfileModalButton: FC<UserProfileModalButtonProps> = ({
  onCloseParent,
  renderButton,
  isInitiallyOpened = false,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(isInitiallyOpened)

  return (
    <div {...rest}>
      {renderButton(() => setOpen(true))}
      {isOpen && (
        <UserProfileModal
          onClose={() => {
            onCloseParent()
            setOpen(false)
          }}
        />
      )}
    </div>
  )
}
