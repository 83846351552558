import { collection, query } from '@firebase/firestore'
import { TProductModel, TProductPriceModel } from '@takle/models/models'
import { where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { Collections } from './constants'
import { converter } from './converter'
import { firebaseFunctions, fireStore } from './firebase'
import { getCollectionData } from './generic'

/*

Payments

*/

enum PaymentsCallableFuntions {
  StartSubscription = 'v2PaymentsStartSubscription',
}

export const getStripeProductsCollectionRef = () => {
  return collection(fireStore, Collections.StripeProducts).withConverter(
    converter,
  )
}

export const getStripeProductPricesCollectionRef = (productId: string) => {
  return collection(
    fireStore,
    Collections.StripeProducts,
    productId,
    Collections.StripeProductPrices,
  ).withConverter(converter)
}

export const getStripeProducts = () =>
  getCollectionData({
    query: query(getStripeProductsCollectionRef(), where('active', '==', true)),
    tModel: TProductModel,
  })

export const getStripeProductPrices = (productId: string) =>
  getCollectionData({
    query: query(
      getStripeProductPricesCollectionRef(productId),
      where('active', '==', true),
    ),
    tModel: TProductPriceModel,
  })

type StartSubscriptionParams = {
  source: string
  priceId: string
}

export const startSubscription = (params: StartSubscriptionParams) =>
  httpsCallable<StartSubscriptionParams>(
    firebaseFunctions,
    PaymentsCallableFuntions.StartSubscription,
  )(params)
