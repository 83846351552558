import { Caption } from '@takle/components/Caption'
import { FC, HTMLAttributes } from 'react'

type SeparatorProps = {
  containerClassName?: string
  label: string
} & HTMLAttributes<HTMLDivElement>

export const ListSeparator: FC<SeparatorProps> = ({
  label,
  className,
  containerClassName,
}) => {
  return (
    <div className={`flex justify-center py-xxs ${containerClassName || ''}`}>
      <Caption
        label={label}
        className={`bg-200 px-xs rounded-xs py-xxs text-900 ${className || ''}`}
      />
    </div>
  )
}
