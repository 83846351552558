import { HTMLAttributes } from 'react'
import { FC } from 'react'
import { useSpring, animated } from '@react-spring/web'

import { Portal } from './Portal'

type OverlayProps = {
  testId?: string
  onClose: () => void
} & HTMLAttributes<HTMLDivElement>

export const Overlay: FC<OverlayProps> = ({
  testId,
  onClose,
  children,
  className,
  ...rest
}) => {
  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  })

  const fadeInFromBottom = useSpring({
    from: {
      translateY: 20,
      opacity: 0,
    },
    to: {
      translateY: 0,
      opacity: 1,
    },
    config: {
      tension: 600,
      friction: 50,
      mass: 1,
    },
    delay: 200,
  })

  return (
    <Portal layer='overlay'>
      <div className='flex w-screen h-screen items-center justify-center backdrop-blur'>
        <animated.div
          style={fadeIn}
          className='absolute w-screen h-screen top-0 left-0 bg-800/40'
          onClick={onClose}
        />
        <div className='p-xl w-full h-full overflow-hidden flex flex-col'>
          <animated.div
            data-testid={testId}
            style={fadeInFromBottom}
            className={`relative ${className || ''}`}
            {...rest}
          >
            {children}
          </animated.div>
        </div>
      </div>
    </Portal>
  )
}
