import { animated, useSpring } from '@react-spring/web'
import { FC, ReactNode, useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { ArrowBarLeft, ArrowBarRight, Download, X } from 'tabler-icons-react'
import { IconButton } from './Button'
import { Text } from './Text'
import { Overlay } from './Overlay'
import { formatFileSize } from '@takle/utils/format'
import { downloadStorageFile } from '@takle/utils/download'

type ImagePreviewImage = {
  name?: string
  size?: number
  url: string
}

export type ImagePreviewOverlayProps = {
  initialImageIndex?: number
  images: ImagePreviewImage[]
  onClose: () => void
  content?: ReactNode
}

export const ImagePreviewOverlay: FC<ImagePreviewOverlayProps> = ({
  images,
  onClose,
  initialImageIndex = 0,
  content,
}) => {
  const [index, setIndex] = useState(
    images.length && initialImageIndex < images.length ? initialImageIndex : 0,
  )

  const [fadeIn] = useSpring(
    {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    [],
  )
  const [bgFadeIn] = useSpring(
    {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 0.2,
      },
    },
    [],
  )

  const image = images[index]
  const hasPrevImage = index > 0 && images[index - 1]
  const hasNextImage = !!images[index + 1]

  const onPrevImage = useCallback(
    () => hasPrevImage && setIndex(i => i - 1),
    [hasPrevImage],
  )
  const onNextImage = useCallback(
    () => hasNextImage && setIndex(i => i + 1),
    [hasNextImage],
  )

  const onDownload = () => {
    if (!image) return
    downloadStorageFile({ url: image.url, name: image.name || 'image.jpg' })
  }

  useHotkeys('esc', onClose)
  useHotkeys('right', onNextImage, [onNextImage])
  useHotkeys('left', onPrevImage, [onPrevImage])

  return (
    <Overlay
      onClose={onClose}
      className='bg-800 rounded-lg ring-3 ring-blue/50 border-blue border flex flex-1 flex-col justify-center items-center overflow-hidden'
    >
      {image && (
        <>
          <animated.div
            key={index}
            className='absolute w-full h-full blur-xl'
            style={{
              ...bgFadeIn,
              backgroundSize: 'cover',
              backgroundImage: `url(${image.url})`,
              backgroundPosition: 'center center',
            }}
          />
          <animated.img
            key={index}
            src={image.url}
            className='absolute max-w-full max-h-full'
            style={fadeIn}
          />
        </>
      )}
      <div className='absolute left-0 top-0'>
        <div className='px-lg pt-md flex flex-col gap-xxs'>
          {image?.name !== undefined && (
            <Text>
              {image.name}{' '}
              {image?.size !== undefined && (
                <Text size='sm' color='200'>
                  ({formatFileSize(image.size)})
                </Text>
              )}
            </Text>
          )}
          {content}
        </div>
      </div>
      {hasPrevImage && <ArrowButton onClick={onPrevImage} />}
      {hasNextImage && <ArrowButton right onClick={onNextImage} />}
      <IconButton onClick={onDownload} className='absolute right-14 top-md'>
        <Download />
      </IconButton>
      <IconButton onClick={onClose} className='absolute right-md top-md'>
        <X />
      </IconButton>
    </Overlay>
  )
}

const ArrowButton: FC<{ right?: boolean; onClick: () => void }> = ({
  right,
  onClick,
}) => {
  return (
    <div
      className={`absolute w-12 h-12 flex items-center justify-center rounded-full bg-400/20 text-200 ${
        right ? 'right-md' : 'left-md'
      }`}
      onClick={onClick}
    >
      {right ? <ArrowBarRight /> : <ArrowBarLeft />}
    </div>
  )
}
