import { observer } from 'mobx-react-lite'
import { FC, HTMLAttributes, useRef } from 'react'
import { Dots } from 'tabler-icons-react'

import { Avatar } from '@takle/components/Avatar'
import { Text } from '@takle/components/Text'
import { store } from '@takle/store'
import { Message, PendingMessage } from '@takle/store/channelsStore'
import { formatRelativeDate, formatTime } from '@takle/utils/format'
import {
  MessageFiles,
  PendingMessageFiles,
} from '@takle/pages/WorkspacePage/components/UserMessage/MessageFiles'
import { PopupButton } from '@takle/components/Popup'
import { convertDeltaStrToHtmlStr } from '../MessageInput/utils'
import './UserMessage.css'
import { ListSeparator } from '../ListSeparator'

type UserMessageProps = {
  isEditing: boolean
  showUnread: boolean
  showUser: boolean
  showDate: boolean
  message: Message | PendingMessage
  onImageLoad?: () => void
} & HTMLAttributes<HTMLDivElement>

export const UserMessage: FC<UserMessageProps> = observer(
  ({
    message,
    showUser,
    showDate,
    showUnread,
    isEditing,
    className,
    onImageLoad,
    ...rest
  }) => {
    const isPending = message.type === 'PendingMessage' && !message.hasError
    const currentUser = store.usersStore.currentLoggedInUser
    const user = store.usersStore.userById(message.userId)
    const ref = useRef<HTMLDivElement>(null)
    const status = currentUser.id === user.id ? 'online' : user.status

    const cleanHtml = {
      __html: convertDeltaStrToHtmlStr(message.text),
    }

    const errorButton =
      message.type === 'PendingMessage' && message.hasError ? (
        <Text
          color='red'
          size='sm'
          className='cursor-pointer underline hover:no-underline'
          onClick={() => message.send()}
        >
          Retry sending this message
        </Text>
      ) : null

    return (
      <>
        {showUnread && <ListSeparator label='new' className='bg-pink' />}
        {showDate && (
          <ListSeparator label={formatRelativeDate(message.createdAt)} />
        )}
        <div
          ref={showUnread ? ref : undefined}
          className={`group relative flex hover:bg-700/30 text-0
          ${isEditing ? 'pointer-events-none bg-blue/20' : ''}
          ${className || ''}`}
          {...rest}
        >
          <div>
            {showUser ? (
              <div className='w-xxl'>
                <Avatar
                  status={status}
                  imageUrl={user.photoURL}
                  className='mt-1'
                  name={user.displayName}
                />
              </div>
            ) : (
              <div className='w-xxl group-hover:opacity-100 opacity-0 transition-opacity'>
                <Text color='400' size='xs'>
                  {formatTime(message.createdAt, false)}
                </Text>
              </div>
            )}
          </div>
          <div className='ml-xs flex flex-col flex-1 gap-xxs break-all'>
            {showUser && (
              <Text>
                <b>{user.displayName}</b>
                <Text size='sm' color='400' className='ml-sm'>
                  {formatTime(message.createdAt)}
                </Text>
              </Text>
            )}
            <div
              className={`text-base message-content ${
                isPending ? 'animate-pulse opacity-80' : ''
              }`}
              dangerouslySetInnerHTML={cleanHtml}
            />
            {message.type === 'PendingMessage' ? (
              <PendingMessageFiles message={message} />
            ) : (
              <MessageFiles message={message} author={user} onImageLoad={onImageLoad} />
            )}
            {message.type === 'Message' &&
              message.createdAt.valueOf() !== message.updatedAt.valueOf() && (
                <Text size='xs' color='400'>
                  Edited
                </Text>
              )}
            {errorButton}
          </div>
          {currentUser.id === message.userId && message.type === 'Message' && (
            <PopupButton
              renderButton={openPopup => (
                <div
                  className='md:opacity-0 cursor-pointer group-hover:opacity-100 transition-opacity absolute right-lg -top-xs bg-800 hover:bg-600 border rounded-xs px-xxs border-600'
                  onClick={openPopup}
                >
                  <Dots size={14} />
                </div>
              )}
            >
              {onClose => (
                <>
                  <div
                    onClick={e => {
                      message.channel.setEditingMessage(message)
                      onClose(e)
                    }}
                    className='border-t border-600 px-md py-xs hover:bg-700 w-40 cursor-pointer'
                  >
                    <Text size='sm' color='200'>
                      Edit Message
                    </Text>
                  </div>
                  <div
                    onClick={e => {
                      store.uiStore.setAlert({
                        title: 'Delete message',
                        message: 'Do you really want to delete this message?',
                        buttons: [
                          {
                            label: 'Cancel',
                          },
                          {
                            label: <Text color='red'>Yes, delete</Text>,
                            onClick: () => message.delete(),
                          },
                        ],
                      })

                      onClose(e)
                    }}
                    className='border-t border-600 px-md py-xs hover:bg-700 w-40 cursor-pointer'
                  >
                    <Text size='sm' color='red'>
                      Delete
                    </Text>
                  </div>
                </>
              )}
            </PopupButton>
          )}
        </div>
      </>
    )
  },
)
