import { useSpring, animated } from '@react-spring/web'
import { FC, HTMLAttributes, useEffect } from 'react'

import { Text } from './Text'

type SwitchProps = {
  onChangeValue: (v: boolean) => void
  value: boolean
  label: string
} & HTMLAttributes<HTMLDivElement>

export const Switch: FC<SwitchProps> = ({
  label,
  onChangeValue,
  value,
  className,
  ...rest
}) => {
  const [styles, set] = useSpring(() => ({ left: value ? 16 : 0 }))

  useEffect(() => {
    set({ left: value ? 16 : 0 })
  }, [set, value])

  return (
    <div
      onClick={() => onChangeValue(!value)}
      className={`flex self-stretch cursor-pointer justify-between items-center ${
        className || ''
      }`}
    >
      <div className='flex-1 pr-md'>
        <Text color='0'>{label}</Text>
      </div>
      <div className='relative w-10 h-6 rounded-2xl ring-2 ring-600 ring-offset-800 ring-offset-2'>
        <animated.div
          style={styles}
          className={`w-6 h-6 rounded-2xl absolute transition-colors ${
            value ? 'bg-green' : 'bg-400'
          }`}
        ></animated.div>
      </div>
    </div>
  )
}
