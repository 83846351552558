import { InputHTMLAttributes } from 'react'

type FormFieldLabelProps = InputHTMLAttributes<HTMLLabelElement> & {
  label: string
}
export const FormFieldLabel = ({
  label,
  ...otherProps
}: FormFieldLabelProps) => {
  return (
    <label className='block mb-2 text-base font-medium text-0' {...otherProps}>
      {label}
    </label>
  )
}
