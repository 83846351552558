import { store } from '@takle/store'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Avatar } from '../Avatar'
import { FilePicker } from '../FilePicker'
import { LinkButton } from '../Button'

interface UserProfileAvatarUploadProps {
  displayName: string
  photoURL?: string
  uploadUserPhoto: (file: File) => Promise<void>
}

export const UserProfileAvatarUpload: FC<UserProfileAvatarUploadProps> =
  observer(({ displayName, photoURL, uploadUserPhoto }) => {

    return (
        <div className='w-24 flex flex-col items-center'>
        <Avatar
          className='mb-sm'
          name={displayName}
          imageUrl={photoURL}
          size='xl'
        />
        <FilePicker
          accept='image/jpg, image/jpeg, image/png'
          onFiles={files => {
            uploadUserPhoto(files[0]!)
            store.uiStore.showInfoToast('Uploading your new profile photo...')
          }}
        >
          {onClick => (
            <LinkButton size='sm' onClick={onClick}>
              Upload photo
            </LinkButton>
          )}
        </FilePicker>
      </div>
    )
  })
