import { FC, HTMLAttributes } from 'react'
import { observer } from 'mobx-react-lite'
import { MessagePlus, Plus, Settings } from 'tabler-icons-react'

import { store } from '@takle/store'
import { Caption } from '@takle/components/Caption'
import { IconButton } from '@takle/components/Button'
import { Avatar } from '@takle/components/Avatar'
import { Text } from '@takle/components/Text'
import { AvatarTitle } from '@takle/components/AvatarTitle'
import { InviteModalButton } from '@takle/components/InviteModal'
import { ChannelCreateButton } from '@takle/components/ChannelCreateModal'
import { WorkspaceEditModalButton } from '@takle/components/WorkspaceEditModal'
import { Workspace } from '@takle/store/workspacesStore'
import { User } from '@takle/store/usersStore'
import { Channel } from '@takle/store/channelsStore'
import { UnreadCount } from '@takle/components/UnreadCount'
import { directChannelIdFromUserIds } from '@takle/firebase'
import SimpleBar from 'simplebar-react'

type ChannelsBarProps = {
  workspace: Workspace
  activeChannelId?: string
  onOpenChannel: (id: string) => void
  onOpenDirectChannel: (id: string) => void
  onWorkspaceRemoved?: () => void
}

export const ChannelsBar: FC<ChannelsBarProps> = observer(
  ({
    workspace,
    activeChannelId,
    onOpenChannel,
    onOpenDirectChannel,
    onWorkspaceRemoved,
  }) => {
    const currentUser = store.usersStore.currentLoggedInUser
    const currentAccount = store.accountsStore.currentAccount

    const channels = store.channelsStore.channels.filter(
      c => c.workspaceId === workspace.id,
    )

    const isOwner = workspace?.accountId === currentAccount?.id
    const userCanCreateChannels =
      isOwner || workspace?.settings.usersCanCreateChannels

    const workspaceUsers = Object.keys(workspace.userIds)
      .filter(uid => workspace.userIds[uid])
      .map(id => store.usersStore.userById(id))
      .reduce<User[]>(
        (acc, user) =>
          currentUser.id === user.id ? [user, ...acc] : [...acc, user],
        [],
      )

    const renderContent = () => {
      if (!workspace) return null

      return (
        <>
          <div className='group pl-lg pr-md pt-lg flex w-full items-center justify-between'>
            <div className='flex-1'>
              <AvatarTitle
                imageUrl={workspace.imageURL}
                name={workspace.name}
                label={workspace.name}
                className='max-w-[200px] sm:max-w-full'
              />
            </div>
            <div>
              {currentUser.id === workspace.accountId && (
                <WorkspaceEditModalButton
                  workspace={workspace}
                  onWorkspaceRemoved={onWorkspaceRemoved}
                  renderButton={onOpenModal => (
                    <IconButton
                      onClick={onOpenModal}
                      className='opacity-30 group-hover:opacity-100 transition-opacity'
                    >
                      <Settings />
                    </IconButton>
                  )}
                />
              )}
            </div>
          </div>
          <div className='flex  items-center justify-between mt-xs ml-lg mr-md'>
            <Caption label='channels' />
            <ChannelCreateButton
              onCreated={onOpenChannel}
              className={
                !userCanCreateChannels
                  ? 'pointer-events-none opacity-0'
                  : undefined
              }
              workspaceId={workspace.id}
              renderButton={onOpen => (
                <IconButton onClick={onOpen}>
                  <Plus />
                </IconButton>
              )}
            />
          </div>
          {channels.map(channel => (
            <ChannelListItem
              key={channel.id}
              channel={channel}
              className='px-lg'
              onOpenChannel={onOpenChannel}
              activeChannelId={activeChannelId}
            />
          ))}
          <div className='flex  items-center justify-between mt-lg ml-lg mr-md'>
            <Caption label='direct messages' />
            <IconButton>
              <MessagePlus />
            </IconButton>
          </div>
          {workspaceUsers.map(user => (
            <UserListItem
              key={user.id}
              user={user}
              activeChannelId={activeChannelId}
              onOpenDirectChannel={onOpenDirectChannel}
              className='px-lg'
            />
          ))}
          {isOwner && (
            <InviteModalButton
              workspace={workspace}
              renderButton={onOpenModal => (
                <ListItem
                  className='px-lg'
                  onClick={onOpenModal}
                  avatarContent='+'
                  name=''
                >
                  Invite Members
                </ListItem>
              )}
            />
          )}
        </>
      )
    }

    return (
      <div
        className='w-full md:border-r md:border-600 md:w-[300px]'
        style={maxHeightStyle}
      >
        <SimpleBar className='w-full h-full pb-lg'>{renderContent()}</SimpleBar>
      </div>
    )
  },
)

const maxHeightStyle = { maxHeight: 'calc(100vh - 56px)' }

type ListItemProps = {
  status?: 'online' | 'offline'
  imageUrl?: string
  avatarContent?: string
  isActive?: boolean
  name: string
} & HTMLAttributes<HTMLDivElement>

const ListItem: FC<ListItemProps> = observer(
  ({
    status,
    isActive,
    className,
    imageUrl,
    avatarContent,
    name,
    children,
    ...rest
  }) => {
    return (
      <div
        className={`flex items-center text-base py-xs hover:bg-700/30 cursor-pointer text-200 ${
          isActive ? 'md:bg-700/60 md:!text-0' : ''
        } ${className || ''}`}
        {...rest}
      >
        <div>
          <Avatar
            status={status}
            name={!avatarContent ? name : undefined}
            imageUrl={imageUrl}
            size='sm'
            className='mr-xs'
          >
            {avatarContent}
          </Avatar>
        </div>
        <div className={`overflow-hidden whitespace-nowrap text-ellipsis`}>
          {children}
        </div>
      </div>
    )
  },
)

type UserListItemProps = {
  user: User
  onOpenDirectChannel: (id: string) => void
  activeChannelId?: string
} & HTMLAttributes<HTMLDivElement>

export const UserListItem: FC<UserListItemProps> = observer(
  ({ user, onOpenDirectChannel, activeChannelId, className, ...rest }) => {
    const currentUser = store.usersStore.currentLoggedInUser

    const channelId = directChannelIdFromUserIds([user.id, currentUser.id])
    const channel = store.channelsStore.directChannelById(channelId)

    const unreadCount = channel?.unreadInfo?.unreadCount
    const isActive = channelId === activeChannelId
    const status = currentUser.id === user.id ? 'online' : user.status

    return (
      <ListItem
        status={status}
        imageUrl={user.photoURL}
        isActive={isActive}
        onClick={() => onOpenDirectChannel(channelId)}
        className={`px-lg ${className || ''}`}
        name={user.displayName}
        {...rest}
      >
        <Text
          bold={!!unreadCount}
          color={unreadCount || isActive ? '0' : '200'}
        >
          {user.displayName}
        </Text>
        <UnreadCount count={unreadCount} />
        {user.id === currentUser.id && (
          <Text color='400' size='sm' className='pl-xxs'>
            (you)
          </Text>
        )}
      </ListItem>
    )
  },
)

type ChannelListItemProps = {
  channel: Channel
  onOpenChannel: (id: string) => void
  activeChannelId?: string
} & HTMLAttributes<HTMLDivElement>

export const ChannelListItem: FC<ChannelListItemProps> = observer(
  ({ channel, onOpenChannel, activeChannelId, ...rest }) => {
    const unreadCount = channel.unreadInfo?.unreadCount
    const unreadMentionCount = channel.unreadInfo?.unreadMentionCount
    const isActive = activeChannelId === channel.id

    return (
      <ListItem
        avatarContent='#'
        className='px-lg'
        onClick={() => onOpenChannel(channel.id)}
        isActive={isActive}
        name={channel.name}
        {...rest}
      >
        <Text
          bold={!!unreadCount}
          color={unreadCount || isActive ? '0' : '200'}
        >
          {channel.name}
        </Text>
        <UnreadCount count={unreadMentionCount} />
      </ListItem>
    )
  },
)
