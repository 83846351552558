import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useRef,
} from 'react'

type FilePickerProps = {
  onFiles: (v: FileList) => void
  children: (onOpenPicker: () => void) => ReactNode
} & InputHTMLAttributes<HTMLInputElement>

export const FilePicker: FC<FilePickerProps> = ({
  onFiles,
  children,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFileInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (!files || !files.length) return

      onFiles(files)
    },
    [onFiles],
  )

  return (
    <>
      <input
        type='file'
        onChange={handleFileInput}
        ref={inputRef}
        className='hidden'
        {...rest}
      />
      {children(() => inputRef.current?.click())}
    </>
  )
}
