import { handleError } from '@takle/sentry'
import { store } from '@takle/store'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useState } from 'react'
import { Modal } from './Modal'
import { Button } from './Button'
import { Text } from '@takle/components/Text'
import { UserProfileMainInfoSection } from './UserPopup/UserProfileMainInfoSection'
import { UserProfileAvatarUpload } from './UserPopup/UserProfileAvatarUpload'

export type UserCompleteProfileModalProps = {
  onClose: () => void
}

export const UserCompleteProfileModal: FC<UserCompleteProfileModalProps> =
  observer(({ onClose }) => {
    const user = store.usersStore.currentLoggedInUser
    const [displayName, setDisplayName] = useState('')
    const [fullName, setFullName] = useState('')
    const [loading, setLoading] = useState(false)

    const onSave = useCallback(async () => {
      if (loading) return

      setLoading(true)
      try {
        await user.setUserProfileData({
          displayName: displayName.trim(),
          fullName: fullName.trim(),
          photoURL: user.photoURL,
          onboarding: {
            nameAdded: true,
          },
        })
        onClose()
      } catch (e) {
        handleError(e)
        store.uiStore.showWarningToast(
          'Something wrong happened updating your profile, please try again',
        )
        setLoading(false)
      }
    }, [loading, user, displayName, fullName, onClose])

    const isSaveDisabled = !displayName.trim() || !fullName.trim()

    return (
      <Modal onClose={() => {}} className='lg:w-[600px] bg-800 py-lg'>
        <div className='flex flex-col gap-lg'>
          <div className='flex items-center self-stretch justify-between'>
            <Text size='xl' bold color='200'>
              Complete your profile
            </Text>
          </div>
          <div className='flex gap-lg'>
            <UserProfileAvatarUpload
              displayName={user.displayName}
              photoURL={user.photoURL}
              uploadUserPhoto={(file) => user.uploadUserPhoto(file)}
            />
            <div className='flex-1 flex flex-col gap-lg'>
              <UserProfileMainInfoSection
                displayName={displayName}
                fullName={fullName}
                setDisplayName={setDisplayName}
                setFullName={setFullName}
              />
            </div>
          </div>

          <div className='flex justify-end border-t border-600 -mx-xl px-xl pt-md -mb-sm'>
            <Button
              onClick={onSave}
              isFetching={loading}
              disabled={isSaveDisabled}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    )
  })
