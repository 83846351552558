import { TextColor } from '@takle/components/Text'
import * as Model from '../models/models'
import { formatFileSize } from './format'

type ProductSettings = {
  titleColor?: TextColor
  benefits: string[]
}

export const productStorageSize: { [K in Model.ProductIdModel]: number } = {
  PRO: 322122547200,
  Business: 2684354560000,
}

export const productMaxUsers: { [K in Model.ProductIdModel]: number } = {
  PRO: 30,
  Business: 100,
}

export const productSettings: { [K in Model.ProductIdModel]: ProductSettings } =
  {
    Business: {
      benefits: [
        'Unlimited number of workspaces',
        `${productMaxUsers['Business']} members on all workspaces`,
        'Unlimited messages history',
        `${formatFileSize(productStorageSize['Business'])} uploads per user`,
      ],
    },
    PRO: {
      titleColor: 'green',
      benefits: [
        'Unlimited number of workspaces',
        `${productMaxUsers['PRO']} members on all workspaces`,
        'Unlimited messages history',
        `${formatFileSize(productStorageSize['PRO'])} uploads per user`,
      ],
    },
  }

export const freeStorageSize = 5368709120

export const freeProductSettings: ProductSettings = {
  titleColor: '200',
  benefits: [
    'Unlimited number of workspaces',
    'Unlimited number of members',
    '30 days of messages history',
    `${formatFileSize(freeStorageSize)} uploads total`,
  ],
}
