import { observer } from 'mobx-react-lite'
import {
  ChangeEvent,
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Trash, X } from 'tabler-icons-react'

import { Modal } from './Modal'
import { Text } from './Text'
import { Button, IconButton } from './Button'
import { Input } from './Input'
import { ReactNode } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { handleError } from '@takle/sentry'
import { store } from '@takle/store'
import { AppLauncher } from '@capacitor/app-launcher';


const KEYWORD_FOR_DELETION = 'delete';
const APPLE_ID_REVOKE_TOKEN_USER_MANUAL_URL = 'https://support.apple.com/en-us/HT210426#:~:text=Hide%20My%20Email.-,Stop%20using%20Sign%20in%20with%20Apple%20with%20an%20app,-On%20your%20iPhone'

export type ProfileDeleteModalProps = {
  onClose: () => void
}

const ProfileDeleteModal: FC<ProfileDeleteModalProps> = observer(
  ({ onClose }) => {
    const [keyword, setKeyword] = useState('')
    const [loading, setLoading] = useState(false)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const user = store.usersStore.currentLoggedInUser

    const keywordEntered = keyword.toLocaleLowerCase() === KEYWORD_FOR_DELETION

    useEffect(() => {
      inputRef.current?.focus()
      inputRef.current?.scrollIntoView()
    }, [])

    const onKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value)
    }

    const onDelete = useCallback(async () => {
      setLoading(true)
      try {
        const userSignedUpBy = store.usersStore.currentLoggedInUser.signedUpBy
        await user.deleteProfileAndSignOut()
        if ( userSignedUpBy === 'apple.com' ) {
          alert('All your information has been removed from our services. As the last step you need to revoke Apple ID authorization, press "OK" to open instruction.')
          AppLauncher.openUrl({url: APPLE_ID_REVOKE_TOKEN_USER_MANUAL_URL })
        }
        store.uiStore.showInfoToast(`Your profile has been successfully deleted`)
      } catch (e) {
        handleError(e)
        store.uiStore.showWarningToast(
          'Something wrong happened while deleting your profile, please try again',
        )
      }
      setLoading(false)
    }, [user])

    useHotkeys('esc', onClose, { enableOnFormTags: true })
    useHotkeys(
      'return',
      onDelete,
      {
        enableOnFormTags: true,
      },
      [onDelete],
    )

    return (
      <Modal onClose={onClose}>
        <div className='flex flex-col gap-md'>
          <div className='flex items-center self-stretch justify-between'>
            <Trash size={36} className='text-200' />
            <IconButton onClick={onClose}>
              <X />
            </IconButton>
          </div>
          <Text size='xl' bold color='700'>
            Delete your profile
          </Text>
          <Text color='400'>
          To confirm the action and delete your profile, please enter the keyword
            <strong> Delete</strong> below.
          </Text>
          <div>
          <Input
            ref={inputRef}
            autoFocus
            invertedColors
            value={keyword}
            placeholder='Keyword'
            onChange={onKeywordChange}
            className='w-full'
          />
          </div>
          <div className='flex justify-end'>
            <Button
              disabled={!keywordEntered}
              onClick={onDelete}
              isFetching={loading}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    )
  },
)

type ProfileDeleteModalButtonProps = {
  renderButton: (v: () => void) => ReactNode
} & HTMLAttributes<HTMLDivElement>

export const ProfileDeleteModalButton: FC<ProfileDeleteModalButtonProps> = ({
  renderButton,
}) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div>
      {renderButton(() => setOpen(true))}
      {isOpen && (
        <ProfileDeleteModal
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  )
}
