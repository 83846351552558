import { Store } from '.'
import { makeObservable, observable, action, computed } from 'mobx'
import {
  subscribeToCollectionUpdates,
  subscribeToDocUpdates,
  createWorkspaceDocument,
  userWorkspacesQuery,
  userPendingWorkspacesQuery,
  workspaceUsersQuery,
  updateWorkspaceDocument,
  uploadAvatarImage,
  getWorkspaceAvatarPath,
  addUserToWorkspaceCallable,
  removeUserFromWorkspaceCallable,
  refreshWorkspaceInvitationHash,
  joinToWorkspaceByInviteHashCallable,
  getWorkspacesTotalFilesSizeCallable,
  deleteWorkspaceDocument,
  getInviteDataByHashCallable,
  getDocData,
  getWorkspaceDocRef,
  sendInviteEmailsCallable,
  getWorkspaceInviteEmailDocRef,
} from '@takle/firebase'
import * as Model from '../models/models'
import { User } from './usersStore'
import {
  freeStorageSize,
  productStorageSize,
} from '@takle/utils/productSettings'

export class WorkspacesStore {
  uid: string | null = null
  accountId: string | null = null
  workspaces: Array<Workspace> = []
  pendingWorkspaces: Array<PendingWorkspace> = []
  store: Store
  isInitialized: boolean = false

  private workspacesUnsubscriber: (() => void) | null = null
  private pendingWorkspacesUnsubscriber: (() => void) | null = null

  constructor(store: Store) {
    this.store = store
    makeObservable(this, {
      workspaces: observable,
      isInitialized: observable,
      pendingWorkspaces: observable,
      resetStore: action,
      addWorkspaces: action,
      removeWorkspacesById: action,
      addPendingWorkspaces: action,
      deleteWorkspace: action,
      removePendingWorkspacesById: action,
    })

    store.accountsStore.registerAccountChangeHandler(
      'initializeWorkspaces',
      (action, account) => {
        if (action === 'changeCurrentAccount') {
          this.initializeStore(account.ownerId, account.id)
        }

        if (action === 'logout') {
          this.resetStore()
        }
      },
    )
  }

  async createWorkspace(name: string) {
    if (!this.uid || !this.accountId)
      throw new Error('You must be logged in to create workspace')
    return await createWorkspaceDocument({
      name,
      uid: this.uid,
      accountId: this.accountId,
    })
  }

  async getWorkspaceById(id: string) {
    return getDocData({
      ref: getWorkspaceDocRef(id),
      tModel: Model.TWorkspaceModel,
    })
  }

  addWorkspaces(changes: { model: Model.WorkspaceModel; atIndex: number }[]) {
    if (!this.isInitialized) this.isInitialized = true
    const addedIds = changes.map(c => c.model.id)
    const updated = this.workspaces
      .slice()
      .filter(w => !addedIds.includes(w.id))
    for (const change of changes) {
      const workspace = new Workspace(change.model, this)
      updated.splice(change.atIndex, 0, workspace)
    }

    this.workspaces = updated
  }

  updateWorkspaces(changes: { model: Model.WorkspaceModel }[]) {
    changes.map(change => {
      const exitingWorkspace = this.workspaces.find(
        ws => ws.id === change.model.id,
      )
      exitingWorkspace?.updateWorkspaceData(change.model)
      return null
    })
  }

  removeWorkspacesById(removedIds: string[]) {
    this.workspaces = this.workspaces.filter(w => !removedIds.includes(w.id))
  }

  addPendingWorkspaces(
    changes: { model: Model.WorkspaceModel; atIndex: number }[],
  ) {
    const addedIds = changes.map(c => c.model.id)
    const updated = this.pendingWorkspaces
      .slice()
      .filter(w => !addedIds.includes(w.id))
    for (const change of changes) {
      const workspace = new PendingWorkspace(change.model, this)
      updated.splice(change.atIndex, 0, workspace)
    }

    this.pendingWorkspaces = updated
  }

  updatePendingWorkspaces(changes: { model: Model.WorkspaceModel }[]) {
    changes.map(change => {
      const exitingWorkspace = this.pendingWorkspaces.find(ws => ws.id)
      exitingWorkspace?.updateWorkspaceData(change.model)
      return null
    })
  }

  async deleteWorkspace(workspaceId: Model.WorkspaceModel['id']) {
    await deleteWorkspaceDocument(workspaceId)

    this.removeWorkspacesById([workspaceId])
  }

  removePendingWorkspacesById(removedIds: string[]) {
    this.pendingWorkspaces = this.pendingWorkspaces.filter(
      w => !removedIds.includes(w.id),
    )
  }

  private async initializeStore(uid: string, accountId: string) {
    this.uid = uid
    this.accountId = accountId

    this.workspacesUnsubscriber = subscribeToCollectionUpdates({
      query: userWorkspacesQuery(uid),
      tModel: Model.TWorkspaceModel,
      onAdded: changes => this.addWorkspaces(changes),
      onUpdated: changes => this.updateWorkspaces(changes),
      onRemovedIds: removedIds => this.removeWorkspacesById(removedIds),
    })
    this.pendingWorkspacesUnsubscriber = subscribeToCollectionUpdates({
      query: userPendingWorkspacesQuery(uid),
      tModel: Model.TWorkspaceModel,
      onAdded: changes => this.addPendingWorkspaces(changes),
      onUpdated: changes => this.updatePendingWorkspaces(changes),
      onRemovedIds: removedIds => this.removePendingWorkspacesById(removedIds),
    })
  }

  async joinToWorkspaceByInvitationHash(inviteHash: string) {
    return joinToWorkspaceByInviteHashCallable({
      inviteHash,
    })
  }

  async getInvitationDataByHash(inviteHash: string) {
    return getInviteDataByHashCallable({
      inviteHash,
    })
  }

  async sendWorkspaceInvites(
    emails: string[],
    workspaceName: string,
    inviteLink: string,
  ) {
    return sendInviteEmailsCallable({ emails, workspaceName, inviteLink })
  }

  resetStore() {
    this.workspacesUnsubscriber?.()
    this.pendingWorkspacesUnsubscriber?.()
    this.uid = null
    this.accountId = null
    this.isInitialized = false
    this.workspaces = []
    this.pendingWorkspaces = []
  }
}

export class PendingWorkspace {
  id: string
  name: string
  accountId: string
  imageURL?: string
  description?: string
  workspacesStore: WorkspacesStore

  constructor(data: Model.WorkspaceModel, workspacesStore: WorkspacesStore) {
    this.id = data.id
    this.name = data.name
    this.accountId = data.accountId
    if (data.imageURL) this.imageURL = data.imageURL
    if (data.description) this.description = data.description
    this.workspacesStore = workspacesStore

    makeObservable(this, {
      name: observable,
      imageURL: observable,
      description: observable,
    })
  }

  updateWorkspaceData(data: Model.WorkspaceModel) {
    this.name = data.name
    if (data.imageURL) this.imageURL = data.imageURL
    if (data.description) this.description = data.description
  }

  async cancelRequest() {
    if (!this.workspacesStore.uid) return

    return await removeUserFromWorkspaceCallable({
      uidToRemove: this.workspacesStore.uid,
      workspaceId: this.id,
    })
  }
}

export class Workspace {
  id: string
  name: string
  accountId: string
  inviteHash: string
  inviteEmailId: string
  inviteEmail: Model.InviteEmailModel | null
  createdAt: Date
  userIds: Record<string, boolean>
  channelIds: Record<string, boolean>
  imageURL?: string
  description?: string
  settings: Model.WorkspaceSettingsModel
  defaultChannelId: string
  pendingUserIds: Record<string, boolean>
  workspacesStore: WorkspacesStore

  private usersUnsubscriber: (() => void) | null = null
  private inviteEmailUnsubscriber: (() => void) | null = null

  constructor(data: Model.WorkspaceModel, workspacesStore: WorkspacesStore) {
    this.id = data.id
    this.name = data.name
    this.accountId = data.accountId
    this.inviteHash = data.inviteHash
    this.inviteEmailId = data.inviteEmailId
    this.inviteEmail = data.inviteEmail
    this.createdAt = data.createdAt
    this.userIds = data.userIds
    this.channelIds = data.channelIds
    this.defaultChannelId = data.defaultChannelId
    this.pendingUserIds = data.pendingUserIds
    this.settings = data.settings
    if (data.imageURL) this.imageURL = data.imageURL
    if (data.description) this.description = data.description
    this.workspacesStore = workspacesStore

    makeObservable(this, {
      name: observable,
      inviteHash: observable,
      inviteEmailId: observable,
      inviteEmail: observable,
      userIds: observable,
      channelIds: observable,
      pendingUserIds: observable,
      imageURL: observable,
      description: observable,
      settings: observable,
      unreadCount: computed,
      owner: computed,
      updateWorkspaceData: action,
    })
  }

  private updateChangedUsers(changes: { model: Model.UserModel }[]) {
    changes.map(({ model }) => {
      this.workspacesStore.store.usersStore.addOrUpdateCachedUser(
        new User(model),
      )
      return null
    })
  }

  private updateInviteEmailData(data: Model.InviteEmailModel) {
    this.inviteEmail = data
  }

  updateWorkspaceData(data: Model.WorkspaceModel) {
    this.name = data.name
    this.inviteHash = data.inviteHash
    this.inviteEmailId = data.inviteEmailId
    this.inviteEmail = data.inviteEmail
    this.userIds = data.userIds
    this.channelIds = data.channelIds
    this.pendingUserIds = data.pendingUserIds
    this.defaultChannelId = data.defaultChannelId
    this.settings = data.settings
    if (data.imageURL) this.imageURL = data.imageURL
    if (data.description) this.description = data.description
  }

  subscribeToWorkspaceUsersUpdates() {
    this.usersUnsubscriber = subscribeToCollectionUpdates({
      query: workspaceUsersQuery(this.id),
      tModel: Model.TUserModel,
      onUpdated: changes => this.updateChangedUsers(changes),
    })
  }

  subscribeToWorkspaceInviteEmailUpdates() {
    this.inviteEmailUnsubscriber = subscribeToDocUpdates({
      ref: getWorkspaceInviteEmailDocRef(this.inviteEmailId),
      tModel: Model.TInviteEmailModel,
      onData: data => this.updateInviteEmailData(data),
    })
  }

  async uploadWorkspaceImage(file: File) {
    const imageURL = await uploadAvatarImage({
      path: getWorkspaceAvatarPath(this.id),
      file,
    })

    return await this.setWorkspaceData({ imageURL })
  }

  async setWorkspaceDescription(description: string) {
    return await this.setWorkspaceData({ description })
  }

  setWorkspaceData(data: Partial<Model.WorkspaceModelToSave>) {
    return updateWorkspaceDocument({
      workspaceId: this.id,
      ...data,
    })
  }

  unsubscribeFromWorkspaceUsersUpdates() {
    this.usersUnsubscriber?.()
  }

  unsubscribeFromWorkspaceInviteEmailUpdates() {
    this.inviteEmailUnsubscriber?.()
  }

  async addUser(uidToAdd: string) {
    return await addUserToWorkspaceCallable({
      uidToAdd,
      workspaceId: this.id,
    })
  }

  async removeUser(uidToRemove: string) {
    return await removeUserFromWorkspaceCallable({
      uidToRemove,
      workspaceId: this.id,
    })
  }

  async leave() {
    if (!this.workspacesStore.uid) return

    return await removeUserFromWorkspaceCallable({
      uidToRemove: this.workspacesStore.uid,
      workspaceId: this.id,
    })
  }

  async refreshInvitationHash() {
    return refreshWorkspaceInvitationHash(this.id)
  }

  async getOwnerStorageFreeSpace() {
    const maxStorageSize = this.owner.subscriptionPlan
      ? productStorageSize[this.owner.subscriptionPlan]
      : freeStorageSize

    const storageUsed = await getWorkspacesTotalFilesSizeCallable({
      accountId: this.accountId,
    })

    return Math.max(0, maxStorageSize - storageUsed.data)
  }

  get owner() {
    return this.workspacesStore.store.accountsStore.accountById(this.accountId)
  }

  getMentionList(currentUserId: string) {
    return Object.keys(this.userIds)
      .filter(id => id !== currentUserId)
      .map(id => {
        const user = this.workspacesStore.store.usersStore.userById(id)
        return {
          ...user,
          value: !!user.displayName ? user.displayName : user.email,
        }
      })
  }

  get unreadCount() {
    const channels = this.workspacesStore.store.channelsStore.channels.filter(
      c => c.workspaceId === this.id,
    )
    const directChannels =
      this.workspacesStore.store.channelsStore.directChannels.filter(
        c => c.workspaceId === this.id,
      )

    return [...channels, ...directChannels].reduce(
      (acc, c) =>
        acc + (c.unreadInfo?.unreadCount ? c.unreadInfo?.unreadCount : 0),
      0,
    )
  }
}
