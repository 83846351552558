import { Virtuoso } from 'react-virtuoso'
import { File as FileIcon, X } from 'tabler-icons-react'
import { FC, HTMLAttributes, useMemo, useState } from 'react'

import { Avatar } from '@takle/components/Avatar'
import { IconButton, LinkButton } from '@takle/components/Button'
import { Tab } from '@takle/components/Tab'
import { Text } from '@takle/components/Text'
import { observer } from 'mobx-react-lite'
import { exhaustCheck } from '@takle/utils/exhaustCheck'
import { MessageAttachment } from '@takle/models/models'
import { store } from '@takle/store'
import { formatRelativeDate } from '@takle/utils/format'
import { ChannelAvatar } from '@takle/components/ChannelAvatar'
import { Workspace } from '@takle/store/workspacesStore'

import { AddPeopleButton } from './AddPeopleButton'

enum State {
  Files = 'Images & Files',
  Members = 'Members',
}

type ChannelInfoBarProps = {
  workspace: Workspace
  channelId?: string
  directChannelId?: string
  onClose: () => void
}

type MessageAttachmentWithMessageInfo = MessageAttachment & {
  createdAt: Date
  userId: string
}

export const ChannelInfoBar: FC<ChannelInfoBarProps> = observer(
  ({ onClose, channelId, directChannelId, workspace }) => {
    const [state, setState] = useState<State>(State.Files)

    const channel = channelId
      ? store.channelsStore.channels.find(c => c.id === channelId)
      : directChannelId
      ? store.channelsStore.directChannels.find(c => c.id === directChannelId)
      : undefined

    const files = useMemo(() => {
      if (!channel) return []

      return channel.messages.reduce(
        (acc, message) => [
          ...acc,
          ...message.fileAttachments.map(a => ({
            ...a,
            createdAt: message.createdAt,
            userId: message.userId,
          })),
          ...message.imageAttachments.map(a => ({
            ...a,
            createdAt: message.createdAt,
            userId: message.userId,
          })),
        ],
        [] as MessageAttachmentWithMessageInfo[],
      )
    }, [channel])

    if (!channel) return null

    const memberUserIds =
      channel.type === 'Channel' ? Object.keys(channel.userIds) : []

    const renderContent = () => {
      switch (state) {
        case State.Files:
          if (files.length === 0)
            return (
              <div className='m-lg'>
                <Text size='lg' color='400'>
                  No files were uploaded to this channel
                </Text>
              </div>
            )

          return (
            <Virtuoso
              className='absolute w-full h-full'
              data={files}
              itemContent={(i, file) => {
                return (
                  <Attachment
                    key={i}
                    attachment={file}
                    className='px-lg py-xs'
                    onClick={() => window.open(file.url, '_blank')}
                  />
                )
              }}
            />
          )

        case State.Members:
          if (channel.type !== 'Channel') return null
          return (
            <Virtuoso
              className='flex-1'
              data={memberUserIds}
              itemContent={(i, userId) => {
                return (
                  <Member
                    className='px-lg pb-sm'
                    key={userId}
                    userId={userId}
                  />
                )
              }}
              components={{
                Footer: () => (
                  <AddPeopleButton
                    workspace={workspace}
                    channel={channel}
                    renderButton={onOpenModal => (
                      <LinkButton onClick={onOpenModal} className='mx-lg'>
                        Add people
                      </LinkButton>
                    )}
                  />
                ),
              }}
            />
          )

        default:
          return exhaustCheck(state)
      }
    }

    return (
      <div className='absolute left-0 top-0 bottom-0 right-0 md:relative bg-900 md:border-l md:border-600 md:w-[300px] flex flex-col animate-fade-from-right'>
        <ChannelAvatar
          channel={channel}
          className='mx-lg mt-lg mb-sm md:hidden'
        />
        <div className='hidden md:flex items-center justify-between mt-lg ml-lg mr-md'>
          <div className='text-lg font-semibold text-400'>Channel info</div>
          <IconButton onClick={onClose}>
            <X />
          </IconButton>
        </div>
        <div className='flex mx-lg my-sm gap-md'>
          <Tab
            onClick={() => setState(State.Files)}
            isActive={state === State.Files}
          >
            {State.Files}
          </Tab>
          {channel.type !== 'DirectChannel' && (
            <Tab
              onClick={() => setState(State.Members)}
              isActive={state === State.Members}
            >
              {State.Members}
            </Tab>
          )}
        </div>
        <div className='relative flex-1'>{renderContent()}</div>
      </div>
    )
  },
)

type AttachmentProps = {
  attachment: MessageAttachmentWithMessageInfo
} & HTMLAttributes<HTMLDivElement>

const Attachment: FC<AttachmentProps> = observer(
  ({ className, attachment, ...rest }) => {
    const avatarContent = attachment.isImage ? null : <FileIcon />
    const user = store.usersStore.userById(attachment.userId)

    return (
      <div
        className={`text-base text-0 hover:bg-700/30 relative cursor-pointer ${
          className || ''
        }`}
        {...rest}
      >
        <Avatar
          name={attachment.name}
          imageUrl={attachment.isImage ? attachment.url : undefined}
          size='md'
          className='!absolute text-900'
        >
          {avatarContent}
        </Avatar>

        <div className='pl-12 relative'>
          <div className='overflow-hidden whitespace-nowrap text-ellipsis font-semibold'>
            {attachment.name}
          </div>
          <div className='text-200 text-sm'>
            by {user.displayName}{' '}
            <span className='text-400'>
              {formatRelativeDate(attachment.createdAt)}
            </span>
          </div>
        </div>
      </div>
    )
  },
)

type MemberProps = {
  userId: string
} & HTMLAttributes<HTMLDivElement>

const Member: FC<MemberProps> = observer(({ className, userId }) => {
  const user = store.usersStore.userById(userId)
  const currentUser = store.usersStore.currentLoggedInUser
  const status = currentUser.id === userId ? 'online' : user.status

  return (
    <div className={`flex items-center gap-md ${className || ''}`}>
      <Avatar
        size='sm'
        status={status}
        name={user.displayName}
        imageUrl={user.photoURL}
      />
      <Text bold color='200'>
        {user.displayName}
        {userId === currentUser.id && (
          <Text color='400' className='font-normal ml-sm'>
            It's you
          </Text>
        )}
      </Text>
    </div>
  )
})
