import { serverTimestamp } from 'firebase/firestore'

export const DEFAULTS = {
  ChannelName: 'general',
  WorkspaceSettings: {
    ownerApprovesInvites: false,
    usersCanCreateChannels: false,
  },
}

export enum Collections {
  Users = 'users-v2',
  OpenedChannels = 'opened-channels-v2',
  UserChannelUnread = 'user-channel-unread-v2',
  Workspaces = 'workspaces-v2',
  DeletedWorkspaces = 'deleted-workspaces-v2',
  Channels = 'channels-v2',
  DirectChannels = 'direct-channels-v2',
  Messages = 'messages-v2',
  UserTokens = 'user-fcm-tokens-v2',
  StripeProducts = 'stripe-products',
  StripeProductPrices = 'prices',
  FileSizeCounters = 'file-size-counters-v2',
  CounterShards = 'shards-v2',
  Accounts = 'accounts-v2',
  AccountsStatistics = 'accounts-statistics-v2',
  Emails = 'emails-v2',
}

export const timestamp = serverTimestamp as any
