import { FC, HTMLAttributes, useState, ReactNode } from 'react'
import SimpleBar from 'simplebar-react'
import { observer } from 'mobx-react-lite'

import { Modal } from '@takle/components/Modal'
import { Text } from '@takle/components/Text'
import { Button, LinkButton } from '@takle/components/Button'
import { store } from '@takle/store'
import { exhaustCheck } from '@takle/utils/exhaustCheck'
import { Tab } from '@takle/components/Tab'
import { Channel } from '@takle/store/channelsStore'
import { Workspace } from '@takle/store/workspacesStore'

import { Avatar } from './Avatar'
import { handleError } from '@takle/sentry'

enum State {
  Available = 'Available',
  Added = 'Members',
}

export type ChannelManageUsersModalProps = {
  channel: Channel
  onClose: () => void
}

export const ChannelManageUsersModal: FC<ChannelManageUsersModalProps> =
  observer(({ onClose, channel }) => {
    const [state, setState] = useState(State.Available)

    const workspace = store.workspacesStore.workspaces.find(
      w => w.id === channel.workspaceId,
    )

    const memberUserIds = Object.keys(channel.userIds).filter(id =>
      Boolean(channel.userIds[id]),
    )

    const availableUserIds = workspace
      ? Object.keys(workspace.userIds).filter(id => !memberUserIds.includes(id))
      : []

    if (!workspace) return null

    const renderContent = () => {
      switch (state) {
        case State.Added:
          return (
            <div className='absolute left-0 top-0 right-0 bottom-0'>
              <SimpleBar className='w-full h-full'>
                <div className='flex flex-col gap-md'>
                  {memberUserIds.map(uid => (
                    <UserListItem
                      isMember
                      workspace={workspace}
                      channel={channel}
                      key={uid}
                      userId={uid}
                    />
                  ))}
                </div>
              </SimpleBar>
            </div>
          )
        case State.Available:
          return (
            <>
              {!!availableUserIds.length ? (
                <div className='absolute left-0 top-0 right-0 bottom-0'>
                  <SimpleBar className='w-full h-full'>
                    <div className='flex flex-col gap-md'>
                      {availableUserIds.map(uid => (
                        <UserListItem
                          workspace={workspace}
                          channel={channel}
                          key={uid}
                          userId={uid}
                        />
                      ))}
                    </div>
                  </SimpleBar>
                </div>
              ) : (
                <div className='flex absolute left-0 top-0 right-0 bottom-0 flex-col justify-center items-center'>
                  <Text
                    color='600'
                    size='xl'
                    bold
                    className='px-xxlg text-center'
                  >
                    It seems that all users of this workspace already added to
                    this channel
                  </Text>
                </div>
              )}
            </>
          )

        default:
          return exhaustCheck(state)
      }
    }

    return (
      <Modal onClose={onClose} className='lg:!w-[540px] bg-800 py-lg'>
        <div className='flex items-center self-stretch justify-between'>
          <Text size='xl' bold color='200'>
            Add members to #{channel.name}
          </Text>
        </div>

        <div className='flex px-xl gap-md -mx-xl my-lg border-b border-600'>
          <Tab
            isActive={state === State.Available}
            onClick={() => setState(State.Available)}
          >
            {State.Available}{' '}
            <Text color='400' bold>
              {availableUserIds.length}
            </Text>
          </Tab>
          <Tab
            isActive={state === State.Added}
            onClick={() => setState(State.Added)}
          >
            {State.Added}{' '}
            <Text color='400' bold>
              {memberUserIds.length}
            </Text>
          </Tab>
        </div>
        <div className='h-[340px] relative'>{renderContent()}</div>
        <div className='flex justify-end border-t border-600 -mx-xl px-xl pt-md -mb-sm mt-lg'>
          <Button bordered onClick={onClose}>
            Close
          </Button>
        </div>
      </Modal>
    )
  })

type UserListItemProps = {
  isMember?: boolean
  channel: Channel
  workspace: Workspace
  userId: string
} & HTMLAttributes<HTMLDivElement>

const UserListItem: FC<UserListItemProps> = observer(
  ({ userId, className, channel, workspace, isMember, ...rest }) => {
    const user = store.usersStore.userById(userId)
    const currentUser = store.usersStore.currentLoggedInUser
    const currentAccount = store.accountsStore.currentAccount
    const status = currentUser.id === userId ? 'online' : user.status

    const [isFetching, setFetching] = useState(false)

    const isCurrentUser = user.id === currentUser.id
    const isChannelOwner = currentUser.id === channel.ownerId
    const isWorkspaceOwner = currentAccount?.id === workspace.accountId

    const canRemoveUser = isCurrentUser || isChannelOwner || isWorkspaceOwner

    const onRemove = () => {
      if (!canRemoveUser) return

      const removeUser = async () => {
        setFetching(true)
        try {
          await channel.removeUser(userId)
          store.uiStore.showInfoToast(
            `${user.displayName} was removed from the channel`,
          )
        } catch (e) {
          handleError(e)
          store.uiStore.showWarningToast(
            'An error occurred while adding a user to the channel, please try again',
          )
        }
        setFetching(false)
      }

      store.uiStore.setAlert({
        title: isCurrentUser ? 'Leave channel' : 'Remove Member',
        message: isCurrentUser
          ? `Are you sure you want to leave #${channel.name}?`
          : `Remove ${user.displayName} from  #${channel.name}?`,
        buttons: [
          {
            label: 'Cancel',
          },
          {
            label: (
              <Text color='red'>Yes, {isCurrentUser ? 'leave' : 'remove'}</Text>
            ),
            onClick: removeUser,
          },
        ],
      })
    }

    const onAdd = async () => {
      setFetching(true)
      try {
        await channel.addUser(userId)
        store.uiStore.showInfoToast(
          `${user.displayName} was added to the channel`,
        )
      } catch (e) {
        handleError(e)
        store.uiStore.showWarningToast(
          'An error occurred while removing a user from the channel, please try again',
        )
      }
      setFetching(false)
    }

    return (
      <div
        className={`flex items-center justify-between ${
          isFetching ? 'animate-pulse pointer-events-none' : ''
        } ${className || ''}`}
        {...rest}
      >
        <div className='flex items-center gap-md'>
          <Avatar
            size='sm'
            status={status}
            name={user.displayName}
            imageUrl={user.photoURL}
          />
          <Text bold color='200'>
            {user.displayName}
            {isCurrentUser && (
              <Text color='400' className='font-normal ml-sm'>
                It's you
              </Text>
            )}
          </Text>
        </div>

        <div className='flex gap-sm'>
          {isMember ? (
            <>
              {canRemoveUser && (
                <LinkButton className='text-red' onClick={onRemove}>
                  {isCurrentUser ? 'Leave' : 'Remove'}
                </LinkButton>
              )}
            </>
          ) : (
            <LinkButton className='text-green' onClick={onAdd}>
              Add
            </LinkButton>
          )}
        </div>
      </div>
    )
  },
)

type ChannelManageUsersModalButtonProps = {
  onCloseParent?: () => void
  renderButton: (v: () => void) => ReactNode
  isInitiallyOpened?: boolean
  channel: Channel
} & HTMLAttributes<HTMLDivElement>

export const ChannelManageUsersModalButton: FC<ChannelManageUsersModalButtonProps> =
  observer(
    ({
      channel,
      onCloseParent,
      renderButton,
      isInitiallyOpened = false,
      ...rest
    }) => {
      const [isOpen, setOpen] = useState(isInitiallyOpened)

      return (
        <div {...rest}>
          {renderButton(() => setOpen(true))}
          {isOpen && (
            <ChannelManageUsersModal
              channel={channel}
              onClose={() => {
                onCloseParent?.()
                setOpen(false)
              }}
            />
          )}
        </div>
      )
    },
  )
