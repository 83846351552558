import { FC, HTMLAttributes, ReactNode } from 'react'
import { Check } from 'tabler-icons-react'
import getSymbolFromCurrency from 'currency-symbol-map'

import { Text, TextColor } from '@takle/components/Text'

export type ProductCardProps = {
  name: string
  title: string
  titleColor?: TextColor
  description: string
  button: ReactNode
  proBenefits?: string[]
  benefits: string[]
}

export const ProductCard: FC<ProductCardProps> = ({
  children,
  button,
  benefits,
  name,
  title,
  titleColor,
  description,
}) => {
  return (
    <div className='flex flex-col p-xl xl:p-xxl gap-10 self-stretch justify-between bg-0 rounded-md'>
      <div className='flex flex-col gap-sm'>
        <Text color={titleColor || '600'} size='xs' bold>
          {title.toUpperCase()}
        </Text>
        <Text color='900' size='display-sm' bold>
          {name}
        </Text>
        <Text color='400'>{description}</Text>
      </div>
      <div>{children}</div>
      <div className='flex flex-col gap-10 justify-between min-h-[270px]'>
        <div className='flex flex-col gap-md'>
          {benefits?.map(b => (
            <div key={b} className='flex items-center gap-sm'>
              <Check className='text-200' size={20} />
              <Text color='900'>
                <b>{b.split(' ')[0]}</b> {b.split(' ').slice(1).join(' ')}
              </Text>
            </div>
          ))}
        </div>
        {button}
      </div>
    </div>
  )
}

type ProductCardPriceProps = {
  amount: number
  currency: string
} & HTMLAttributes<HTMLDivElement>

export const ProductCardPrice: FC<ProductCardPriceProps> = ({
  amount,
  currency,
  className,
  ...rest
}) => {
  return (
    <div className={`flex ${className || ''}`} {...rest}>
      <div>
        <Text color='400' size='lg' className='relative top-[2px]'>
          {getSymbolFromCurrency(currency) || currency}
        </Text>
      </div>
      <div className='mr-sm'>
        <Text color='900' bold size='display'>
          {Math.round(amount / 100)}.
        </Text>
      </div>
      <div className='self-end'>
        <Text color='400'>/ per month</Text>
      </div>
    </div>
  )
}
