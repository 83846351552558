import { FC, HTMLAttributes, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

export const Portal: FC<
  {
    layer: 'popup' | 'modal' | 'alert' | 'overlay'
  } & HTMLAttributes<HTMLDivElement>
> = ({ layer, children, className, ...rest }) => {
  const el = useRef(document.createElement('div')).current

  useEffect(() => {
    const overlayRoot = document.getElementById(`overlay-${layer}`)
    const root = document.getElementById('root')

    if (!overlayRoot || !root) {
      console.warn('Cannot find root or overlayRoot for modal')
      return
    }
    root.style.pointerEvents = 'none'
    overlayRoot.appendChild(el)

    return () => {
      root.style.pointerEvents = 'auto'
      overlayRoot.removeChild(el)
    }
  }, [el, layer])

  return createPortal(
    <div
      className={`absolute left-0 right-0 bottom-0 top-0 ${className || ''}`}
      {...rest}
    >
      {children}
    </div>,
    el,
  )
}
