import { FC, HTMLAttributes } from 'react'

import { Avatar } from '@takle/components/Avatar'
import { PopupButton } from '@takle/components/Popup'
import { UserPopupContent } from '@takle/components/UserPopup/UserPopup'
import { CurrentUser } from '@takle/store/usersStore'

type UserButtonProps = {
  user: CurrentUser
} & HTMLAttributes<HTMLDivElement>

export const UserButton: FC<UserButtonProps> = ({
  user,
  className,
  ...rest
}) => {
  return (
    <PopupButton
      renderButton={openPopup => (
        <div
          onClick={openPopup}
          className={`flex items-center gap-md text-base cursor-pointer font-semibold text-200 hover:text-0 ${
            className || ''
          }`}
          {...rest}
        >
          {user.displayName}
          <Avatar size='sm' name={user.displayName} imageUrl={user.photoURL} />
        </div>
      )}
    >
      {onClose => <UserPopupContent onClose={onClose as any} />}
    </PopupButton>
  )
}
