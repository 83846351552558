import { observer } from 'mobx-react-lite'
import { FC, ReactNode } from 'react'

import { Channel } from '@takle/store/channelsStore'
import { InviteModalButton } from '@takle/components/InviteModal'
import { ChannelManageUsersModalButton } from '@takle/components/ChannelManageUsersModal'
import { Workspace } from '@takle/store/workspacesStore'

type AddPeopleButtonProps = {
  workspace: Workspace
  channel: Channel
  renderButton: (onOpen: () => void) => ReactNode
  onCloseParent?: () => void
}

export const AddPeopleButton: FC<AddPeopleButtonProps> = observer(
  ({ channel, workspace, renderButton, onCloseParent }) => {
    return channel.isDefault ? (
      <InviteModalButton workspace={workspace} renderButton={renderButton} onCloseParent={onCloseParent} />
    ) : (
      <ChannelManageUsersModalButton
        channel={channel}
        renderButton={renderButton}
        onCloseParent={onCloseParent}
      />
    )
  },
)
