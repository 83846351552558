import { forwardRef, InputHTMLAttributes } from 'react'

import { Text } from '@takle/components/Text'

type InputProps = {
  testId?: string
  invertedColors?: boolean
  showError?: string | boolean
} & InputHTMLAttributes<HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { testId, disabled, invertedColors, showError, className, ...rest },
    ref,
  ) => {
    return (
      <>
        <input
          ref={ref}
          data-testid={testId}
          disabled={disabled}
          className={`
      transition-all
      ring ring-0 ring-transparent
      ${
        invertedColors
          ? 'bg-200/30 text-900 border-200 placeholder:text-200'
          : `bg-800 ${
              disabled ? 'text-200 border-600' : 'text-0 border-400'
            } placeholder:text-400`
      }      
      border  
      text-base rounded-xs px-md py-sm
       focus:outline-0 focus:ring-3 
       ${
         showError
           ? 'text-red focus:ring-red/30 border-red'
           : 'focus:ring-blue/30 focus:border-blue'
       }
    
    ${className || ''}`}
          {...rest}
        />
        {typeof showError === 'string' && (
          <Text
            color='red'
            size='sm'
            data-testid={testId ? testId + '-error' : undefined}
          >
            {showError}
          </Text>
        )}
      </>
    )
  },
)
