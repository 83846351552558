import {
  FC,
  HTMLAttributes,
  useCallback,
  useState,
  ReactNode,
  ChangeEvent,
} from 'react'
import SimpleBar from 'simplebar-react'
import { observer } from 'mobx-react-lite'

import { Modal } from '@takle/components/Modal'
import { Text } from '@takle/components/Text'
import { Button, LinkButton } from '@takle/components/Button'
import { Input } from '@takle/components/Input'
import { store } from '@takle/store'
import { Workspace } from '@takle/store/workspacesStore'
import { exhaustCheck } from '@takle/utils/exhaustCheck'

import { Caption } from './Caption'
import { Avatar } from './Avatar'
import { Switch } from './Switch'
import { Tab } from './Tab'

import { FilePicker } from './FilePicker'
import { handleError } from '@takle/sentry'
import { DeleteWorkspaceButton } from './DeleteWorkspaceButton'
import { Trash } from 'tabler-icons-react'

enum State {
  General = 'General Settings',
  Users = 'Members',
  //TK-101 - Remove confirmation button for invited users in workspace settings (temporarily)
  // Pending = 'Pending Members',
}

export type EditWorkspaceModalProps = {
  workspace: Workspace
  onClose: () => void
  onWorkspaceRemoved?: () => void
}

export const WorkspaceEditModal: FC<EditWorkspaceModalProps> = observer(
  ({ onClose, workspace, onWorkspaceRemoved }) => {
    const [state, setState] = useState(State.General)
    const [name, setName] = useState(workspace.name)
    const [settings, setSettings] = useState(workspace.settings)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value)
      setError('')
    }

    const onSave = useCallback(async () => {
      if (loading) return

      const workspaceAlreadyExists = store.workspacesStore.workspaces.some(
        w => w.name === name && w.id !== workspace.id,
      )
      if (workspaceAlreadyExists) {
        setError('Workspace with this name already exists')
        return
      }

      setLoading(true)
      try {
        workspace.setWorkspaceData({ name, settings })
        onClose()
      } catch (e) {
        handleError(e)
        store.uiStore.showWarningToast(
          'Something wrong happened updating channel settings, please try again',
        )
        setLoading(false)
      }
    }, [onClose, loading, name, settings, workspace])

    // TK-101 - Remove confirmation button for invited users in workspace settings (temporarily)
    // const pendingUserIds = Object.keys(workspace.pendingUserIds).filter(id =>
    //   Boolean(workspace.pendingUserIds[id]),
    // )
    const userIds = Object.keys(workspace.userIds).filter(id =>
      Boolean(workspace.userIds[id]),
    )

    const renderContent = () => {
      switch (state) {
        case State.General:
          return (
            <div className='flex gap-lg'>
              <div className='w-24 flex flex-col items-center'>
                <Avatar
                  className='mb-sm'
                  name={workspace.name}
                  imageUrl={workspace.imageURL}
                  size='xl'
                />
                <FilePicker
                  accept='image/jpg, image/jpeg, image/png'
                  onFiles={files => {
                    workspace.uploadWorkspaceImage(files[0]!)
                    store.uiStore.showInfoToast(
                      'Uploading new workspace image...',
                    )
                  }}
                >
                  {onClick => (
                    <LinkButton size='sm' onClick={onClick}>
                      Change image
                    </LinkButton>
                  )}
                </FilePicker>
              </div>
              <div className='flex-1 flex flex-col gap-lg'>
                <div>
                  <Caption label='workspace name' className='mb-xs' />
                  <Input
                    value={name}
                    placeholder='the workspace'
                    maxLength={20}
                    className='w-full'
                    onChange={onNameChange}
                    showError={!name || error}
                  />
                </div>
                <div className='flex flex-col gap-md'>
                  <Caption label='Settings' />
                  {/* TK-101 - Remove confirmation button for invited users in workspace settings (temporarily) */}
                  {/* <Switch
                    label='Users needs your approval to join'
                    value={settings.ownerApprovesInvites}
                    onChangeValue={v =>
                      setSettings(s => ({
                        ...s,
                        ownerApprovesInvites: v,
                      }))
                    }
                  /> */}
                  <Switch
                    label='Other users can create channels'
                    value={settings.usersCanCreateChannels}
                    onChangeValue={v =>
                      setSettings(s => ({
                        ...s,
                        usersCanCreateChannels: v,
                      }))
                    }
                  />
                  <DeleteWorkspaceButton
                    workspaceId={workspace.id}
                    onComplete={() => {
                      onWorkspaceRemoved?.()
                      onClose()
                    }}
                    renderButton={onOpen => (
                      <LinkButton
                        className=' text-red'
                        size='sm'
                        onClick={onOpen}
                      >
                        <Trash size={16} className='mr-xxs inline-block' />
                        Delete workspace
                      </LinkButton>
                    )}
                  />
                </div>
              </div>
            </div>
          )

        case State.Users:
          return (
            <div className='absolute left-0 top-0 right-0 bottom-0'>
              <SimpleBar className='w-full h-full'>
                <div className='flex flex-col gap-md'>
                  {userIds.map(uid => (
                    <UserListItem
                      workspace={workspace}
                      key={uid}
                      userId={uid}
                    />
                  ))}
                </div>
              </SimpleBar>
            </div>
          )
        // TK-101 - Remove confirmation button for invited users in workspace settings (temporarily)
        // case State.Pending:
        //   return (
        //     <>
        //       {!!pendingUserIds.length ? (
        //         <div className='absolute left-0 top-0 right-0 bottom-0'>
        //           <SimpleBar className='w-full h-full'>
        //             <div className='flex flex-col gap-md'>
        //               {pendingUserIds.map(uid => (
        //                 <UserPendingListItem
        //                   workspace={workspace}
        //                   key={uid}
        //                   userId={uid}
        //                 />
        //               ))}
        //             </div>
        //           </SimpleBar>
        //         </div>
        //       ) : (
        //         <div className='flex absolute left-0 top-0 right-0 bottom-0 flex-col justify-center items-center'>
        //           <Text
        //             color='600'
        //             size='xl'
        //             bold
        //             className='px-xxlg text-center'
        //           >
        //             No users are waiting for your approval
        //           </Text>
        //         </div>
        //       )}
        //     </>
        //   )

        default:
          return exhaustCheck(state)
      }
    }
    const renderButtons = () => {
      switch (state) {
        case State.General:
          return (
            <div className='flex justify-end border-t border-600 -mx-xl px-xl pt-md -mb-sm'>
              <Button bordered onClick={onClose} className='mr-md'>
                Close
              </Button>
              <Button
                onClick={onSave}
                isFetching={loading}
                disabled={!name.trim()}
              >
                Save changes
              </Button>
            </div>
          )

        case State.Users:
          // case State.Pending:
          return (
            <div className='flex justify-end border-t border-600 -mx-xl px-xl pt-md -mb-sm'>
              <Button bordered onClick={onClose}>
                Close
              </Button>
            </div>
          )

        default:
          return exhaustCheck(state)
      }
    }

    return (
      <Modal onClose={onClose} className='lg:!w-[550px] bg-800 py-lg'>
        <div className='flex items-center self-stretch justify-between'>
          <Text size='xl' bold color='200'>
            {workspace.name}
          </Text>
        </div>

        <div className='flex px-xl gap-md -mx-xl my-lg border-b border-600'>
          <Tab
            isActive={state === State.General}
            onClick={() => setState(State.General)}
          >
            {State.General}
          </Tab>
          <Tab
            isActive={state === State.Users}
            onClick={() => setState(State.Users)}
          >
            {State.Users}{' '}
            <Text color='400' bold>
              {userIds.length}
            </Text>
          </Tab>
          {/* TK-101 - Remove confirmation button for invited users in workspace settings (temporarily) */}
          {/* <Tab
            isActive={state === State.Pending}
            onClick={() => setState(State.Pending)}
          >
            {State.Pending}
            {!!pendingUserIds.length && (
              <>
                {' '}
                <Text
                  color='800'
                  bold
                  className='bg-green px-xs rounded-sm ml-xs'
                >
                  {pendingUserIds.length}
                </Text>
              </>
            )}
          </Tab> */}
        </div>
        <div className='h-[340px] relative flex-1'>{renderContent()}</div>
        <div className='mt-lg'>{renderButtons()}</div>
      </Modal>
    )
  },
)

type UserListItemProps = {
  workspace: Workspace
  userId: string
} & HTMLAttributes<HTMLDivElement>

// TK-101 - Remove confirmation button for invited users in workspace settings (temporarily)
// const UserPendingListItem: FC<UserListItemProps> = observer(
//   ({ userId, className, workspace, ...rest }) => {
//     const user = store.usersStore.userById(userId)

//     const [isFetching, setFetching] = useState(false)

//     const onReject = async () => {
//       setFetching(true)
//       try {
//         await workspace.removeUser(userId)
//       } catch (e) {
//         handleError(e)
//         store.uiStore.showWarningToast(
//           'An error occurred while removing a user from the workspace, please try again',
//         )
//       }
//       setFetching(false)
//     }
//     const onApprove = async () => {
//       setFetching(true)
//       try {
//         await workspace.addUser(userId)
//       } catch (e) {
//         handleError(e)
//         store.uiStore.showWarningToast(
//           'An error occurred while adding a user to the workspace, please try again',
//         )
//       }
//       setFetching(false)
//     }

//     return (
//       <div
//         className={`flex items-center justify-between ${
//           isFetching ? 'animate-pulse pointer-events-none' : ''
//         } ${className || ''}`}
//         {...rest}
//       >
//         <div className='flex items-center gap-md'>
//           <Avatar
//             size='sm'
//             status={user.status}
//             name={user.displayName}
//             imageUrl={user.photoURL}
//           />
//           <Text bold color='200'>
//             {user.displayName}
//           </Text>
//         </div>

//         <div className='flex gap-sm'>
//           <LinkButton className='text-green' onClick={onApprove}>
//             Approve
//           </LinkButton>
//           <LinkButton className='text-red' onClick={onReject}>
//             Reject
//           </LinkButton>
//         </div>
//       </div>
//     )
//   },
// )

const UserListItem: FC<UserListItemProps> = observer(
  ({ userId, className, workspace, ...rest }) => {
    const user = store.usersStore.userById(userId)
    const currentUser = store.usersStore.currentLoggedInUser
    const currentAccount = store.accountsStore.currentAccount
    const status = currentUser.id === userId ? 'online' : user.status

    const [isFetching, setFetching] = useState(false)

    const isCurrentUser = user.id === currentUser.id

    const onRemove = async () => {
      const removeUser = async () => {
        setFetching(true)
        try {
          await workspace.removeUser(userId)
        } catch (e) {
          handleError(e)
          store.uiStore.showWarningToast(
            'An error occurred while removing a user from the workspace, please try again',
          )
        }
        setFetching(false)
      }

      store.uiStore.setAlert({
        title: isCurrentUser ? 'Leave Workspace' : 'Remove Member',
        message: isCurrentUser
          ? `Are you sure you want to leave ${workspace.name} workspace?`
          : `Remove ${user.displayName} from  ${workspace.name} workspace?`,
        buttons: [
          {
            label: 'Cancel',
          },
          {
            label: (
              <Text color='red'>Yes, {isCurrentUser ? 'leave' : 'remove'}</Text>
            ),
            onClick: removeUser,
          },
        ],
      })
    }

    return (
      <div
        className={`flex items-center justify-between ${
          isFetching ? 'animate-pulse pointer-events-none' : ''
        } ${className || ''}`}
        {...rest}
      >
        <div className='flex items-center gap-md'>
          <Avatar
            size='sm'
            status={status}
            name={user.displayName}
            imageUrl={user.photoURL}
          />
          <Text bold color='200'>
            {user.displayName}
          </Text>
          {isCurrentUser && <Text color='400'>It's you</Text>}
        </div>

        <div className='flex gap-sm'>
          {workspace.accountId === currentAccount?.id ? (
            <Text color='200' bold>
              Owner
            </Text>
          ) : (
            <LinkButton className='text-red' onClick={onRemove}>
              {isCurrentUser ? 'Leave' : 'Remove'}
            </LinkButton>
          )}
        </div>
      </div>
    )
  },
)

type WorkspaceEditModalButtonProps = {
  onCloseParent?: () => void
  renderButton: (v: () => void) => ReactNode
  isInitiallyOpened?: boolean
  workspace: Workspace
  onWorkspaceRemoved?: () => void
} & HTMLAttributes<HTMLDivElement>

export const WorkspaceEditModalButton: FC<WorkspaceEditModalButtonProps> =
  observer(
    ({
      workspace,
      onCloseParent,
      renderButton,
      isInitiallyOpened = false,
      onWorkspaceRemoved,
      ...rest
    }) => {
      const [isOpen, setOpen] = useState(isInitiallyOpened)

      return (
        <div {...rest}>
          {renderButton(() => setOpen(true))}
          {isOpen && (
            <WorkspaceEditModal
              workspace={workspace}
              onClose={() => {
                onCloseParent?.()
                setOpen(false)
              }}
              onWorkspaceRemoved={onWorkspaceRemoved}
            />
          )}
        </div>
      )
    },
  )
