import React, { useMemo, useCallback } from 'react'

import { Button } from '@takle/components/Button'
import { exhaustCheck } from '@takle/utils/exhaustCheck'
import { useForm, Control } from 'react-hook-form'
import { ProgressBar } from '@takle/components/ProgressBar'

import { recordQuestionnaireCallable } from '@takle/firebase'
import { FormInput } from '@takle/components/FormInput'
import { FormSelect } from '@takle/components/FormSelect'
import { observer } from 'mobx-react-lite'
import { store } from '@takle/store'

export type FormValue = {
  work: OptionType | null
  currentCommunicationMethod: OptionType | null
  mainCommunicationDrawback: string
  forWhat: OptionType | null
  position: OptionType | null
  companySize: OptionType | null
  trafficSource: OptionType | null
}

const defaultValues: FormValue = {
  work: null,
  currentCommunicationMethod: null,
  mainCommunicationDrawback: '',
  forWhat: null,
  position: null,
  companySize: null,
  trafficSource: null,
}

const fieldList: FieldItem[] = [
  {
    name: 'work',
    label: 'What work do you do?',
    type: 'select',
    options: [
      'Sales',
      'Support',
      'Customer Success',
      'Marketing',
      'Operations',
      'Business Development',
      'Software Development',
      'Other',
    ],
  },
  {
    name: 'currentCommunicationMethod',
    label: 'What are you currently using for communication between companies?',
    type: 'select',
    options: [
      'Corporate messengers (Slack, Teams)',
      'Personal messengers (WhatsApp, Facebook, Telegram)',
      'Email',
      'Other',
    ],
  },
  {
    name: 'mainCommunicationDrawback',
    label: 'What is the main drawback of your current tool?',
    type: 'input',
  },
  {
    name: 'forWhat',
    label: 'What will you use Takle for?',
    type: 'select',
    options: ['Work', 'Personal', 'Education'],
  },
  {
    name: 'position',
    label: 'What is your role?',
    type: 'select',
    options: ['CEO/Founder', 'Director', 'Team Leader', 'Employee'],
  },
  {
    name: 'companySize',
    label: 'Size of your company?',
    type: 'select',
    options: ['Less than 10', '11-50', '51-300', '301-1000', 'More than 1000'],
  },
  {
    name: 'trafficSource',
    label: 'How did you hear about Takle?',
    type: 'select',
    options: ['Google', 'LinkedIn', 'Twitter', 'Facebook', 'Word of mouth'],
  },
]

type AbstractField = {
  name: keyof FormValue
  label?: string
}

type InputField = AbstractField & {
  type: 'input'
}

type SelectField = AbstractField & {
  type: 'select'
  options: string[]
}

type FieldItem = InputField | SelectField

export const Questionnaire = observer(() => {
  const user = store.usersStore.currentLoggedInUser

  const {
    handleSubmit,
    control,
    watch,
    formState: { touchedFields, errors },
  } = useForm<FormValue>({
    mode: 'onChange',
    defaultValues,
  })
  const [loading, setLoading] = React.useState(false)

  const onSubmit = async (data: FormValue) => {
    setLoading(true)

    try {
      user.setUserProfileData({ registrationSurveyCompleted: true })
      recordQuestionnaireCallable(prepareDataForSend(data))
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
  }

  const values = watch()

  const allFieldsCount = useMemo(() => Object.keys(values).length, [values])
  const filledFieldsCount = useMemo(
    () => Object.values(values).filter(Boolean).length,
    [values],
  )

  const isShowField = useCallback(
    (idx: number) => {
      if (idx === 0) {
        return true
      }
      const prevField = fieldList[idx - 1]!

      const prevFieldValue = values[prevField.name]

      return Boolean(prevFieldValue || touchedFields[prevField.name])
    },
    [touchedFields, values],
  )

  return (
    <div className='flex items-center justify-center h-full w-full dark'>
      <div
        className={`flex flex-col justify-center m-auto mt-xs px-lg pb-xs w-[calc(theme('spacing.xxxl')*9)]`}
      >
        <div className='sticky -top-lg bg-900 z-10 pb-3 pt-xl'>
          <div className='text-200/80 mb-xs text-base'>
            Question {filledFieldsCount} of {allFieldsCount}
          </div>

          <ProgressBar progress={filledFieldsCount} total={allFieldsCount} />
          <div className='font-bold text-xl text-0 text-center mt-4'>
            Tell us about your team
          </div>
        </div>

        <div className='mt-3'>
          {preparedFieldList.map((fieldItem, idx) => (
            <RenderField
              key={fieldItem.name}
              fieldItem={fieldItem}
              control={control}
              show={isShowField(idx)}
              loading={loading}
              error={errors[fieldItem.name]?.message}
            />
          ))}
        </div>
        <Button
          isFetching={loading}
          className='mt-6'
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>
      </div>
    </div>
  )
})
const RenderField = ({
  fieldItem,
  control,
  show,
  loading,
  error,
}: {
  fieldItem: PreparedFieldItem
  control: Control<FormValue, any>
  show?: boolean
  loading?: boolean
  error?: string
}) => {
  const hideFieldClassNames = 'max-h-0 overflow-hidden'
  const fieldClassNames = `w-full transition-all duration-500 ease-out ${
    show ? '[&:not(:last-child)]:mb-4 max-h-32' : hideFieldClassNames
  }`

  const rules = { required: show ? errorMessageRequired : false }

  switch (fieldItem.type) {
    case 'input': {
      return (
        <FormInput
          fieldItem={fieldItem}
          control={control}
          loading={loading}
          classNames={fieldClassNames}
          error={error}
          rules={rules}
        />
      )
    }
    case 'select': {
      return (
        <FormSelect
          fieldItem={fieldItem}
          control={control}
          loading={loading}
          classNames={fieldClassNames}
          error={error}
          rules={rules}
        />
      )
    }
    default: {
      return exhaustCheck(fieldItem)
    }
  }
}

const errorMessageRequired = 'This field is required'

type PreparedSelectField = AbstractField & {
  type: 'select'
  options: OptionType[]
}

type PreparedFieldItem = InputField | PreparedSelectField

type OptionType = { value: string; label: string }

const mapToSelectOption = (value: string): OptionType => ({
  value,
  label: value,
})

const preparedFieldList: PreparedFieldItem[] = fieldList.map(fieldItem =>
  fieldItem.type === 'select'
    ? { ...fieldItem, options: fieldItem.options.map(mapToSelectOption) }
    : fieldItem,
)

export type PreparedData = Record<keyof FormValue, string>

const prepareDataForSend = (data: FormValue): PreparedData =>
  Object.keys(data).reduce((acc: Record<string, string>, key) => {
    const currentFieldValue = data[key as keyof FormValue]

    if (typeof currentFieldValue === 'string') {
      acc[key] = currentFieldValue
    }

    if (
      typeof currentFieldValue === 'object' &&
      !Array.isArray(currentFieldValue) &&
      currentFieldValue !== null
    ) {
      acc[key] = currentFieldValue.value
    }

    return acc
  }, {}) as PreparedData
