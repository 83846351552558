import { useEffect, useState } from 'react'

type ViewportSize = 'sm' | 'md' | 'lg' | 'xl'

export const useViewportSize = () => {
  const [size, setSize] = useState(getViewportSize(window.innerWidth))

  useEffect(() => {
    const handleWindowResize = () => {
      const newSize = getViewportSize(window.innerWidth)
      if (size === newSize) return

      setSize(newSize)
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [size])

  return size
}

const getViewportSize = (width: number): ViewportSize => {
  if (width <= 640) return 'sm'
  if (width <= 768) return 'md'
  if (width <= 1024) return 'lg'
  return 'xl'
}
