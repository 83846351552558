import { FC } from 'react'

import { Avatar } from '@takle/components/Avatar'
import { Text } from '@takle/components/Text'
import { store } from '@takle/store'

import { CurrentPlanCard } from './CurrentPlanCard'
import { UserProfileModalButton } from './UserProfileModal'

type UserPopupContentProps = {
  onClose: () => void
}

export const UserPopupContent: FC<UserPopupContentProps> = ({ onClose }) => {
  const user = store.usersStore.currentLoggedInUser

  return (
    <>
      <div className='flex items-center gap-md px-lg py-md'>
        <div>
          <Avatar size='md' name={user.displayName} imageUrl={user.photoURL} />
        </div>
        <div className='leading-3'>
          <Text bold>{user.displayName}</Text>
          <br />
          <Text color='400' size='sm'>
            {user.email}
          </Text>
        </div>
      </div>
      <CurrentPlanCard />
      <UserProfileModalButton
        onCloseParent={onClose as any}
        renderButton={onOpenModal => (
          <div
            data-testid='user-popup-edit-profile-button'
            onClick={onOpenModal}
            className='px-lg py-md hover:bg-700 w-80 cursor-pointer'
          >
            <Text color='200'>Edit your profile</Text>
          </div>
        )}
      />
      <div
        data-testid='user-popup-logout-button'
        onClick={() => user.signOut()}
        className='border-t border-600 px-lg py-md hover:bg-700 w-80 cursor-pointer'
      >
        <Text color='200'>Log out</Text>
      </div>
    </>
  )
}
