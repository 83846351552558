import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router'

import { DashboardPage } from '@takle/pages/DashboardPage/DashboardPage'
import { WorkspacePage } from '@takle/pages/WorkspacePage/WorkspacePage'
import { PricingPage } from '@takle/pages/PricingPage/PricingPage'
import { PaymentPage } from '@takle/pages/PaymentPage/PaymentPage'
import { JoinPage } from '@takle/pages/JoinPage/JoinPage'
import { PaymentPageMobile } from '@takle/pages/PaymentPage/PaymentPageMobile'
import { Capacitor } from '@capacitor/core'
import { CreateWorkspacePage } from '@takle/pages/CreateWorkspacePage/CreateWorkspacePage'

export enum AuthorizedRoutePath {
  Dashboard = '/',
  Workspace = '/workspace/:workspaceId',
  Channel = '/workspace/:workspaceId/channel/:channelId',
  DirectChannel = '/workspace/:workspaceId/direct/:directChannelId',
  Upgrade = '/upgrade',
  Pricing = '/pricing',
  Payment = '/buy/:productId/:priceId',
  Join = '/join',
  CreateWorkspace = '/create',
  Other = '/other',
}

export const AuthorizedRoutes: FC = () => {
  return (
    <Routes>
      <Route path={AuthorizedRoutePath.Dashboard} element={<DashboardPage />} />
      <Route path={AuthorizedRoutePath.Workspace} element={<WorkspacePage />} />
      <Route path={AuthorizedRoutePath.Channel} element={<WorkspacePage />} />
      <Route
        path={AuthorizedRoutePath.DirectChannel}
        element={<WorkspacePage />}
      />
      <Route path={AuthorizedRoutePath.Upgrade} element={<PricingPage />} />
      <Route
        path={AuthorizedRoutePath.Pricing}
        element={<Navigate to={AuthorizedRoutePath.Upgrade} />}
      />
      <Route
        path={AuthorizedRoutePath.Payment}
        element={
          Capacitor.isNativePlatform() ? <PaymentPageMobile /> : <PaymentPage />
        }
      />
      <Route path={AuthorizedRoutePath.Join} element={<JoinPage />} />
      <Route
        path={AuthorizedRoutePath.CreateWorkspace}
        element={<CreateWorkspacePage />}
      />
      <Route
        path='*'
        element={<Navigate to={AuthorizedRoutePath.Dashboard} />}
      />
    </Routes>
  )
}
