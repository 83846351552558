import { BrowserRouter as Router } from 'react-router-dom'
import { FC, useCallback, useEffect, useState } from 'react'
import * as Sentry from '@takle/sentry'
import { observer } from 'mobx-react-lite'
import ReactHtmlParser from 'react-html-parser'

import { ErrorBoundary } from '@takle/components/ErrorBoundary'
import { ActivityIndicator } from '@takle/components/ActivityIndicator'
import { Toast } from '@takle/components/Toast'
import { store } from '@takle/store'
import { usePushNotifications } from '@takle/pushNotifications'
import { accountMessagesSentMap, trackEvent } from '@takle/analytics'
import { SearchParams, WEB_APP_ORIGIN_URL } from '@takle/constants'

import '@takle/index.css'
import { Alert } from './components/Alert'
import { UnauthorizedRoutePath, UnauthorizedRoutes } from './navigation/UnauthorizedRoutes'
import { AuthorizedRoutePath, AuthorizedRoutes } from './navigation/AuthorizedRoutes'
import { ImagePreviewOverlay } from './components/ImagePreviewOverlay'
import { Badger } from './utils/badger'
import { RedirectByInvitationId } from './components/RedirectByInvitationId'

import { Capacitor } from '@capacitor/core'
import { App as CapApp, URLOpenListenerEvent } from '@capacitor/app'
import { buildUrl, makeInternalUrl } from './navigation/paths'
import { FirebaseMessaging, NotificationActionPerformedEvent } from '@capacitor-firebase/messaging'

import '@takle/config'
import { Channel, DirectChannel, DraftMessage } from './store/channelsStore'

if (process.env.NODE_ENV === 'production') {
  Sentry.initSentry()
}

export const App: FC = () => {
  return (
    <>
      <ErrorBoundary>
        <Pages />
      </ErrorBoundary>
      <Overlays />
    </>
  )
}

const Overlays: FC = observer(() => {
  return (
    <>
      {store.uiStore.imagePreview && (
        <ImagePreviewOverlay
          {...store.uiStore.imagePreview}
          onClose={() => store.uiStore.closeImagePreview()}
        />
      )}
      {store.uiStore.alert && <Alert {...store.uiStore.alert} />}
      <div className='z-50 fixed right-lg top-lg'>
        {[...store.uiStore.toasts.entries()].map(([id, toast]) => (
          <Toast key={id} {...toast} />
        ))}
      </div>
    </>
  )
})

const Pages: FC = observer(() => {
  const [badger] = useState(() => new Badger({}))

  const showNotificationAsToast = useCallback(
    ({ title, body }: { title?: string; body?: string }) => {
      store.uiStore.addToast({
        title,
        message: body ? <div>{ReactHtmlParser(body)}</div> : '',
      })
    },
    [],
  )

  const registerPushNotificationsForUser = usePushNotifications(
    showNotificationAsToast,
  )

  const unreadCount = store.workspacesStore.workspaces.reduce(
    (acc, w) => acc + w.unreadCount,
    0,
  )

  useEffect(() => {
    badger.value = unreadCount
  }, [unreadCount, badger])

  useEffect(() => {
    const unregisterMessageSentTrackEvent =
      store.channelsStore.registerMessageSentHandler(
        'trackMessageSent',
        async (message: DraftMessage, channel: DirectChannel | Channel) => {
          const isDirect = channel.type === 'DirectChannel'

          const workspace = store.workspacesStore.workspaces.find(
            ({ id }) => channel.workspaceId === id,
          )

          if (!workspace) {
            throw new Error('Workspace not found')
          }
          const basePayload = {
            workspaceId: workspace.id,
            accountId: workspace.accountId,
          }

          const { totalMessagesSent } =
            await store.statisticsStore.getAccountStatistics(
              workspace.accountId,
            )

          const messageSentEventType = accountMessagesSentMap[totalMessagesSent]

          if (messageSentEventType) {
            trackEvent({
              kind: messageSentEventType,
              payload: basePayload,
            })
          }

          trackEvent({
            kind: 'messageSent',
            payload: {
              ...basePayload,
              type: isDirect ? 'direct' : 'channel',
              attach: !!message.files.length,
              attachSizeKb: message.files.reduce((a, f) => a + f.file.size, 0),
            },
          })
        },
      )

    const unregisterSentrySetUser = store.usersStore.registerUserChangeHandler(
      'sentrySetUser',
      (action, user) => {
        Sentry.setUser(action === 'login' ? user : null)
      },
    )

    const unregisterTrackUserRegistered =
      store.usersStore.registerUserChangeHandler(
        'trackUserRegistered',
        async (action, user) => {
          const searchParams = new URL(document.location.href).searchParams
          const inviteLink = searchParams.get(SearchParams.InvitationId)
          const currentUser = store.usersStore.currentLoggedInUser

          const isFirstLogin =
            currentUser.firebaseUser.metadata.creationTime &&
            currentUser.firebaseUser.metadata.lastSignInTime &&
            currentUser.firebaseUser.metadata.creationTime ===
              currentUser.firebaseUser.metadata.lastSignInTime

          if (!isFirstLogin || action !== 'login') {
            return
          }

          if (!!inviteLink) {
            const inviteData =
              await store.workspacesStore.getInvitationDataByHash(inviteLink)
            trackEvent({
              kind: 'registrationCompletedByInvite',
              payload: {
                type: user.signedUpBy || '',
                accountId: inviteData.data.workspaceAccountId,
              },
              trackOnceForUserId: user.id,
            })
          } else {
            trackEvent({
              kind: 'registrationCompleted',
              payload: {
                type: user.signedUpBy || '',
                accountId: Object.keys(currentUser.accountIds)[0] || '',
              },
              trackOnceForUserId: user.id,
            })
          }
        },
      )

    return () => {
      unregisterSentrySetUser()
      unregisterTrackUserRegistered()
      unregisterMessageSentTrackEvent()
    }
  }, [])

  useEffect(() =>
    store.usersStore.registerUserChangeHandler(
      'updateProfileName',
      (action, user) => {
        if (action === 'login') {
          user.syncProfileName()
        }
      },
    )
  )

  useEffect(() => {
    const unregister = store.usersStore.registerUserChangeHandler(
      'pushNotifications',
      (action, user) => {
        registerPushNotificationsForUser(action === 'login' ? user.id : null)
      },
    )

    return () => {
      unregister()
    }
  }, [registerPushNotificationsForUser])

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      // Mobile: register for UniversalUrls event
      const subscription = CapApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        if (event.url.startsWith(WEB_APP_ORIGIN_URL)) {
          window.location.href = makeInternalUrl(event.url)
        }
      })

      return () => { subscription.remove() }
    }
  }, [])

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      // Mobile: register for notifications clicks
      const subscription = FirebaseMessaging.addListener(
        'notificationActionPerformed',
        (event: NotificationActionPerformedEvent) => {
          if (event.notification.data) {
            const {channelId, directChannelId, workspaceId} = event.notification.data as {
              channelId?: string,
              directChannelId?:string,
              workspaceId?:string
            }

            const channelUrl = function getChannelUrl() {
              if (!workspaceId) return null
              if (channelId) return buildUrl(AuthorizedRoutePath.Channel, {channelId, workspaceId})
              if (directChannelId) return buildUrl(AuthorizedRoutePath.DirectChannel, {directChannelId, workspaceId})
            }()

            if (channelUrl) {
              window.location.href = makeInternalUrl(channelUrl)
            }
          }
        }
      )

      return () => { subscription.remove() }
    }
  }, [])


  if (!store.usersStore.initialized) {
    return <ActivityIndicator />
  }

  return (
    <Router>
      {!store.usersStore.currentUser ? (
        <UnauthorizedRoutes
          defaultPath={ Capacitor.isNativePlatform()
            ? UnauthorizedRoutePath.Login
            : UnauthorizedRoutePath.Landing}
        />
      ) : store.workspacesStore.isInitialized &&
        store.channelsStore.isInitialized ? (
        <>
          <RedirectByInvitationId />
          <AuthorizedRoutes />
        </>
      ) : (
        <ActivityIndicator />
      )}
    </Router>
  )
})
