import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useState } from 'react'

import { store } from '@takle/store'
import { HeaderBar } from '@takle/components/HeaderBar/HeaderBar'
import { WorkspacesBar } from '@takle/components/WorkpacesBar'
import { ActivityIndicator } from '@takle/components/ActivityIndicator'

import { ChannelChat, DirectChannelChat } from './components/Chat'
import { ChannelsBar } from './components/ChannelsBar'
import { ChannelInfoBar } from './components/ChannelInfoBar'
import { useViewportSize } from '@takle/utils/useViewportSize'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { useParams } from 'react-router-dom'
import { getLocalStorageItem, setLocalStorageItem } from '@takle/localStorage'
import { isChannelDirect } from '@takle/firebase'

export const WorkspacePage: FC = observer(() => {
  const viewportSize = useViewportSize()
  const { workspaceId, channelId, directChannelId } = useParams()
  const workspace = store.workspacesStore.workspaces.find(
    w => w.id === workspaceId,
  )
  const [isInfoBarOpened, setInfoBarOpened] = useState(false)

  // This is used to adapt responsive ui for mobile
  const channelOrDirectChannelId = channelId || directChannelId
  const [isChannelChatOpened, setChannelChatOpened] = useState(!!channelOrDirectChannelId)
  const navigate = useStrictNavigate()

  const navigateToWorkspace = useCallback(
    (workspaceId: string) => {
      navigate(AuthorizedRoutePath.Workspace, { workspaceId })
    },
    [navigate],
  )

  const onWorkspaceRemoved = useCallback(() => {
    const workspace = store.workspacesStore.workspaces.at(0)

    if (workspace) {
      navigateToWorkspace(workspace.id)
    } else {
      navigate(AuthorizedRoutePath.Dashboard, {})
    }
  }, [navigate, navigateToWorkspace])

  const navigateToChannel = useCallback(
    (channelId: string) => {
      setChannelChatOpened(true)
      workspaceId &&
        navigate(AuthorizedRoutePath.Channel, { workspaceId, channelId })
    },
    [navigate, workspaceId],
  )

  const navigateToDirectChannel = useCallback(
    (directChannelId: string) => {
      setChannelChatOpened(true)
      workspaceId &&
        navigate(AuthorizedRoutePath.DirectChannel, {
          workspaceId,
          directChannelId,
        })
    },
    [navigate, workspaceId],
  )

  useEffect(() => {
    setChannelChatOpened(!!channelOrDirectChannelId)
    setInfoBarOpened(false)
  }, [workspaceId, channelOrDirectChannelId])

  useEffect(() => {
    if (
      !workspaceId ||
      viewportSize === 'sm' ||
      !workspace?.defaultChannelId ||
      channelId ||
      directChannelId
    )
      return

    const lastOpenedChannelId = getLocalStorageItem({
      paramKey: 'lastOpenedChannelIdForWorkspaceId',
      value: workspaceId,
    })

    if (lastOpenedChannelId) {
      const isChannelExists = !!(
        store.channelsStore.channelById(lastOpenedChannelId) ||
        store.channelsStore.directChannelById(lastOpenedChannelId)
      )

      if (isChannelExists) {
        isChannelDirect(lastOpenedChannelId)
          ? navigateToDirectChannel(lastOpenedChannelId)
          : navigateToChannel(lastOpenedChannelId)

        return
      }
    }

    navigateToChannel(workspace.defaultChannelId)
  }, [
    viewportSize,
    workspace?.defaultChannelId,
    workspaceId,
    channelId,
    directChannelId,
    navigateToChannel,
    navigateToDirectChannel,
  ])

  useEffect(() => {
    if (!workspace) return
    workspace.subscribeToWorkspaceUsersUpdates()
    return () => workspace.unsubscribeFromWorkspaceUsersUpdates()
  }, [workspace])

  useEffect(() => {
    if (!channelOrDirectChannelId || !workspaceId) return

    setLocalStorageItem(
      {
        paramKey: 'lastOpenedChannelIdForWorkspaceId',
        value: workspaceId,
      },
      channelOrDirectChannelId,
    )

    store.channelsStore.setOpenedChannelId(channelOrDirectChannelId)
    return () => store.channelsStore.setOpenedChannelId(null)
  }, [channelOrDirectChannelId, workspaceId])

  if (!workspace) return <ActivityIndicator />

  return (
    <div className='flex flex-1 flex-col max-h-screen'>
        <HeaderBar
          onMobileBackPress={
            isInfoBarOpened
              ? () => setInfoBarOpened(false)
              : isChannelChatOpened
              ? () => setChannelChatOpened(false)
              : undefined
          }
          channelId={channelId}
          directChannelId={directChannelId}
          workspace={workspace}
          onOpenChannel={navigateToChannel}
          isSidebarOpened={isInfoBarOpened}
          setSidebarOpened={setInfoBarOpened}
        />
      <div className='flex flex-1'>
        <div className='flex flex-1 relative'>
          <WorkspacesBar
            activeWorkspaceId={workspaceId}
            onChangeWorkspace={navigateToWorkspace}
          />
          <ChannelsBar
            workspace={workspace}
            activeChannelId={channelOrDirectChannelId}
            onOpenChannel={navigateToChannel}
            onOpenDirectChannel={navigateToDirectChannel}
            onWorkspaceRemoved={onWorkspaceRemoved}
          />
          {channelId && (
            <ChannelChat
              workspace={workspace}
              channelId={channelId}
              isSidebarOpened={isInfoBarOpened}
              setSidebarOpened={setInfoBarOpened}
              isChatOpenedOnMobile={isChannelChatOpened}
              onOpenChannel={navigateToChannel}
            />
          )}
          {directChannelId && (
            <DirectChannelChat
              workspace={workspace}
              directChannelId={directChannelId}
              isSidebarOpened={isInfoBarOpened}
              setSidebarOpened={setInfoBarOpened}
              isChatOpenedOnMobile={isChannelChatOpened}
            />
          )}
          {isInfoBarOpened && (channelId || directChannelId) && (
            <ChannelInfoBar
              workspace={workspace}
              onClose={() => setInfoBarOpened(false)}
              directChannelId={directChannelId}
              channelId={channelId}
            />
          )}
        </div>
      </div>
    </div>
  )
})
