import { observer } from 'mobx-react-lite'
import { FC, HTMLAttributes } from 'react'

import { store } from '@takle/store'
import { Channel, DirectChannel } from '@takle/store/channelsStore'

import { AvatarTitle } from './AvatarTitle'
import { AvatarProps } from './Avatar'

type ChannelAvatarProps = {
  channel: Channel | DirectChannel
  size?: AvatarProps['size']
} & HTMLAttributes<HTMLDivElement>

export const ChannelAvatar: FC<ChannelAvatarProps> = observer(
  ({ channel, ...rest }) => {
    if (channel.type === 'DirectChannel') {
      const currentUser = store.usersStore.currentLoggedInUser

      const userId =
        channel.userIds.find(id => id !== currentUser.id) || currentUser.id
      const user = store.usersStore.userById(userId)
      const status = currentUser.id === userId ? 'online' : user.status

      return (
        <AvatarTitle
          label={user.displayName}
          name={user.displayName}
          imageUrl={user.photoURL}
          status={status}
          {...rest}
        />
      )
    }

    return <AvatarTitle label={channel.name} name={channel.name} {...rest} />
  },
)
