import {
  onValue as dbOnValue,
  set as dbSet,
  ref as dbRef,
  serverTimestamp as dbServerTimestamp,
  onDisconnect,
} from 'firebase/database'
import { setDoc } from 'firebase/firestore'
import { timestamp } from './constants'
import { fireDB } from './firebase'
import { getUserDocRef } from './users'
import * as Model from '@takle/models/models'

/*

Presence

*/

export function subscribeUserPresence(uid: string) {
  const userStatusDatabaseRef = dbRef(fireDB, '/user-status/' + uid)

  const dbOfflineUserData = {
    status: 'offline',
    latestActivityTimestamp: dbServerTimestamp(),
  }

  const dbOnlineUserData = {
    status: 'online',
    latestActivityTimestamp: dbServerTimestamp(),
  }

  return dbOnValue(dbRef(fireDB, '.info/connected'), snapshot => {
    if (snapshot.val() === false) {
      updatedUserPresence(uid, 'offline')
      return
    }

    onDisconnect(userStatusDatabaseRef)
      .set(dbOfflineUserData)
      .then(() => {
        dbSet(userStatusDatabaseRef, dbOnlineUserData)
        updatedUserPresence(uid, 'online')
      })
  })
}

export async function updatedUserPresence(
  uid: string,
  status: Model.UserStatus,
) {
  const userData: Partial<Model.UserModel> = {
    status,
    latestActivityTimestamp: timestamp(),
  }
  return await setDoc(getUserDocRef(uid), userData, { merge: true })
}
