const PREFIX = 'takle'

export const STORAGE_KEY = {
  emailForSignIn: '@emailForSignIn',
  invitationId: '@invitationId',
  noCreationRedirect: '@noCreationRedirect',
  lastOpenedChannelIdForWorkspaceId: (workspaceId: string) =>
    `@lastOpenedChannelId:${workspaceId}`,
} as const

export const PARAM_STORAGE_KEY = {
  lastOpenedChannelIdForWorkspaceId: (workspaceId: string) =>
    `@lastOpenedChannelId:${workspaceId}`,
} as const

type StorageKey =
  | keyof typeof STORAGE_KEY
  | { paramKey: keyof typeof PARAM_STORAGE_KEY; value: string }

export function setLocalStorageItem(key: StorageKey, value: string | number) {
  return localStorage.setItem(getAccessKey(key), String(value))
}

export function getLocalStorageItem(key: StorageKey) {
  return localStorage.getItem(getAccessKey(key))
}

export function removeLocalStorageItem(key: StorageKey) {
  localStorage.removeItem(getAccessKey(key))
}

const getAccessKey = (key: StorageKey) =>
  PREFIX +
  (typeof key === 'object'
    ? PARAM_STORAGE_KEY[key.paramKey](key.value)
    : STORAGE_KEY[key])
