export function getEnv(name: string): string {
  const value: null | undefined | string = process.env[name]

  if (value == null) {
    console.warn(`\`${name}\` environment variable is not defined`)
    return ''
  }

  return value
}
