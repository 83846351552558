import { FC } from 'react'

export const EmailIcon: FC = () => {
  return (
    <svg width='90' height='90' viewBox='0 0 90 90' fill='none'>
      <g clipPath='url(#clip0_403_2035)'>
        <path
          d='M68.3465 29.7501C67.6627 29.0611 66.5498 29.0565 65.8606 29.7401C65.1715 30.4239 65.1669 31.5369 65.8507 32.2262L65.8613 32.2371C66.2035 32.5821 66.6521 32.7541 67.1012 32.7541C67.5491 32.7541 67.9977 32.5828 68.3419 32.2415C69.0311 31.5577 69.0303 30.4394 68.3465 29.7501Z'
          fill='#7381FF'
        />
        <path
          d='M89.4851 50.8889L73.1589 34.5627C72.4725 33.8764 71.3594 33.8764 70.6728 34.5627C69.9864 35.2491 69.9864 36.3621 70.6728 37.0487L83.6335 50.0093H52.7268C50.1628 50.0093 48.0772 47.9235 48.0772 45.3597V14.4531L60.5898 26.9656C61.2763 27.6518 62.3893 27.6518 63.0759 26.9656C63.7623 26.2792 63.7623 25.1661 63.0759 24.4795L47.1976 8.60136C46.5112 7.91511 45.3981 7.91511 44.7117 8.60136L16.6886 36.6249C16.0022 37.3114 16.0022 38.4244 16.6886 39.111L58.9762 81.3984C59.3058 81.728 59.7529 81.9132 60.2191 81.9132C60.6853 81.9132 61.1325 81.728 61.4621 81.3984L89.4853 53.375C89.8149 53.0452 90.0001 52.5982 90.0001 52.132C90 51.6658 89.8147 51.2186 89.4851 50.8889ZM44.5619 13.7234V36.1102H22.1751L44.5619 13.7234ZM58.4613 75.9115L22.1751 39.6257H44.5619V45.3597C44.5619 49.862 48.2249 53.5249 52.7271 53.5249H58.4613V75.9115ZM61.9768 75.9117V53.5249H84.3634L61.9768 75.9117Z'
          fill='#7381FF'
        />
        <path
          d='M12.064 44.6483H1.75781C0.786973 44.6483 0 45.4355 0 46.4061C0 47.3768 0.786973 48.1639 1.75781 48.1639H12.064C13.0349 48.1639 13.8219 47.377 13.8219 46.4061C13.8219 45.4355 13.0349 44.6483 12.064 44.6483Z'
          fill='#7381FF'
        />
        <path
          d='M17.3049 44.6483H17.2786C16.3077 44.6483 15.5208 45.4355 15.5208 46.4061C15.5208 47.3768 16.3077 48.1639 17.2786 48.1639H17.3049C18.2758 48.1639 19.0627 47.3768 19.0627 46.4061C19.0627 45.4355 18.2758 44.6483 17.3049 44.6483Z'
          fill='#7381FF'
        />
        <path
          d='M7.38554 26.1918H1.76001C0.78917 26.1918 0.00219727 26.9789 0.00219727 27.9496C0.00219727 28.9202 0.78917 29.7074 1.76001 29.7074H7.38554C8.35638 29.7074 9.14335 28.9202 9.14335 27.9496C9.14335 26.9789 8.35638 26.1918 7.38554 26.1918Z'
          fill='#7381FF'
        />
        <path
          d='M19.7771 26.1918H13.1277C12.1568 26.1918 11.3699 26.9789 11.3699 27.9496C11.3699 28.9202 12.1568 29.7074 13.1277 29.7074H19.7771C20.748 29.7074 21.535 28.9202 21.535 27.9496C21.535 26.9789 20.748 26.1918 19.7771 26.1918Z'
          fill='#7381FF'
        />
        <path
          d='M23.6043 54.316H13.7607C12.7899 54.316 12.0029 55.1032 12.0029 56.0739C12.0029 57.0445 12.7899 57.8317 13.7607 57.8317H23.6043C24.5752 57.8317 25.3621 57.0445 25.3621 56.0739C25.3621 55.1032 24.575 54.316 23.6043 54.316Z'
          fill='#7381FF'
        />
        <path
          d='M31.2903 65.5657H6.50537C5.53453 65.5657 4.74756 66.3528 4.74756 67.3235C4.74756 68.2942 5.53453 69.0813 6.50537 69.0813H31.2903C32.2612 69.0813 33.0482 68.2942 33.0482 67.3235C33.0482 66.3528 32.2612 65.5657 31.2903 65.5657Z'
          fill='#7381FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_403_2035'>
          <rect width='90' height='90' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
