import { Button } from '@takle/components/Button'
import { useHotkeys } from 'react-hotkeys-hook'
import { DeltaStatic } from 'quill'
import { observer } from 'mobx-react-lite'
import {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Paperclip, Send } from 'tabler-icons-react'

import { store } from '@takle/store'
import {
  Channel,
  DirectChannel,
  DraftMessageError,
  DRAFT_MAX_FILES,
} from '@takle/store/channelsStore'
import {
  DraftMessageFiles,
  EditingMessageFiles,
} from '@takle/pages/WorkspacePage/components/UserMessage/MessageFiles'
import { FilePicker } from '@takle/components/FilePicker'

import {
  MessageInput,
  MessageInputComponent,
  MentionItem,
} from './MessageInput/MessageInput'
import { DropFileArea } from './DropFileArea'
import { Text } from '@takle/components/Text'
import { formatFileSize } from '@takle/utils/format'
import { useNavigate } from 'react-router-dom'
import { Workspace } from '@takle/store/workspacesStore'
import { Avatar } from '@takle/components/Avatar'
import { handleError } from '@takle/sentry'
import { getConfigValue, RemoteConfigFieldName } from '@takle/firebase'
import { EMPTY_MESSAGE_QUILL } from '@takle/constants'
import { useViewportSize } from '@takle/utils/useViewportSize'

type MessagesListWithInputProps = {
  workspace: Workspace
  channel: DirectChannel | Channel
}

export const MessagesListWithInput: FC<MessagesListWithInputProps> = observer(
  ({ channel, workspace, children }) => {
    const messageInput = useRef<MessageInputComponent>(null)
    const message = channel.editingMessage || channel.draftMessage

    const [pending, setPending] = useState(false)
    const viewportSize = useViewportSize()

    const currentUser = store.usersStore.currentLoggedInUser

    const getMentionList = useCallback(
      () => workspace?.getMentionList(currentUser.id) ?? [],
      [currentUser.id, workspace],
    )

    useHotkeys(
      'esc',
      () => {
        channel.editingMessage && channel.setEditingMessage(null)
      },
      [channel.editingMessage],
    )

    const onCancel = useCallback(() => {
      channel.setEditingMessage(null)
    }, [channel])

    const onMessageSubmit = useCallback(async () => {
      if (pending) {
        return
      }
      try {
        setPending(true)
        await message.save()
        channel.setEditingMessage(null)
        if (message.type === 'DraftMessage') {
          store.channelsStore.sendMessage(message, channel)
        }
      } catch (e) {
        handleError(e)
        if (e instanceof Error) store.uiStore.showWarningToast(e.message)
      } finally {
        setPending(false)
      }
    }, [pending, message, channel])

    const onMessageChange = useCallback(
      (delta: DeltaStatic, mentionUserIds: string[]) => {
        message.setText(JSON.stringify(delta))
        message.setMentionUserIds(mentionUserIds)
      },
      [message],
    )

    useEffect(() => {
      if (viewportSize !== 'sm') {
        messageInput.current?.focus()
      }
    }, [message.id, viewportSize])

    useEffect(() => {
      try {
        if (!message.text) {
          return
        }
        const data = JSON.parse(message.text)
        messageInput.current?.setContent(data)
      } catch (e) {
        const error = new Error(`Message parse error id: ${message.id}`)
        handleError(e)
        handleError(error)
        throw error
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.id])

    const dropFileDisabled =
      !!channel.editingMessage ||
      channel.draftMessage.files.length === DRAFT_MAX_FILES

    const submitDisabled = channel.editingMessage
      ? channel.editingMessage.text === EMPTY_MESSAGE_QUILL
      : (channel.draftMessage.text === EMPTY_MESSAGE_QUILL &&
          !channel.draftMessage.files.length) ||
        !!channel.draftMessage.error

    const renderMentionItem = useCallback((item: MentionItem) => {
      return (
        <div className='flex items-center py-2 max-h-9 font-sans font-semibold'>
          <Avatar
            className='mr-md'
            name={item.displayName}
            imageUrl={item.photoURL}
            size='sm'
          />
          <div>{item.value}</div>
        </div>
      )
    }, [])

    const handleFilesAdd = useCallback(
      (v: File[] | File) => {
        channel.draftMessage.addFiles(Array.isArray(v) ? v : [v])
      },
      [channel.draftMessage],
    )

    return (
      <DropFileArea
        disabled={dropFileDisabled}
        className='flex flex-1 flex-col'
        onUploadFiles={handleFilesAdd}
      >
        {children}
        <div className='px-sm pb-xs'>
          <MessageInput
            ref={messageInput}
            onEsc={onCancel}
            onSubmit={onMessageSubmit}
            onChange={onMessageChange}
            key={message.id}
            onImagePaste={handleFilesAdd}
            defaultValue={message.text}
            disabled={submitDisabled}
            getMentionList={getMentionList}
            renderMentionItem={renderMentionItem}
          >
            {channel.editingMessage ? (
              <>
                <EditingMessageFiles message={channel.editingMessage} />
                <div className='flex justify-end gap-xxs px-xxs pb-xxs'>
                  <Button size='sm' bordered onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    size='sm'
                    disabled={submitDisabled}
                    onClick={onMessageSubmit}
                  >
                    Update
                  </Button>
                </div>
              </>
            ) : (
              <>
                <MessageError
                  workspace={workspace}
                  error={channel.draftMessage.error}
                  className='mx-sm mb-xs'
                />
                <DraftMessageFiles message={channel.draftMessage} />
                <div className='flex justify-between px-xxs'>
                  <FilePicker
                    onFiles={files => {
                      channel.draftMessage.addFiles([...files])
                    }}
                    multiple
                  >
                    {onClick => (
                      <BottomButton onClick={onClick}>
                        <Paperclip size={20} />
                      </BottomButton>
                    )}
                  </FilePicker>
                  <BottomButton
                    disabled={submitDisabled}
                    onClick={onMessageSubmit}
                  >
                    <Send size={20} className='rotate-45' />
                  </BottomButton>
                </div>
              </>
            )}
          </MessageInput>
        </div>
      </DropFileArea>
    )
  },
)

type BottomButtonProps = { disabled?: boolean } & HTMLAttributes<HTMLDivElement>

const BottomButton: FC<BottomButtonProps> = ({
  className,
  disabled,
  children,
  onClick,
  ...rest
}) => {
  return (
    <div
      className={`px-xs pb-xs ${
        !disabled ? ' text-200 hover:text-blue cursor-pointer' : 'text-600'
      } ${className || ''}`}
      onClick={!disabled ? onClick : undefined}
      {...rest}
    >
      {children}
    </div>
  )
}

type MessageErrorProps = {
  workspace: Workspace
  error: DraftMessageError | null
} & HTMLAttributes<HTMLDivElement>

const MessageError: FC<MessageErrorProps> = observer(
  ({ workspace, error, className, ...rest }) => {
    const navigate = useNavigate()

    const currentAccount = store.accountsStore.currentAccount
    const isUserWorkspaceOwner = workspace.accountId === currentAccount?.id
    const userWorkspaceOwner = store.usersStore.userById(
      workspace.owner.ownerId,
    )

    if (!error) return null

    const renderContent = () => {
      switch (error) {
        case DraftMessageError.UnknownError:
          return (
            <Text color='red'>
              Something bad just happened, please try again
            </Text>
          )
        case DraftMessageError.SingleFileSize:
          return (
            <Text color='red'>
              Some of your attachments exceeds maximum file size limit of{' '}
              {formatFileSize(
                getConfigValue(
                  RemoteConfigFieldName.UPLOAD_MAX_FILE_SIZE_BYTES,
                ).asNumber(),
              )}
            </Text>
          )
        case DraftMessageError.TotalFileSize:
          const increaseStorageMessage = isUserWorkspaceOwner
            ? 'Please consider upgrading your subscription plan.'
            : `Kindly ask ${userWorkspaceOwner.displayName} to upgrade subscription plan.`

          const text = `There is no free space left available to upload all your attachments. ${increaseStorageMessage}`

          return (
            <div className='flex justify-between'>
              <div className='flex-1 mr-md'>
                <Text color='red'>{text}</Text>
              </div>
              {isUserWorkspaceOwner && (
                <Button
                  color='orange'
                  size='sm'
                  onClick={() => navigate('/upgrade')}
                >
                  Upgrade
                </Button>
              )}
            </div>
          )
      }
    }

    return (
      <div
        className={`px-sm py-xs rounded-xxs bg-red/20  text-red ${
          className || ''
        }`}
        {...rest}
      >
        {renderContent()}
      </div>
    )
  },
)
