import { FC, ReactNode, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { Product } from '@takle/store/paymentsStore'

import { ProductCard, ProductCardPrice, ProductCardProps } from './ProductCard'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { trackEvent } from '@takle/analytics'
import { store } from '@takle/store'

type StripeProductCardProps = Omit<ProductCardProps, 'button'> & {
  button: (onClick: () => void) => ReactNode
  product: Product
  interval: 'year' | 'month'
}

export const StripeProductCard: FC<StripeProductCardProps> = observer(
  ({ product, button, children, interval, ...rest }) => {
    const navigate = useStrictNavigate()

    const price =
      product.prices && product.prices.filter(p => p.interval === interval)[0]

    const onClick = useCallback(() => {
      trackEvent({
        kind: 'initiatedPurchase',
        payload: {
          accountId: store.accountsStore.currentAccount.id,
        },
      })

      navigate(AuthorizedRoutePath.Payment, {
        productId: product.id,
        priceId: price?.id || '',
      })
    }, [navigate, price?.id, product.id])

    return (
      <ProductCard {...rest} button={button(onClick)}>
        {!!price && (
          <ProductCardPrice
            currency={price.currency}
            amount={price.unit_amount / (interval === 'year' ? 12 : 1)}
          />
        )}
      </ProductCard>
    )
  },
)
