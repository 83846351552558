type DownloadFileProps = {
  url: string
  name: string
}
export function downloadStorageFile({ name, url }: DownloadFileProps) {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      link.remove()
    })
    .catch(console.error)
}
