import { useEffect, useState, FC } from 'react'
import { useParams } from 'react-router-dom'
import { Check } from 'tabler-icons-react'
import { observer } from 'mobx-react-lite'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import { Button } from '@takle/components/Button'
import { Logo } from '@takle/components/Logo'
import { Text } from '@takle/components/Text'
import { exhaustCheck } from '@takle/utils/exhaustCheck'
import { ActivityIndicator } from '@takle/components/ActivityIndicator'
import { Product } from '@takle/store/paymentsStore'
import { store } from '@takle/store'
import { ProductPriceModel } from '@takle/models/models'
import { handleError } from '@takle/sentry'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import getSymbolFromCurrency from 'currency-symbol-map'
import { CurrentAccount } from '@takle/store/accountsStore'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')

export enum PaymentFlow {
  Form,
  Success,
}

export const PaymentPage: FC = observer(() => {
  const { productId = '', priceId = '' } = useParams()
  const navigate = useStrictNavigate()
  const [flow, setFlow] = useState<PaymentFlow>(PaymentFlow.Form)
  const currentUser = store.usersStore.currentLoggedInUser
  const currentAccount = store.accountsStore.currentAccount

  const product = store.paymentsStore.products.find(p => p.id === productId)
  const price = product?.prices.find(p => p.id === priceId)

  useEffect(() => {
    if (flow !== PaymentFlow.Success) return

    currentUser.firebaseUser.getIdToken(true)
  }, [currentUser, flow])

  const renderContent = () => {
    if (!price || !product) return <ActivityIndicator />

    switch (flow) {
      case PaymentFlow.Form:
        return (
          <Elements stripe={stripePromise}>
            <StripeForm
              product={product}
              price={price}
              onSuccess={() => setFlow(PaymentFlow.Success)}
              currentAccount={currentAccount}
            />
          </Elements>
        )

      case PaymentFlow.Success:
        return (
          <>
            <div className='text-center '>
              <Check size={70} className='text-green mx-auto mb-xl' />
              <h1 className='mb-md'>
                <Text size='display-sm' bold>
                  Payment Succeeded
                </Text>
              </h1>
              <Text className='md:text-lg' color='200'>
                Thank you for purchasing {product.name} subscription. All
                benefits of your subscription plan are now available.
              </Text>
            </div>
            <Button
              bordered
              onClick={() => navigate(AuthorizedRoutePath.Dashboard, {})}
            >
              Go to Dashboard
            </Button>
          </>
        )

      default:
        return exhaustCheck(flow)
    }
  }

  return (
    <div className='flex flex-1 flex-col justify-center items-center'>
      <div className='flex flex-col gap-xxl items-center w-full px-md md:px-0 md:max-w-md my-lg'>
        <Logo />
        {renderContent()}
      </div>
    </div>
  )
})

type StripeFormProps = {
  product: Product
  price: ProductPriceModel
  currentAccount: CurrentAccount
  onSuccess: () => void
}

const StripeForm: FC<StripeFormProps> = ({
  product,
  price,
  onSuccess,
  currentAccount,
}) => {
  const [isFetching, setFetching] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async () => {
    setFetching(true)
    try {
      const card = elements?.getElement(CardElement)

      if (!card || !stripe) {
        return
      }

      const { error, token } = await stripe.createToken(card)

      if (error) throw error
      if (!token) throw new Error('No token')

      await store.paymentsStore.startSubscription({ price, tokenId: token.id })

      onSuccess()
    } catch (e) {
      handleError(e)
      store.uiStore.showWarningToast(
        'Error happened while processing the payment, please try again',
      )
    }
    setFetching(false)
  }

  return (
    <>
      <div className='text-center w-full md:w-96 md:max-w-none'>
        <h1 className='mb-md'>
          <Text size='display' bold>
            {product.name}
          </Text>
        </h1>
        <Text size='lg' color='200'>
          <b>
            {getSymbolFromCurrency(price.currency) || price.currency}
            {(
              price.unit_amount /
              100 /
              (price.interval === 'year' ? 12 : 1)
            ).toFixed(2)}
          </b>
          <Text size='base' color='400'>
            / per month, billed {price.interval}ly
          </Text>
        </Text>
      </div>
      <div className='w-full border text-base rounded-xs px-md py-sm bg-0 text-900 border-700 placeholder:text-400'>
        <CardElement />
      </div>
      {!!elements && (
        <Button
          isFetching={isFetching}
          className='w-full'
          disabled={isFetching}
          onClick={handleSubmit}
        >
          Proceed payment
        </Button>
      )}
    </>
  )
}
