import { makeObservable, observable, action, computed } from 'mobx'
import {
  getDocData,
  getUserDocRef,
  subscribeToDocUpdates,
  firebaseAuth,
  updatedUserData,
  subscribeUserPresence,
  updatedUserPresence,
  subscribeToCollectionUpdates,
  userChannelUnreadQuery,
  uploadAvatarImage,
  getUserAvatarPath,
  deleteUserCallable,
} from '@takle/firebase'
import retry from 'async-retry'
import {
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  signInWithCredential, //for native platforms
  User as FirebaseUser,
  OAuthProvider,
  updateProfile,
} from 'firebase/auth'
import * as Model from '../models/models'
import { Store } from '.'
import { v4 } from 'uuid'
import { Capacitor } from '@capacitor/core';
import {    
  FirebaseAuthentication,
} from '@capacitor-firebase/authentication';

const DELETED_USER_NAME = 'Deleted User'

const DELETED_USER_DATA: Model.UserModelToDelete = {
  displayName: DELETED_USER_NAME,
  fullName: DELETED_USER_NAME,
  email: '',
  photoURL: null,
  notificationsSettings: {
    notifyOnDirectChannelsUpdates: false,
    notifyChannelsUpdates: false,
    notifyOnMentions: false,
  },
}

type LoginLogoutHandler = (
  action: 'login' | 'logout',
  u: CurrentUser,
) => Promise<void> | void

export class UsersStore {
  store: Store
  currentUser: CurrentUser | null = null
  users: Map<string, User> = new Map()
  userChangeHandlers: Map<string, LoginLogoutHandler> = new Map()
  initialized: boolean = false

  registerUserChangeHandler(id: string, handler: LoginLogoutHandler) {
    this.userChangeHandlers.set(id, handler)
    return () => {this.userChangeHandlers.delete(id)}
  }

  constructor(store: Store) {
    this.store = store
    makeObservable(this, {
      currentUser: observable,
      initialized: observable,
      currentLoggedInUser: computed,
      users: observable,
      setCurrentUser: action,
      addOrUpdateCachedUser: action,
      setInitialized: action,
    })

    firebaseAuth.onAuthStateChanged(async firebaseUserOrNull => {
      this.setInitialized(false)

      firebaseUserOrNull
        ? await this.loginFirebaseUser(firebaseUserOrNull)
        : await this.logoutCurrentUser()

      this.setInitialized(true)
    })
  }

  get currentLoggedInUser(): CurrentUser {
    const user = this.currentUser
    if (!user)
      throw new Error(
        'This computed value must be used within authenticated state',
      )
    return user
  }

  userById(id: string): User {
    const user = this.users.get(id)
    if (!user) {
      this.fetchUserById(id)
      return DUMMY_USER()
    }
    return user
  }

  async sendLinkToEmail({ email, url }: { email: string; url: string }) {
    await sendSignInLinkToEmail(firebaseAuth, email, {
      handleCodeInApp: true,
      url: url.toString(),
    })
  }

  async signInWithLink({ email, url }: { email: string; url: string }) {
    await signInWithEmailLink(firebaseAuth, email, url)
  }

  isValidSignInLink(link: string) {
    return isSignInWithEmailLink(firebaseAuth, link)
  }

  async signInWithGoogle() {
    if (Capacitor.isNativePlatform()) {
      // 1. Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithGoogle();
      // 2. Sign in on the web layer using the id token
      const credential = GoogleAuthProvider.credential(result.credential?.idToken);
      await signInWithCredential(firebaseAuth, credential);
    } else {
      const provider = new GoogleAuthProvider()
      await signInWithPopup(firebaseAuth, provider)
    }
  }

  async signInWithApple() {
    if (Capacitor.isNativePlatform()) {
      // 1. Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithApple({
        skipNativeAuth: true,
        scopes: ["email", "name"]
      });
      const displayName = result.user?.displayName

      // 2. Sign in on the web layer using the id token
      const provider = new OAuthProvider('apple.com');
      provider.addScope('name')
      provider.addScope('email')
      const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce,
      });
      const signInWebResult = await signInWithCredential(firebaseAuth, credential);


      // Workaround for AppleId sign in issue, described here
      // https://github.com/firebase/firebase-ios-sdk/issues/4393
      // in short after signIn we need to manually set name for user
      if (displayName && displayName.length > 0) {
        const fbUser = signInWebResult.user
        await updateProfile(fbUser, { displayName: displayName })
      }

    } else {
      const provider = new OAuthProvider('apple.com');
      await signInWithPopup(firebaseAuth, provider)
    }
  }

  addOrUpdateCachedUser(user: User) {
    const existingUser = this.users.get(user.id)
    if (!existingUser) {
      this.users.set(user.id, user)
      return
    }
    existingUser.updateUserData({ ...user, photoURL: user.photoURL })
  }

  private async fetchUserById(id: string) {
    const userData = await getDocData({
      ref: getUserDocRef(id),
      tModel: Model.TUserModel,
    })
    this.addOrUpdateCachedUser(new User(userData))
  }

  private async loginFirebaseUser(firebaseUser: FirebaseUser) {
    // Need to wait for the doc properly created in the firestore
    await retry(
      async () => {
        const userDoc = await getDocData({
          ref: getUserDocRef(firebaseUser.uid),
          tModel: Model.TUserModel,
        })
        await this.loginCurrentUser(userDoc, firebaseUser)
      },
      {
        minTimeout: 1000,
        maxTimeout: 2000,
      },
    )
  }

  setCurrentUser(currentUser: typeof this.currentUser) {
    currentUser &&
      this.addOrUpdateCachedUser(
        new User({ ...currentUser, photoURL: currentUser.photoURL }),
      )
    this.currentUser = currentUser
  }

  setInitialized(v: boolean) {
    this.initialized = v
  }

  private async loginCurrentUser(
    userData: Model.UserModel,
    firebaseUser: FirebaseUser,
  ) {
    if (this.currentUser) {
      await this.logoutCurrentUser()
    }

    const user = new CurrentUser({
      data: userData,
      usersStore: this,
      firebaseUser,
    })

    this.setCurrentUser(user)

    const handlers = this.userChangeHandlers.values()

    for (const handler of handlers) {
      await handler('login', user)
    }
  }

  private async logoutCurrentUser() {
    if (!this.currentUser) return
    const handlers = this.userChangeHandlers.values()
    for (const handler of handlers) {
      await handler('logout', this.currentUser)
    }

    this.setCurrentUser(null)
  }
}

export class User {
  id: string
  displayName: string
  fullName: string
  email: string
  photoURL?: string
  status: Model.UserStatus
  stripeId?: string
  stripeLink?: string

  constructor(
    data: Pick<
      Model.UserModel,
      'id' | 'displayName' | 'fullName' | 'photoURL' | 'email' | 'status'
    >,
  ) {
    this.id = data.id
    this.displayName = data.displayName
    this.fullName = data.fullName
    if (data.photoURL) this.photoURL = data.photoURL
    this.email = data.email
    this.status = data.status

    makeObservable(this, {
      status: observable,
      displayName: observable,
      fullName: observable,
      photoURL: observable,
      updateUserData: action,
    })
  }

  updateUserData(
    data: Pick<
      Model.UserModel,
      'displayName' | 'fullName' | 'email' | 'photoURL' | 'status'
    >,
  ) {
    this.displayName = data.displayName
    this.fullName = data.fullName
    this.email = data.email
    if (data.photoURL) this.photoURL = data.photoURL
    this.status = data.status
  }
}

export class CurrentUser {
  id: string
  status: Model.UserStatus = 'online'
  displayName: string
  registeredAt: Date
  fullName: string
  email: string
  photoURL?: string
  notificationsSettings: Model.UserNotificationsSettingsModel
  signedUpBy?: string
  channelUnread: Map<string, Model.UserChannelUnread> = new Map()
  registrationSurveyCompleted?: boolean
  onboarding?: Model.UserOnboarding
  accountIds: Record<string, boolean>
  stripeId?: string
  stripeLink?: string

  usersStore: UsersStore
  firebaseUser: FirebaseUser

  private updateUnsubscriber: (() => void) | null = null
  private presenceUnsubscriber: (() => void) | null = null
  private channelUnreadUnsubscriber: (() => void) | null = null

  constructor({
    data,
    usersStore,
    firebaseUser,
  }: {
    data: Model.UserModel
    usersStore: UsersStore
    firebaseUser: FirebaseUser
  }) {
    this.id = data.id
    this.displayName = data.displayName
    this.fullName = data.fullName
    this.email = data.email
    if (data.photoURL) this.photoURL = data.photoURL
    this.notificationsSettings = data.notificationsSettings
    this.registeredAt = new Date(data.registeredAt)
    this.signedUpBy = data.signedUpBy
    this.accountIds = data.accountIds
    this.registrationSurveyCompleted = data.registrationSurveyCompleted
    this.onboarding = data.onboarding
    this.stripeLink = data.stripeLink
    this.stripeId = data.stripeId
    this.usersStore = usersStore
    this.firebaseUser = firebaseUser

    makeObservable(this, {
      displayName: observable,
      fullName: observable,
      photoURL: observable,
      accountIds: observable,
      registrationSurveyCompleted: observable,
      onboarding: observable,
      notificationsSettings: observable,
      channelUnread: observable,
      addOrUpdateChannelUnread: action,
      removeChannelUnread: action,
      updateUserData: action,
    })

    usersStore.registerUserChangeHandler(
      'currentUserDataUpdates',
      (action, user) => {
        action === 'login'
          ? user.subscribeToUpdates()
          : user.unsubscribeFromUpdates()
      },
    )
  }

  updateUserData(data: Model.UserModel) {
    this.displayName = data.displayName
    this.fullName = data.fullName
    this.email = data.email
    if (data.photoURL) this.photoURL = data.photoURL
    this.notificationsSettings = data.notificationsSettings
    this.registrationSurveyCompleted = data.registrationSurveyCompleted
    this.onboarding = data.onboarding
    this.usersStore.addOrUpdateCachedUser(new User(data))
  }

  async uploadUserPhoto(file: File) {
    const photoURL = await uploadAvatarImage({
      path: getUserAvatarPath(this.id),
      file,
    })

    return await this.setUserProfileData({ photoURL })
  }

  async setUserProfileData(data: Partial<Model.UserModelToSave>) {
    return await updatedUserData({ ...data, id: this.id })
  }

  addOrUpdateChannelUnread(changes: { model: Model.UserChannelUnread }[]) {
    for (const change of changes) {
      this.channelUnread.set(change.model.channelId, change.model)
    }
  }

  removeChannelUnread(removedIds: string[]) {
    for (const userId_ChannelId of removedIds) {
      const [, ...rest] = userId_ChannelId.split('_')
      const channelId = rest.join('_')
      this.channelUnread.delete(channelId)
    }
  }

  subscribeToUpdates() {
    this.updateUnsubscriber = subscribeToDocUpdates({
      ref: getUserDocRef(this.id),
      tModel: Model.TUserModel,
      onData: data => this.updateUserData(data),
    })
    this.presenceUnsubscriber = subscribeUserPresence(this.id)
    this.channelUnreadUnsubscriber = subscribeToCollectionUpdates({
      query: userChannelUnreadQuery(this.id),
      tModel: Model.TUserChannelUnread,
      onAdded: changes => this.addOrUpdateChannelUnread(changes),
      onUpdated: changes => this.addOrUpdateChannelUnread(changes),
      onRemovedIds: changes => this.removeChannelUnread(changes),
    })
  }

  unsubscribeFromUpdates() {
    this.updateUnsubscriber?.()
    this.presenceUnsubscriber?.()
    this.channelUnreadUnsubscriber?.()
  }

  async signOut() {
    await updatedUserPresence(this.id, 'offline')
    if (Capacitor.isNativePlatform()) {
      //Sing out in native layer...
      await FirebaseAuthentication.signOut();
    }
    //..[then] sign out in web layer
    firebaseAuth.signOut()
  }

  async deleteProfileAndSignOut() {
    await updatedUserData({ ...DELETED_USER_DATA, id: this.id })
    await deleteUserCallable(this.id)
    await this.signOut()
  }

  profileNameIsSet(): boolean {
    return (
      !!this.displayName &&
      this.displayName.length > 0 &&
      !!this.fullName &&
      this.fullName.length >= 0
    )
  }

  async syncProfileName() {
    if (!this.profileNameIsSet()) {
      //If name in profile is not set, then try to acquire one from auth profile
      const fbDisplayName = firebaseAuth.currentUser?.displayName?.trim()
      if (!!fbDisplayName && fbDisplayName.length > 0)
      {
        await updatedUserData({
          id: this.id,
          displayName: fbDisplayName,
          fullName: fbDisplayName,
        })
      }
    }
  }

}

const DUMMY_USER = () =>
  new User({
    id: v4(),
    displayName: '',
    fullName: '',
    email: '',
    status: 'offline',
    photoURL: null,
  })
