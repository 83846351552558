type E<K extends string> = {
  kind: K
}

type CompleteRegistration = E<'CompleteRegistration'>
type CustomizeProduct = E<'CustomizeProduct'>

type FbEvent = CompleteRegistration | CustomizeProduct

export function trackEventFb(e: FbEvent) {
  try {
    // @ts-ignore
    fbq('track', e.kind)
  } catch (error) {
    console.error(error)
  }
}
