import { FC, HTMLAttributes } from 'react'

type CaptionProps = { label: string } & HTMLAttributes<HTMLDivElement>

export const Caption: FC<CaptionProps> = ({ label, className, ...rest }) => {
  return (
    <div
      className={`uppercase text-200 font-semibold text-xs tracking-widest ${
        className || ''
      }`}
      {...rest}
    >
      {label}
    </div>
  )
}
