import quillEmoji from 'quill-emoji'
const { ShortNameEmoji } = quillEmoji

export const emojiConfig = {
  'formats/emoji': quillEmoji.EmojiBlot,
  'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
  'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
  'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
}

export const emojiList = ShortNameEmoji.DEFAULTS.emojiList
