import { HTMLAttributes } from 'react'
import { Text } from './Text'

type HorizontalSelectProps<T extends string> = {
  options: T[]
  selectedOption: T
  onSelectOption: (v: T) => void
} & HTMLAttributes<HTMLDivElement>

export function HorizontalSelect<T extends string>({
  options,
  selectedOption,
  onSelectOption,
  className,
  ...rest
}: HorizontalSelectProps<T>) {
  return (
    <div
      className={`rounded-xs bg-600 p-xxs flex gap-xs flex-row ${
        className || ''
      }`}
      {...rest}
    >
      {options.map(o => {
        const isSelected = o === selectedOption
        return (
          <div
            key={o}
            onClick={() => onSelectOption(o)}
            className={`rounded-xs min-w-[115px] px-md flex flex-col justify-center items-center h-xxl border transition-all ${
              isSelected
                ? 'border-blue pointer-events-none'
                : 'cursor-pointer border-transparent hover:border-blue/30 hover:bg-200/10'
            }`}
          >
            <Text bold={isSelected} color='0'>
              {o}
            </Text>
          </div>
        )
      })}
    </div>
  )
}
