import { collection, doc, query, where, deleteDoc } from 'firebase/firestore'
import { converter } from './converter'
import { Collections } from './constants'
import { fireStore } from './firebase'

/*

User-channel unreads

*/

export function getUserChannelUnreadCollectionRef() {
  return collection(fireStore, Collections.UserChannelUnread).withConverter(
    converter,
  )
}

export function getUserChannelUnreadDocument(
  userId: string,
  channelId: String,
) {
  return doc(fireStore, Collections.UserChannelUnread, `${userId}_${channelId}`)
}

export const userChannelUnreadQuery = (userId: string) =>
  query(getUserChannelUnreadCollectionRef(), where(`userId`, '==', userId))

type RemoveUnreadDocumentParams = {
  userId: string
  channelId: string
}

export async function removeUnreadDocument({
  userId,
  channelId,
}: RemoveUnreadDocumentParams) {
  await deleteDoc(getUserChannelUnreadDocument(userId, channelId))
}
