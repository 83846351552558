import { SaveInvitationId } from '@takle/components/SaveInvitationId'
import { CompleteRegistrationPage } from '@takle/pages/CompleteRegistrationPage/CompleteRegistrationPage'
import { LandingPage } from '@takle/pages/LandingPage/LandingPage'
import { LoginFlow, LoginPage } from '@takle/pages/LoginPage/LoginPage'
import { PricingPage } from '@takle/pages/PricingPage/PricingPage'
import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router'
import { AuthorizedRoutePath } from './AuthorizedRoutes'

export enum UnauthorizedRoutePath {
  Landing = '/home',
  Login = '/login',
  SignUp = '/signup',
  CompleteRegistration = '/finish-sign-in',
  Pricing = '/pricing',
}

type UnauthorizedRoutesProps = { defaultPath? :UnauthorizedRoutePath }

export const UnauthorizedRoutes: FC<UnauthorizedRoutesProps> = ( { defaultPath } ) => {
  const _defaultPath = defaultPath || UnauthorizedRoutePath.Landing
  return (
    <Routes>
      <Route path={UnauthorizedRoutePath.Landing} element={<LandingPage />} />
      <Route
        path={UnauthorizedRoutePath.Login}
        element={<LoginPage initialFlow={LoginFlow.Login} />}
      />
      <Route
        path={UnauthorizedRoutePath.SignUp}
        element={<LoginPage initialFlow={LoginFlow.CreateAccount} />}
      />
      <Route
        path={UnauthorizedRoutePath.CompleteRegistration}
        element={<CompleteRegistrationPage />}
      />
      <Route path={UnauthorizedRoutePath.Pricing} element={<PricingPage />} />
      <Route path={AuthorizedRoutePath.Join} element={<SaveInvitationId />} />
      <Route
        path='*'
        element={<Navigate to={_defaultPath} />}
      />
    </Routes>
  )
}
