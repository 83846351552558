import dompurify from 'dompurify'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { emojiList } from './config'

export function convertDeltaStrToHtmlStr(deltaString: string) {
  try {
    const { ops } = JSON.parse(deltaString)

    var converter = new QuillDeltaToHtmlConverter(ops, {
      multiLineParagraph: false,
    })
    converter.renderCustomWith(customOp => {
      if (customOp.insert.type === 'mention') {
        const mention = customOp.insert.value
        //TODO styles
        return `
            <span
              class="mention"
              data-index="${mention.index}"
              data-denotation-char="${mention.denotationChar}"
              data-key="${mention.key}"
              data-link="${mention.link}"
              data-value='${mention.value}'
            >
              <span contenteditable="false">
                @${mention.value}
              </span>
            </span>
            `
      }
      if (customOp.insert.type === 'emoji') {
        const emoji = customOp.insert.value
        const emojiObj = emojiList.find(
          (emj: { name: string }) => emj.name === emoji,
        )
        const code = String.fromCodePoint(parseInt(emojiObj.unicode, 16))
        return `<span>${code}</span>`
      }
      return ''
    })

    return dompurify.sanitize(converter.convert(), {
      ADD_ATTR: ['target'],
    })
  } catch (e) {
    throw new Error('Parse delta string error')
  }
}
