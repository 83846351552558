import { makeObservable, observable, action } from 'mobx'
import {
  getDocData,
  getAccountDocRef,
  subscribeToDocUpdates,
  getCollectionData,
  getAccountsCollectionRef,
} from '@takle/firebase'
import { v4 } from 'uuid'
import * as Model from '../models/models'
import { Store } from '.'
import { query, where } from 'firebase/firestore'

type LoginLogoutHandler = (
  action: 'logout' | 'changeCurrentAccount',
  u: CurrentAccount,
) => Promise<void> | void

export class AccountsStore {
  store: Store
  currentAccountOrNull: CurrentAccount | null = null
  accounts: Map<string, Account> = new Map()
  accountChangeHandlers: Map<string, LoginLogoutHandler> = new Map()
  initialized: boolean = false

  registerAccountChangeHandler(id: string, handler: LoginLogoutHandler) {
    this.accountChangeHandlers.set(id, handler)
    return () => this.accountChangeHandlers.delete(id)
  }

  constructor(store: Store) {
    this.store = store
    makeObservable(this, {
      currentAccountOrNull: observable,
      initialized: observable,
      accounts: observable,
      setCurrentAccount: action,
      addOrUpdateCachedAccount: action,
      setInitialized: action,
    })

    store.usersStore.registerUserChangeHandler(
      'setAccounts',
      async (action, user) => {
        if (action === 'login') {
          const accountsData = await this.getAccountsByUserId(user.id)

          const currentAccount = accountsData[0]

          if (!currentAccount) {
            throw new Error('User has no accounts')
          }

          this.changeCurrentAccount(currentAccount)

          this.setAccounts(accountsData)
        }

        if (action === 'logout') {
          this.logoutCurrentAccount()
        }
      },
    )
  }

  get currentAccount(): CurrentAccount {
    const account = this.currentAccountOrNull

    if (!account)
      throw new Error(
        'This computed value must be used within authenticated state',
      )
    return account
  }

  private async setAccounts(accountsData: Model.AccountModel[]) {
    this.accounts = accountsData.reduce(
      (acc: Map<string, Account>, account) => {
        acc.set(account.id, new Account(account))
        return acc
      },
      new Map(),
    )
  }

  private async logoutCurrentAccount() {
    if (!this.currentAccountOrNull) return
    const handlers = this.accountChangeHandlers.values()

    for (const handler of handlers) {
      handler('logout', this.currentAccountOrNull)
    }

    this.resetCurrentAccount()
  }

  async changeCurrentAccount(accountData: Model.AccountModel) {
    if (this.currentAccountOrNull) {
      this.resetCurrentAccount()
    }

    const account = new CurrentAccount({
      data: accountData,
      accountsStore: this,
    })

    this.setCurrentAccount(account)

    const handlers = this.accountChangeHandlers.values()

    for (const handler of handlers) {
      await handler('changeCurrentAccount', account)
    }
  }

  getAccountsByUserId(userId: string) {
    return getCollectionData({
      query: query(getAccountsCollectionRef(), where('ownerId', '==', userId)),
      tModel: Model.TAccountModel,
    })
  }

  accountById(id: string): Account {
    const account = this.accounts.get(id)
    if (!account) {
      this.fetchAccountById(id)
      return DUMMY_ACCOUNT()
    }
    return account
  }

  addOrUpdateCachedAccount(account: Account) {
    const existingAccount = this.accounts.get(account.id)
    if (!existingAccount) {
      this.accounts.set(account.id, account)
      return
    }
    existingAccount.updateAccountData({ ...account })
  }

  private async fetchAccountById(id: string) {
    const accountData = await getDocData({
      ref: getAccountDocRef(id),
      tModel: Model.TAccountModel,
    })
    this.addOrUpdateCachedAccount(new Account(accountData))
  }

  private async resetCurrentAccount() {
    if (!this.currentAccountOrNull) return

    this.setCurrentAccount(null)
  }

  setCurrentAccount(currentAccount: typeof this.currentAccountOrNull) {
    currentAccount &&
      this.addOrUpdateCachedAccount(new Account({ ...currentAccount }))
    this.currentAccountOrNull = currentAccount
  }

  setInitialized(v: boolean) {
    this.initialized = v
  }
}

export class Account {
  id: string
  subscriptionPlan: Model.AccountSubscriptionPlanModel
  ownerId: string

  constructor(
    data: Pick<Model.AccountModel, 'id' | 'subscriptionPlan' | 'ownerId'>,
  ) {
    this.id = data.id
    this.subscriptionPlan = data.subscriptionPlan
    this.ownerId = data.ownerId

    makeObservable(this, {
      updateAccountData: action,
    })
  }

  updateAccountData(data: Pick<Model.AccountModel, 'subscriptionPlan'>) {
    this.subscriptionPlan = data.subscriptionPlan
  }
}

export class CurrentAccount {
  id: string
  subscriptionPlan: Model.AccountSubscriptionPlanModel
  workspaceIds: Record<string, boolean>
  accountsStore: AccountsStore
  ownerId: string

  private updateUnsubscriber: (() => void) | null = null

  constructor({
    data,
    accountsStore,
  }: {
    data: Model.AccountModel
    accountsStore: AccountsStore
  }) {
    this.id = data.id
    this.workspaceIds = data.workspaceIds
    this.subscriptionPlan = data.subscriptionPlan

    this.ownerId = data.ownerId

    this.accountsStore = accountsStore

    makeObservable(this, {
      subscriptionPlan: observable,
      workspaceIds: observable,
      updateAccountData: action,
    })

    accountsStore.registerAccountChangeHandler(
      'currentAccountDataUpdates',
      (action, account) => {
        if (action === 'changeCurrentAccount') {
          account.subscribeToUpdates()
          return
        }

        if (action === 'logout') {
          account.unsubscribeFromUpdates()
          return
        }
      },
    )
  }

  updateAccountData(data: Model.AccountModel) {
    this.workspaceIds = data.workspaceIds
    this.subscriptionPlan = data.subscriptionPlan
    this.accountsStore.addOrUpdateCachedAccount(new Account(data))
  }

  subscribeToUpdates() {
    this.updateUnsubscriber = subscribeToDocUpdates({
      ref: getAccountDocRef(this.id),
      tModel: Model.TAccountModel,
      onData: data => this.updateAccountData(data),
    })
  }

  unsubscribeFromUpdates() {
    this.updateUnsubscriber?.()
  }
}

const DUMMY_ACCOUNT = () =>
  new Account({
    id: v4(),
    subscriptionPlan: null,
    ownerId: v4(),
  })
