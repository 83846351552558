import {
  collection,
  doc,
  query,
  runTransaction,
  updateDoc,
  deleteDoc,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import * as Model from '@takle/models/models'
import { converter } from '@takle/firebase/converter'
import { Collections, timestamp } from './constants'
import { firebaseFunctions, fireStore } from './firebase'
import { getWorkspaceDocRef } from './workspaces'
import { PreparedData } from '@takle/pages/DashboardPage/components/Questionnaire'

/*

Channels

*/

export function getChannelDocRef(channelId: string) {
  return doc(fireStore, Collections.Channels, channelId).withConverter(
    converter,
  )
}

export function getChannelsCollectionRef() {
  return collection(fireStore, Collections.Channels).withConverter(converter)
}

export function getDirectChannelsCollectionRef() {
  return collection(fireStore, Collections.DirectChannels).withConverter(
    converter,
  )
}

enum ChannelCallableFunctions {
  RemoveUserFromChannel = 'v2removeUserFromChannel',
  AddUserToChannel = 'v2addUserToChannel',
  RecordQuestionnaire = 'v2recordQuestionnaire',
}

export const recordQuestionnaireCallable = (params: PreparedData) =>
  httpsCallable<PreparedData, Model.ChannelModel>(
    firebaseFunctions,
    ChannelCallableFunctions.RecordQuestionnaire,
  )(params)

type RemoveUserFromChannelParams = {
  uidToRemove: string
  channelId: string
}

export const removeUserFromChannelCallable = (
  params: RemoveUserFromChannelParams,
) =>
  httpsCallable<RemoveUserFromChannelParams, Model.ChannelModel>(
    firebaseFunctions,
    ChannelCallableFunctions.RemoveUserFromChannel,
  )(params)

type AddUserToChannelParams = {
  uidToAdd: string
  channelId: string
}

export const addUserToChannelCallable = (params: AddUserToChannelParams) =>
  httpsCallable<AddUserToChannelParams, Model.ChannelModel>(
    firebaseFunctions,
    ChannelCallableFunctions.AddUserToChannel,
  )(params)

export const userChannelsQuery = (uid: string) =>
  query(getChannelsCollectionRef(), where(`userIds.${uid}`, '==', true))

export const userDirectChannelsQuery = (uid: string) =>
  query(
    getDirectChannelsCollectionRef(),
    where('userIds', 'array-contains', uid),
  )

type CreateChannelDocumentParams = {
  name: string
  workspaceId: string
  uid: string
  accountId: string
}

export async function createChannelDocument({
  name,
  workspaceId,
  uid,
  accountId,
}: CreateChannelDocumentParams) {
  const workspaceRef = getWorkspaceDocRef(workspaceId)
  const channelRef = doc(collection(fireStore, Collections.Channels))

  await runTransaction(fireStore, async transaction => {
    const workspaceData = await Model.decode(
      (await transaction.get(workspaceRef)).data(),
      Model.TWorkspaceModel,
    )

    const userCanCreateChannel =
      workspaceData.userIds[uid] &&
      (accountId === workspaceData.accountId ||
        workspaceData.settings.usersCanCreateChannels)

    if (!userCanCreateChannel) {
      throw new Error('You are not allowed to create channels')
    }

    const channelData: Omit<Model.ChannelModel, 'id'> = {
      name,
      createdAt: timestamp(),
      workspaceId: workspaceRef.id,
      ownerId: uid,
      isDefault: false,
      userIds: {
        [uid]: true,
      },
    }

    transaction.set(channelRef, channelData)
  })

  return channelRef.id
}

type CreateDirectChannelDocumentParams = {
  workspaceId: string
  withUserId: string
  userId: string
}

export async function createDirectChannelDocument({
  workspaceId,
  withUserId,
  userId,
}: CreateDirectChannelDocumentParams) {
  const userIds = prepareDirectChannelUserIds([withUserId, userId])
  const workspaceRef = getWorkspaceDocRef(workspaceId)
  const channelRef = doc(
    collection(fireStore, Collections.DirectChannels),
    directChannelIdFromUserIds(userIds),
  )

  await runTransaction(fireStore, async transaction => {
    const channelDoc = await transaction.get(channelRef)

    if (channelDoc.exists()) return

    const channelData: Omit<Model.DirectChannelModel, 'id'> = {
      createdAt: timestamp(),
      workspaceId: workspaceRef.id,
      userIds,
    }

    transaction.set(channelRef, channelData)
  })

  return channelRef.id
}

type UpdateChannelDocumentParams = {
  channelId: string
} & Partial<Model.ChannelModelToSave>

export async function updateChannelDocument({
  channelId,
  name,
}: UpdateChannelDocumentParams) {
  const channelRef = getChannelDocRef(channelId)

  const channelData: Partial<Model.ChannelModel> = {
    name,
  }

  await updateDoc(channelRef, channelData)
}

type DeleteChannelDocumentParams = {
  channelId: string
}

export async function deleteChannelDocument({
  channelId,
}: DeleteChannelDocumentParams) {
  const channelRef = getChannelDocRef(channelId)

  await deleteDoc(channelRef)
}

export function prepareDirectChannelUserIds(uids: [string, string]) {
  return uids.sort()
}

export function directChannelIdFromUserIds(uids: [string, string]) {
  return prepareDirectChannelUserIds(uids).join('_')
}
