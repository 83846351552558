import { FC } from 'react'
import { Check } from 'tabler-icons-react'

type StepperProps = {
  currentStep: number
  steps: string[]
  getStepClass: (i: number, step: string) => string
}

export const Stepper: FC<StepperProps> = ({
  currentStep,
  steps,
  getStepClass,
}) => {
  return (
    <div className='flex items-center gap-5'>
      {steps.map((step, i) => (
        <div key={step} className='flex items-center gap-2'>
          <div
            className={`w-6 h-6 rounded-full border-2 border-solid flex items-center justify-center text-xs ${getStepClass(
              i,
              'step',
            )}`}
          >
            {i < currentStep ? <Check /> : i + 1}
          </div>
          <p
            className={`text-xs uppercase font-semibold tracking-widest ${getStepClass(
              i,
              'text',
            )}`}
          >
            {step}
          </p>
        </div>
      ))}
    </div>
  )
}
