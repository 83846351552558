import { FC } from 'react'
import { ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import './styles.css'

type EmailInputProps = {
  emails: string[]
  onSetEmails: (emails: string[]) => void
}

export const EmailInput: FC<EmailInputProps> = ({ emails, onSetEmails }) => {
  return (
    <form>
      <ReactMultiEmail
        placeholder='Enter emails'
        emails={emails}
        onChange={(_emails: string[]) => {
          onSetEmails(_emails)
        }}
        autoFocus
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          )
        }}
      />
    </form>
  )
}
