import { Component, FC } from 'react'

export class ErrorBoundary extends Component<{}, { error: null | Error }> {
  constructor(props: {}) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  render() {
    const { error } = this.state

    if (error) {
      return (
        <div className='text-200 p-12'>
          <ErrorTitle />
          <h1 className='text-lg text-400 mt-2'>Something went really wrong</h1>
          <p className='text-200 text-sm max-w-3xl mt-2'>{error.message}</p>
        </div>
      )
    }

    return this.props.children
  }
}

const ErrorTitle: FC = () => {
  const r = getRandomInt(1, 5)
  const className = 'text-[30px] text-0 font-bold mb-4'
  switch (r) {
    case 1:
      return <div className={className}>🤬 What the heck did you just do?</div>
    case 2:
      return <div className={className}>😳 Oooops...</div>
    case 3:
      return <div className={className}>💩 Holy crap</div>
    case 4:
      return (
        <div className={className}>
          🖕 Hell no, I'm ain't gonna work this way
        </div>
      )
    case 5:
    default:
      return <div className={className}>🚀, but not your project</div>
  }
}

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
