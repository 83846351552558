import { forwardRef } from 'react'
import ReactSelect, { GroupBase, Props } from 'react-select'

export const Select = forwardRef(
  <
    OptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
  >(
    props: { error?: string | boolean } & Props<OptionType, IsMulti, GroupType>,
  ) => {
    return (
      <>
        <ReactSelect
          maxMenuHeight={400}
          menuPlacement='auto'
          {...props}
          classNames={{
            placeholder: () => '!text-400 !text-base',
            input: () => '!text-0 !text-base',
            menuList: () => '!bg-800',
            option: params => {
              const { isFocused, isSelected } = params
              const baseClassNames = '!text-0 !text-base'
              let extraClassNames = ''
              if (isFocused) {
                extraClassNames = '!bg-700/30'
              }

              if (isSelected) {
                extraClassNames = '!bg-blue/40 !font-bold'
              }

              return `${baseClassNames} ${extraClassNames}`
            },
            singleValue: params => {
              const { isDisabled } = params

              const baseClassNames = '!text-0 !text-base'
              let extraClassNames = ''

              if (isDisabled) {
                extraClassNames = '!text-200 !text-base'
              }

              return `${baseClassNames} ${extraClassNames}`
            },
            control: params => {
              const { isFocused, isDisabled } = params
              const baseClassNames =
                '!bg-800 !border-400 !rounded-xs !shadow-none ring ring-0 ring-transparent !duration-300'
              let extraClassNames = ''

              if (isFocused) {
                extraClassNames = '!ring-3 !ring-blue/30 !border-blue'
              }

              if (props.error) {
                extraClassNames = `!text-red !border-red ${
                  isFocused ? '!ring-red/30' : ''
                }`
              }

              if (isDisabled) {
                extraClassNames = '!text-200 !border-600'
              }

              return `${baseClassNames} ${extraClassNames}`
            },

            indicatorSeparator: params => {
              const { isDisabled } = params

              if (isDisabled) {
                return '!bg-600'
              }

              return '!bg-400'
            },
            dropdownIndicator: params => {
              const { isDisabled } = params

              if (isDisabled) {
                return '!text-400'
              }

              return '!text-200'
            },
            valueContainer: () =>
              `!px-[calc(theme('spacing.md')-1px)] !py-[calc(theme('spacing.sm')-1px)]`,
            ...props.classNames,
          }}
        />
        {props.error && (
          <span className='!text-red !text-sm'>{props.error}</span>
        )}
      </>
    )
  },
)
