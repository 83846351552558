import { ChangeEvent, FC, HTMLAttributes, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { Trash, X } from 'tabler-icons-react'

import { Channel } from '@takle/store/channelsStore'
import { formatChannelName } from '@takle/utils/format'

import { Modal } from './Modal'
import { Text } from './Text'
import { Button, IconButton, LinkButton } from './Button'
import { Input } from './Input'
import { Caption } from './Caption'
import { Avatar } from './Avatar'
import { useHotkeys } from 'react-hotkeys-hook'
import { store } from '@takle/store'
import { handleError } from '@takle/sentry'
import { DeleteChannelButton } from './DeleteChannelModal'
import { useParams } from 'react-router'

export type ChannelEditModalProps = {
  channel: Channel
  onClose: () => void
  onOpenChannel?: (channelId: string) => void
}

export const ChannelEditModal: FC<ChannelEditModalProps> = observer(
  ({ onClose, channel, onOpenChannel }) => {
    const [name, setName] = useState(channel.name)
    const [loading, setLoading] = useState(false)
    const { workspaceId } = useParams()
    const [error, setError] = useState('')

    const isSaveDisabled =
      !name.trim() || name.startsWith('-') || name.endsWith('-')

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(formatChannelName(e.target.value))     
      setError('')
    }

    const onEdit = useCallback(async () => {
      if (loading || isSaveDisabled) return
      
      const channelAlreadyExists = store.channelsStore.channels.some((c)=> c.workspaceId === workspaceId && c.name === name && c.id !== channel.id )
      
      if (channelAlreadyExists) {
        setError('Channel with this name already exists')
        return;
      }

      setLoading(true)
      try {
        await channel.setChannelName(name)
        onClose()
      } catch (e) {
        handleError(e)
        store.uiStore.showWarningToast(
          'Something wrong happened updating channel settings, please try again',
        )
        setLoading(false)
      }
    }, [loading, isSaveDisabled, workspaceId, name, channel, onClose])
    
    useHotkeys('esc', onClose, { enableOnFormTags: true })
    useHotkeys('return', () => onEdit(), { enableOnFormTags: true }, [onEdit])

    return (
      <Modal onClose={onClose} className='lg:!w-[450px] bg-800 py-lg'>
        <div className='flex self-stretch justify-between mb-lg'>
          <Avatar name={channel.name} size='lg' className='mt-md' />
          <IconButton onClick={onClose}>
            <X />
          </IconButton>
        </div>

        <div className='flex flex-col flex-1 gap-lg'>
          <div className='flex flex-col gap-sm'>
            <Text bold size='display-sm'>
              #{channel.name}
            </Text>
            <Text bold size='lg' color='200'>
              Channel settings
            </Text>
          </div>
          <div className='self-stretch'>
            <Caption label='channel name' className='mb-xs' />
            <Input
              value={name}
              placeholder='my-channel'
              maxLength={40}
              className='w-full'
              showError={!name || error}
              onChange={onNameChange}
            />
          </div>

          {!channel.isDefault ? (
            <DeleteChannelButton
              channelId={channel.id}
              onComplete={() => {
                const channel = store.channelsStore.channels.at(0)
                if (channel) {
                  onOpenChannel?.(channel.id)
                }
              }}
              renderButton={onOpen => (
                <LinkButton className=' text-red' size='sm' onClick={onOpen}>
                  <Trash size={16} className='mr-xxs inline-block' />
                  Delete channel
                </LinkButton>
              )}
            />
          ) : (
            <Text color='400' size='xs'>
              This is the default channel and cannot be deleted.
            </Text>
          )}

          <div className='flex self-stretch justify-end border-t border-600 -mx-xl px-xl pt-md -mb-sm'>
            <Button bordered onClick={onClose} className='mr-sm'>
              Close
            </Button>
            <Button
              onClick={onEdit}
              isFetching={loading}
              disabled={isSaveDisabled}
            >
              Save changes
            </Button>
          </div>
        </div>
      </Modal>
    )
  },
)

type ChannelEditModalButtonProps = {
  onCloseParent?: () => void
  renderButton: (v: () => void) => ReactNode
  isInitiallyOpened?: boolean
  channel: Channel
  onOpenChannel?: (channelId: string) => void
} & HTMLAttributes<HTMLDivElement>

export const ChannelEditModalButton: FC<ChannelEditModalButtonProps> = observer(
  ({
    channel,
    onCloseParent,
    renderButton,
    isInitiallyOpened = false,
    onOpenChannel,
    ...rest
  }) => {
    const [isOpen, setOpen] = useState(isInitiallyOpened)

    return (
      <div {...rest}>
        {renderButton(() => setOpen(true))}
        {isOpen && (
          <ChannelEditModal
            channel={channel}
            onOpenChannel={onOpenChannel}
            onClose={() => {
              onCloseParent?.()
              setOpen(false)
            }}
          />
        )}
      </div>
    )
  },
)
