import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import React, { useCallback } from 'react'
import { updateUserTokenDocumentWithToken } from './firebase/userTokens'

const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY

type PushNotification = { title?: string; body?: string }
type PushNotificationHanlder = (payload: PushNotification) => void

const requestPermissions = async () => {
    const result = await FirebaseMessaging.requestPermissions();
    const permissions = result.receive
    switch (permissions) {
      case 'prompt':
        console.error('Wrong push permission, we already requested permissions, so it cant be a "prompt" state')
        break
      case 'granted':
        console.log('Push permissions are GRANTED')
        break
      case 'denied':
        console.log('Push permissions are DENIED')
        break
      case 'prompt-with-rationale':
        console.log('Push permissions are DENIED')
        break
      default:
        break
    }
}

const registerForNotifications = (onNotification: PushNotificationHanlder) => {
  FirebaseMessaging.addListener('notificationReceived', event => {
    console.debug('notificationReceived', event);
    const title = event.notification.title
    const body = event.notification.body
    if (!!title && !!body) return

    onNotification({ title, body })
  });
  return () => {FirebaseMessaging.removeAllListeners()}
}

export const usePushNotifications = (
  onNotification: PushNotificationHanlder,
) => {
  const [notificationToken, setNotificationToken] = React.useState<
    string | null
  >(null)

  React.useEffect(() => {
    if (!notificationToken) {
      return
    }
    return registerForNotifications(onNotification)
  }, [notificationToken, onNotification])

  return useCallback(async (userId: string | null) => {
    setNotificationToken(null)

    if (!userId) return
    try {
      requestPermissions()
      const tokenResult = await FirebaseMessaging.getToken({ vapidKey })
      const currentToken = tokenResult.token
      if (!currentToken) {
        console.log(
          'No registration token available. Request permission to generate one.',
        )
        return
      }

      console.log('current token for client: ', currentToken)
      await updateUserTokenDocumentWithToken(userId, currentToken)
      setNotificationToken(currentToken)
    } catch (e) {
      console.error('An error occurred while retrieving token. ', e)
    }
  }, [])
}
