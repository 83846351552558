import { FC, useState, ChangeEvent, useCallback, useReducer } from 'react'
import { Copy } from 'tabler-icons-react'
import { observer } from 'mobx-react-lite'
import { exhaustCheck } from '@takle/utils/exhaustCheck'
import { useStrictNavigate } from '@takle/navigation/paths'

import { trackEvent } from '@takle/analytics'
import { store } from '@takle/store'
import { handleError } from '@takle/sentry'
import { SearchParams } from '@takle/constants'

import { Logo } from '@takle/components/Logo'
import { Text } from '@takle/components/Text'
import { Stepper } from './components/Stepper'
import { Button, LinkButton } from '@takle/components/Button'
import { Caption } from '@takle/components/Caption'
import { Input } from '@takle/components/Input'
import { Avatar } from '@takle/components/Avatar'
import { FilePicker } from '@takle/components/FilePicker'
import { EmailInput } from './components/EmailInput/EmailInput'

import { Workspace } from '@takle/store/workspacesStore'

import { buildUrl } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { setLocalStorageItem } from '@takle/localStorage'

export enum CreateWorkspaceFlow {
  CreateWorkspace,
  AddMembers,
}

const steps = ['Create Workspace', 'Add Members']

enum FormActionType {
  SetName,
  SetDescription,
  SetLogo,
  SetEmails,
}

type FormAction = {
  type: FormActionType
  payload: string | string[] | (File & { src: string })
}

type FormState = {
  workspaceName: string
  workspaceDescription: string
  workspaceLogo: (File & { src: string }) | null
  workspaceInviteEmails: string[]
}

const formReducer = (state: FormState, action: FormAction): FormState => {
  const { type, payload } = action
  switch (type) {
    case FormActionType.SetName:
      return {
        ...state,
        workspaceName: payload as string,
      }
    case FormActionType.SetDescription:
      return {
        ...state,
        workspaceDescription: payload as string,
      }
    case FormActionType.SetLogo:
      return {
        ...state,
        workspaceLogo: payload as File & { src: string },
      }
    case FormActionType.SetEmails:
      return {
        ...state,
        workspaceInviteEmails: payload as string[],
      }
    default:
      return state
  }
}

export const CreateWorkspacePage: FC = observer(() => {
  const navigate = useStrictNavigate()

  const initialState: FormState = {
    workspaceName: '',
    workspaceDescription: '',
    workspaceLogo: null,
    workspaceInviteEmails: [],
  }

  const [state, dispatch] = useReducer(formReducer, initialState)
  const {
    workspaceName,
    workspaceDescription,
    workspaceInviteEmails,
    workspaceLogo,
  } = state
  const [flow, setFlow] = useState<CreateWorkspaceFlow>(
    CreateWorkspaceFlow.CreateWorkspace,
  )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [inviteLink, setInviteLink] = useState('')
  const [workspace, setWorkspace] = useState<Workspace | null>(null)

  const currentAccount = store.accountsStore.currentAccount
  const workspaces = store.workspacesStore.workspaces
  // const inviteEmail = workspace?.inviteEmail

  const handleImageChange = (files: FileList) => {
    const file = files[0]
    if (!file) return
    dispatch({
      type: FormActionType.SetLogo,
      payload: { ...file, src: URL.createObjectURL(file) },
    })
  }

  const onCreate = useCallback(async () => {
    if (loading || !workspaceName) return

    const workspaceAlreadyExists = workspaces.some(
      workspace => workspace.name === workspaceName,
    )
    if (workspaceAlreadyExists) {
      setError('Workspace with this name already exists')
      return
    }

    setLoading(true)
    try {
      const workspaceId = await store.workspacesStore.createWorkspace(
        workspaceName,
      )

      if (!workspaceId) {
        throw new Error('Workspace id is not defined')
      }

      trackEvent({
        kind: 'workspaceCreated',
        payload: { workspaceId, accountId: currentAccount.id },
      })

      store.uiStore.showInfoToast(`Workspace ${workspaceName} created`)

      const workspace = new Workspace(
        await store.workspacesStore.getWorkspaceById(workspaceId),
        store.workspacesStore,
      )
      setWorkspace(workspace)

      if (workspaceLogo) {
        await workspace.uploadWorkspaceImage(workspaceLogo)
      }

      if (workspaceDescription) {
        await workspace.setWorkspaceDescription(workspaceDescription)
      }
      setInviteLink(createInviteUrl(workspace.inviteHash))
      setFlow(CreateWorkspaceFlow.AddMembers)
    } catch (e) {
      handleError(e)
      store.uiStore.showWarningToast(
        'Something wrong happened while creating a new workspace, please try again',
      )
    }
    setLoading(false)
  }, [
    loading,
    workspaceName,
    workspaces,
    currentAccount.id,
    workspaceLogo,
    workspaceDescription,
  ])

  const onLinkClick = (workspaceId: string) => {
    navigator.clipboard.writeText(inviteLink)
    trackEvent({
      kind: 'inviteLinkCopied',
      payload: { workspaceId, accountId: currentAccount.id },
    })
    store.uiStore.showInfoToast('Link copied to your clipboard')
  }

  const onCancelCreation = () => {
    setLocalStorageItem('noCreationRedirect', 'true')
    navigate(AuthorizedRoutePath.Dashboard, {})
  }

  const onSkipInvites = (workspaceId: string) => {
    navigate(AuthorizedRoutePath.Workspace, { workspaceId })
  }

  const onSendInvites = async (workspace: Workspace) => {
    setLoading(true)
    const workspaceName = workspace.name
    const docId = await store.workspacesStore.sendWorkspaceInvites(
      workspaceInviteEmails,
      workspaceName,
      inviteLink,
    )

    workspace.inviteEmailId = String(docId.data)
    setLoading(false)
    navigate(AuthorizedRoutePath.Workspace, { workspaceId: workspace.id })
  }

  // Уведомление о результате отправки писем. Временно отключено
  // useEffect(() => {
  //   if (!workspace || !workspace.inviteEmailId) return
  //   workspace.subscribeToWorkspaceInviteEmailUpdates()
  //   return () => workspace.unsubscribeFromWorkspaceInviteEmailUpdates()
  // }, [workspace, workspace?.inviteEmailId])

  // useEffect(() => {
  //   if (!inviteEmail) return
  //   if (inviteEmail.delivery.state === 'ERROR') {
  //     store.uiStore.showInfoToast(
  //       'An error occurred while delivering invite emails',
  //     )
  //   } else if (inviteEmail.delivery.state === 'SUCCESS') {
  //     store.uiStore.showInfoToast('Invite emails have been sent successfully')
  //   }
  // }, [inviteEmail])

  const renderContent = () => {
    switch (flow) {
      case CreateWorkspaceFlow.CreateWorkspace:
        return (
          <div className='max-w-sm text-center'>
            <h1 className='mb-xxl'>
              <Text size='display' bold testId='title-new-workspace'>
                New workspace
              </Text>
            </h1>
            <div className='w-full mb-xxl flex flex-col items-center'>
              <FilePicker
                accept='image/jpg, image/jpeg, image/png'
                onFiles={handleImageChange}
              >
                {onClick => (
                  <Avatar
                    onClick={onClick}
                    className='mb-sm'
                    name='Upload workspace image'
                    imageUrl={workspaceLogo?.src}
                    size='xl'
                    isWorkspace
                  />
                )}
              </FilePicker>
            </div>
            <div className='w-full mb-xxl flex flex-col items-center'>
              <div className='w-full mb-md flex flex-col items-start'>
                <Caption label='workspace name' className='mb-xs' />
                <Input
                  value={workspaceName}
                  autoFocus
                  placeholder='My Workspace name'
                  maxLength={20}
                  className='w-full mb-xs'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    dispatch({
                      type: FormActionType.SetName,
                      payload: e.target.value,
                    })
                    setError('')
                  }}
                  showError={error}
                />
                <Text
                  size='sm'
                  color='400'
                  testId='create-workspace-name-input'
                >
                  Could be a partners name or a company you’re working with
                </Text>
              </div>
              <div className='w-full flex flex-col items-start'>
                <Caption label='description' className='mb-xs' />
                <Input
                  value={workspaceDescription}
                  placeholder='Description'
                  maxLength={20}
                  className='w-full mb-xs'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    dispatch({
                      type: FormActionType.SetDescription,
                      payload: e.target.value,
                    })
                  }}
                />
                <Text size='sm' color='400' testId='input-workspace-name'>
                  (optional) A few words describing its purpose
                </Text>
              </div>
            </div>
            <Button
              testId='button-submit-email'
              className='w-full mb-xxl'
              disabled={!workspaceName.trim()}
              isFetching={loading}
              onClick={onCreate}
            >
              Continue
            </Button>
            <LinkButton onClick={onCancelCreation}>Cancel</LinkButton>
          </div>
        )

      case CreateWorkspaceFlow.AddMembers:
        if (!workspace) return null
        return (
          <div className='max-w-4xl text-center'>
            <h1 className='mb-xxl'>
              <Text size='display' bold testId='title-add-members'>
                My {workspaceName} workspace needs some members
              </Text>
            </h1>
            <div className='w-full flex items-start justify-center mb-xxl text-200 text-left'>
              <div className='w-2/4 flex flex-col gap-3.5 pr-20 border-r border-700 pb-20'>
                <Text color='200' size='lg' bold>
                  Invite by email
                </Text>
                <Text size='base' color='200'>
                  Add team members by providing their emails separated by comma
                  and we will send invites
                </Text>
                <EmailInput
                  emails={workspaceInviteEmails}
                  onSetEmails={(value: string[]) => {
                    dispatch({ type: FormActionType.SetEmails, payload: value })
                  }}
                />
              </div>
              <div className='w-2/4 flex flex-col gap-3.5	pl-20'>
                <Text color='200' size='lg' bold>
                  Invite via link
                </Text>
                <Text size='base' color='200'>
                  Share this link with a person you want to invite to your
                  workspace
                </Text>
                {workspace?.id && (
                  <div
                    onClick={() => onLinkClick(workspace.id)}
                    className='flex cursor-pointer overflow-hidden hover:bg-200/30 gap-lg self-stretch justify-between items-center p-md rounded-xs bg-200/10'
                  >
                    <Text color='blue' size='sm' className='break-words'>
                      {inviteLink}
                    </Text>
                    <Copy className='text-blue' width={48} />
                  </div>
                )}
              </div>
            </div>
            <Button
              testId='button-submit-email'
              className='w-80 mb-xxl'
              disabled={!workspaceInviteEmails.length}
              isFetching={loading}
              onClick={() => onSendInvites(workspace)}
            >
              Invite members
            </Button>

            <div>
              <LinkButton onClick={() => onSkipInvites(workspace.id)}>
                Skip
              </LinkButton>
            </div>
          </div>
        )

      default:
        return exhaustCheck(flow)
    }
  }

  const getStepClass = (index: number, type: string) => {
    if (index < flow) {
      return type === 'text' ? 'text-0' : 'bg-blue border-blue text-0'
    } else if (index === flow) {
      return type === 'text' ? 'text-0' : 'border-blue text-0'
    } else {
      return type === 'text' ? 'text-200' : 'border-200 text-200'
    }
  }

  return (
    <div className='flex flex-1 flex-col justify-center items-center'>
      <div className='flex flex-col gap-xxl items-center max-w-4x mx-xl my-lg'>
        <Logo />
        <Stepper currentStep={flow} getStepClass={getStepClass} steps={steps} />
        {renderContent()}
      </div>
    </div>
  )
})

export function createInviteUrl(id: string): string {
  const url = new URL(
    window.location.origin + buildUrl(AuthorizedRoutePath.Join, {}),
  )
  url.searchParams.append(SearchParams.InvitationId, id)
  return url.toString()
}
