import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'

import { trackEvent } from '@takle/analytics'
import { Button } from '@takle/components/Button'
import { HeaderBarWithLinks } from '@takle/components/HeaderBar/HeaderBarWithLinks'
import { Text } from '@takle/components/Text'
import { store } from '@takle/store'

import { ProductCard, ProductCardPrice } from './ProductCard'
import { StripeProductCard } from './StripeProductCard'
import { PageGradientBackground } from '@takle/components/PageGradientBackground/PageGradientBackground'
import { HorizontalSelect } from '@takle/components/HorizontalSelect'
import { useStrictNavigate } from '@takle/navigation/paths'
import { UnauthorizedRoutePath } from '@takle/navigation/UnauthorizedRoutes'
import {
  freeProductSettings,
  productSettings,
} from '@takle/utils/productSettings'
import { EMAIL } from '@takle/components/HeaderBar/ContactButton'

enum Duration {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

const options = [Duration.Monthly, Duration.Yearly]

export const PricingPage: FC = observer(() => {
  const currentAccount = store.accountsStore.currentAccount
  const products = store.paymentsStore.products
  const [duration, setDuration] = useState<Duration>(Duration.Yearly)

  const navigate = useStrictNavigate()

  useEffect(() => {
    trackEvent({
      kind: 'subscriptionScreenOpened',
      payload: {
        accountId: currentAccount.id,
      },
    })
  }, [currentAccount])

  const signUpButton = (
    <Button
      className='w-full'
      onClick={() => navigate(UnauthorizedRoutePath.SignUp, {})}
    >
      Try for free
    </Button>
  )

  return (
    <>
      <PageGradientBackground />
      <div className='relative flex flex-col self-center px-md md:px-lg lg:px-xxl w-full max-w-7xl gap-xxxl my-xl pb-xxxl'>
        <HeaderBarWithLinks />
        <div className='flex flex-col gap-xxl justify-center items-center mt-xl'>
          <Text bold size='display' center>
            Choose the plan that’s right for your team
          </Text>
          <div className='flex flex-col gap-md items-center'>
            <Text color='200' size='lg' bold>
              Pay by month or the year, and cancel at any time.
            </Text>

            <HorizontalSelect
              selectedOption={duration}
              onSelectOption={setDuration}
              options={options}
            />
          </div>
        </div>
        <div className='grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-lg xl:gap-xxl'>
          <ProductCard
            title='starter'
            name='Free'
            description={`Just for a good start,${'\n'}but it has some limitations`}
            {...freeProductSettings}
            button={
              currentAccount ? (
                <Button
                  bordered
                  color='transparent'
                  className='w-full text-400 pointer-events-none bg-200/20'
                >
                  {!currentAccount.subscriptionPlan
                    ? 'This is your plan'
                    : 'You already have subscription'}
                </Button>
              ) : (
                signUpButton
              )
            }
          >
            <ProductCardPrice currency='USD' amount={0} />
          </ProductCard>
          {products ? (
            <>
              {products.map(product => (
                <StripeProductCard
                  title={product.title}
                  interval={duration === Duration.Monthly ? 'month' : 'year'}
                  key={product.id}
                  product={product}
                  name={product.name}
                  description={product.description}
                  {...productSettings[product.productId]}
                  button={onClick =>
                    currentAccount ? (
                      <Button
                        className='w-full'
                        disabled={!!currentAccount.subscriptionPlan}
                        onClick={onClick}
                      >
                        {currentAccount.subscriptionPlan
                          ? currentAccount.subscriptionPlan ===
                            product.productId
                            ? 'This is your plan'
                            : 'You already subscribed'
                          : 'Upgrade'}
                      </Button>
                    ) : (
                      signUpButton
                    )
                  }
                />
              ))}
            </>
          ) : (
            Array.from(new Array(3)).map((_, i) => (
              <div
                key={i}
                className='flex w-full bg-0/10 rounded-md h-[400px] animate-pulse'
              />
            ))
          )}
        </div>
        <div className='flex flex-col gap-xxl items-center self-center'>
          <Text className='max-w-2xl text-center' color='400'>
            Easily book meetings, share documents, and simplify various other
            routine task. Streamline processes and speed up collaboration by
            building and connecting new workflows across organizations
          </Text>
          <Text size='xl' bold color='200'>
            Need more members or uploads capacity?
          </Text>

          <Button
            bordered
            onClick={() => window.open(`mailto:${EMAIL}`, '_blank')}
          >
            Contact our sales team
          </Button>
        </div>
      </div>
    </>
  )
})
