import { FC, useEffect } from 'react'
import { X } from 'tabler-icons-react'
import { useSpring, animated } from '@react-spring/web'
import { Text } from '@takle/components/Text'
import { Toast as ToastModel } from '@takle/store/uiStore'

const LIFETIME = 5 // seconds

export const Toast: FC<ToastModel> = ({ message, title, isWarning, hide }) => {
  useEffect(() => {
    const hideTimeout = setTimeout(hide, LIFETIME * 1000)

    return () => {
      clearTimeout(hideTimeout)
    }
  }, [hide])

  const fadeInFromBottom = useSpring({
    from: {
      translateY: 20,
      opacity: 0,
    },
    to: {
      translateY: 0,
      opacity: 1,
    },
    config: {
      tension: 600,
      friction: 50,
      mass: 1,
    },
  })

  return (
    <animated.div
      style={fadeInFromBottom}
      className={`relative w-80 flex items-center rounded-xs mb-xs ${
        isWarning ? 'bg-red' : 'bg-blue'
      }`}
    >
      <div className='flex-1 p-md text-base text-0'>
        {title && (
          <Text bold className='mb-xxs'>
            {title}
          </Text>
        )}
        {message}
      </div>
      <div
        className='text-0 cursor-pointer opacity-50 hover:opacity-100 transition-opacity p-md'
        onClick={hide}
      >
        <X size={16} />
      </div>
    </animated.div>
  )
}
