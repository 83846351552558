import { FC, HTMLAttributes, useState } from 'react'

import { store } from '@takle/store'

import { Text } from './Text'

import { ReactNode } from 'react'
import { handleError } from '@takle/sentry'

type DeleteWorkspaceButtonProps = {
  workspaceId: string
  renderButton: (v: () => void) => ReactNode
  isInitiallyOpened?: boolean
  onComplete?: () => void
} & HTMLAttributes<HTMLDivElement>

export const DeleteWorkspaceButton: FC<DeleteWorkspaceButtonProps> = ({
  workspaceId,
  renderButton,
  isInitiallyOpened = false,
  onComplete,
  ...rest
}) => {
  const [loading, setLoading] = useState(false)

  const onDelete = async () => {
    const deleteWorkspace = async () => {
      if (loading) return

      setLoading(true)
      try {
        await store.workspacesStore.deleteWorkspace(workspaceId)

        // Maybe will need in future
        // trackEvent({
        //   kind: 'workspaceRemoved',
        //   payload: { workspaceId },
        // })

        onComplete?.()
      } catch (error) {
        handleError(error)
        store.uiStore.showWarningToast(
          'Something wrong happened with deleting workspace, please try again',
        )
      }
      setLoading(false)
    }

    store.uiStore.setAlert({
      title: 'Delete Workspace',
      message:
        'This action will delete the workspace with all channels, users and messages',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: <Text color='red'>Yes, delete</Text>,
          onClick: deleteWorkspace,
        },
      ],
    })
  }

  return <div {...rest}>{renderButton(() => onDelete())}</div>
}
