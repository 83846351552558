import { InverseButton, LinkButton } from "@takle/components/Button"
import { Logo } from "@takle/components/Logo"
import { AuthorizedRoutePath } from "@takle/navigation/AuthorizedRoutes"
import { useStrictNavigate } from "@takle/navigation/paths"
import { FC } from "react"

export const PaymentPageMobile : FC = () => {
  const navigate = useStrictNavigate()
  return (
    <div className='flex flex-1 flex-col justify-center items-center'>
      <div className='flex flex-col gap-xl items-center w-full px-md md:px-0 md:max-w-md my-lg'>
        <Logo />
        <span className="text-base text-0 text-200 md:block text-center">
          Plan management is only supported in a web version as of now.
          Click the button below if you want to proceed to the web version.
        </span>
        {/*FIXME: parametrize payment redirect URL */}
        <InverseButton 
          onClick={ () => {window.open('https://takle.app')} }
        >
          Open web portal
        </InverseButton>
        <LinkButton onClick={() => navigate(AuthorizedRoutePath.Dashboard, {})}>
          Go back
        </LinkButton>
      </div>
    </div>
  )
}