import { FC } from 'react'

import { Text, TextProps } from '@takle/components/Text'

type UnreadCountProps = {
  count?: number
} & TextProps

export const UnreadCount: FC<UnreadCountProps> = ({
  count,
  className,
  ...rest
}) => {
  if (!count) return null

  return (
    <Text
      size='xs'
      color='800'
      bold
      className={`bg-green min-w-[15px] text-center rounded-sm px-xxs ml-xs relative bottom-[1px] ${
        className || ''
      }`}
      {...rest}
    >
      {count}
    </Text>
  )
}
