import { FC, HTMLAttributes, useState } from 'react'

import { store } from '@takle/store'

import { Text } from './Text'

import { ReactNode } from 'react'
import { handleError } from '@takle/sentry'

type DeleteChannelButtonProps = {
  channelId: string
  renderButton: (v: () => void) => ReactNode
  isInitiallyOpened?: boolean
  onComplete?: () => void
} & HTMLAttributes<HTMLDivElement>

export const DeleteChannelButton: FC<DeleteChannelButtonProps> = ({
  channelId,
  renderButton,
  isInitiallyOpened = false,
  onComplete,
  ...rest
}) => {
  const [loading, setLoading] = useState(false)

  const onDelete = async () => {
    const deleteChannel = async () => {
      if (loading) return

      setLoading(true)
      try {
        await store.channelsStore.deleteChannel(channelId)
        // Maybe will need in future
        // trackEvent({
        //   kind: 'channelRemoved',
        //   payload: { workspaceId: '' },
        // })

        setLoading(false)

        onComplete?.()
      } catch (error) {
        handleError(error)
        setLoading(false)
        store.uiStore.showWarningToast(
          'Something wrong happened with deleting channel, please try again',
        )
      }
    }

    store.uiStore.setAlert({
      title: 'Delete Channel',
      message: 'This action will delete channel with all message history',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: <Text color='red'>Yes, delete</Text>,
          onClick: deleteChannel,
        },
      ],
    })
  }

  return <div {...rest}>{renderButton(() => onDelete())}</div>
}
