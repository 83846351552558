import { Avatar } from '@takle/components/Avatar'
import { handleError } from '@takle/sentry'
import { store } from '@takle/store'
import { Channel, DirectChannel } from '@takle/store/channelsStore'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'

const inViewportOptions = {
  disconnectOnLeave: true,
}

export const OlderMessagesLoader: FC<{ channel: Channel | DirectChannel }> =
  observer(({ channel }) => {
    const ref = useRef<HTMLDivElement>(null)

    const { inViewport } = useInViewport(ref, undefined, inViewportOptions)
    const shouldLoadMore = channel.hasMoreToLoad && !channel.isLoadingMore

    useEffect(() => {
      if (!inViewport || !shouldLoadMore) return

      const fetchMoreMessages = async () => {
        if (!channel) return
        try {
          await channel.loadOlderMessages()
        } catch (e) {
          handleError(e)
          store.uiStore.showWarningToast(
            'An error occurred while fetching channel messages',
          )
        }
      }

      fetchMoreMessages()
    }, [inViewport, shouldLoadMore, channel])

    if (!channel.hasMoreToLoad) return null

    return (
      <div ref={ref} className='flex flex-col gap-sm'>
        {[...new Array(4)].map((_, i) => {
          const widths =
            i % 2 === 0 ? ['w-32', 'w-60', 'w-48'] : ['w-24', 'w-64', 'w-32']

          return (
            <div key={i} className='flex mx-lg'>
              <div className='w-xxl'>
                <Avatar className='mt-1 animate-pulse' />
              </div>
              <div className='ml-xs flex flex-col flex-1 pt-xxs gap-xs'>
                {widths.map(w => (
                  <div
                    key={w}
                    className={`rounded-xs bg-600 animate-pulse h-4 ${w}`}
                  />
                ))}
              </div>
            </div>
          )
        })}
      </div>
    )
  })
