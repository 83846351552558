import { Quill } from 'react-quill'
import {DeltaStatic, Delta as DeltaType } from 'quill'

const Delta: typeof DeltaType = Quill.import('delta')

export const removeFormattingOnPaste = (node: HTMLElement | DocumentFragment, delta: DeltaStatic) => {
  
    if (delta.ops?.some((op) => op.attributes && op.attributes.list)) {
      return delta;
    }
    if (delta.ops?.some((op) => op.attributes && op.attributes.link)) {
      const ops = delta.ops.map((op) => {
          if (op.attributes && (op.attributes.background || op.attributes.color || op.attributes.bold)) {
            const { background, color, bold, ...restAttributes } = op.attributes;
      
            return { insert: op.insert, attributes: restAttributes };
          }
          return op;
        })
      
      return new Delta(ops);
    } else {
      const ops = delta.ops?.map((op) => ({insert: op.insert}));
      return new Delta(ops)
    }
  }