import { UsersStore } from './usersStore'
import { WorkspacesStore } from './workspacesStore'
import { ChannelsStore } from './channelsStore'
import { v4 } from 'uuid'
import { PaymentsStore } from './paymentsStore'
import { __DEV__ } from '@takle/constants'
import { UiStore } from './uiStore'
import { AccountsStore } from './accountsStore'
import { StatisticsStore } from './statisticsStore'

export class Store {
  clientId = v4()

  usersStore: UsersStore
  accountsStore: AccountsStore
  workspacesStore: WorkspacesStore
  channelsStore: ChannelsStore
  paymentsStore: PaymentsStore
  uiStore: UiStore
  statisticsStore: StatisticsStore

  constructor() {
    this.usersStore = new UsersStore(this)
    this.accountsStore = new AccountsStore(this)
    this.workspacesStore = new WorkspacesStore(this)
    this.channelsStore = new ChannelsStore(this)
    this.paymentsStore = new PaymentsStore(this)
    this.uiStore = new UiStore(this)
    this.statisticsStore = new StatisticsStore(this)
  }
}

export const store = new Store()

if (__DEV__) {
  const w: any = window
  w['store'] = store
}
