import { FC, HTMLAttributes } from 'react'

type TabProps = {
  isActive?: boolean
} & HTMLAttributes<HTMLDivElement>

export const Tab: FC<TabProps> = ({
  children,
  isActive,
  className,
  ...rest
}) => {
  return (
    <div
      className={`
    text-base pb-sm
    ${
      isActive
        ? 'text-0 border-b-2 border-200'
        : 'text-200 border-b hover:text-0 border-600 hover:border-blue cursor-pointer'
    }
  
  ${className || ''}`}
      {...rest}
    >
      {children}
    </div>
  )
}
