import { SearchParams } from '@takle/constants'
import { setLocalStorageItem } from '@takle/localStorage'
import { useStrictNavigate } from '@takle/navigation/paths'
import { UnauthorizedRoutePath } from '@takle/navigation/UnauthorizedRoutes'
import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const SaveInvitationId: FC<{}> = () => {
  const navigate = useStrictNavigate()
  const [searchParams] = useSearchParams()
  const invitationId = searchParams.get(SearchParams.InvitationId)
  if (invitationId) {
    setLocalStorageItem('invitationId', invitationId)
  }

  useEffect(() => {
    navigate(UnauthorizedRoutePath.Login, {})
  }, [navigate])

  return <></>
}
