import { useDropzone } from 'react-dropzone'
import {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Text } from '@takle/components/Text'
import { Upload } from 'tabler-icons-react'

type DropFileAreaProps = {
  disabled?: boolean
  onUploadFiles: (files: File[]) => void
} & HTMLAttributes<HTMLDivElement>

export const DropFileArea: FC<DropFileAreaProps> = ({
  onUploadFiles,
  disabled,
  children,
  className,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [dragOver, setDragOver] = useState(false)

  const onDrop = useCallback(
    (files: File[]) => {
      if (dragOver) {
        setDragOver(false)
      }
      onUploadFiles(files)
    },
    [dragOver, onUploadFiles],
  )

  const { getRootProps, isDragActive } = useDropzone({ onDrop, disabled })

  useEffect(() => {
    if (disabled) return
    const el = ref.current

    const setDragStarted = () => {
      setDragOver(true)
    }

    const setDragEnded = () => {
      setDragOver(false)
    }

    el?.addEventListener('dragover', setDragStarted)
    el?.addEventListener('dragleave', setDragEnded)
    return () => {
      el?.removeEventListener('dragover', setDragStarted)
      el?.removeEventListener('dragleave', setDragEnded)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, disabled])

  const dropZoneProps = getRootProps()

  const isVisible = isDragActive || dragOver

  return (
    <div className={`relative ${className || ''}`} ref={ref} {...rest}>
      {children}
      <div
        {...dropZoneProps}
        className={`absolute z-50 transition-all w-full h-full flex justify-center flex-col items-center ${
          isVisible
            ? 'opacity-100 bg-800/80'
            : 'hidden opacity-0 bg-800/20 pointer-events-none'
        }`}
      >
        <div className='flex flex-col gap-lg items-center max-w-xs'>
          <Upload size={64} className='text-600 mb' />
          <Text size='display-sm' color='400'>
            Upload file
          </Text>
          <Text color='200'>Drop your file here to start uploading</Text>
        </div>
      </div>
    </div>
  )
}
