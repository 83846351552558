import { UserTokenModel } from '@takle/models/models'
import { setDoc } from 'firebase/firestore'
import { Collections } from './constants'
import { fireStore } from './firebase'
import { doc } from 'firebase/firestore'

/*

User Tokens

*/

export function getUserTokenDocumentRef(userId: string) {
  return doc(fireStore, Collections.UserTokens, userId)
}

export async function updateUserTokenDocumentWithToken(
  userId: string,
  token: string,
) {
  const updateUserTokenData: Pick<UserTokenModel, 'tokens'> = {
    tokens: {
      [token]: true,
    },
  }

  return await setDoc(getUserTokenDocumentRef(userId), updateUserTokenData, {
    merge: true,
  })
}
