import { FC, HTMLAttributes, ReactNode, useState } from 'react'
import { Copy, UserPlus, X } from 'tabler-icons-react'
import { observer } from 'mobx-react-lite'

import { trackEvent } from '@takle/analytics'
import { Workspace } from '@takle/store/workspacesStore'
import { store } from '@takle/store'
import { WEB_APP_ORIGIN_URL, SearchParams } from '@takle/constants'

import { IconButton, LinkButton } from './Button'
import { Text } from './Text'
import { Modal } from './Modal'
import { useHotkeys } from 'react-hotkeys-hook'
import { handleError } from '@takle/sentry'
import { buildUrl } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'

export type InviteModalProps = {
  workspace: Workspace
  onClose: () => void
}

export const InviteModal: FC<InviteModalProps> = observer(
  ({ workspace, onClose }) => {
    const currentAccount = store.accountsStore.currentAccount

    const [isUpdatingLink, setUpdatingLink] = useState(false)
    const inviteLink = createInviteUrl(workspace.inviteHash)
    useHotkeys('esc', onClose)

    const onRefreshLinkClick = async () => {
      setUpdatingLink(true)
      try {
        await workspace.refreshInvitationHash()
        store.uiStore.showInfoToast(
          'Workspace invitation link has been refreshed.',
        )
      } catch (e) {
        handleError(e)
        store.uiStore.showWarningToast(
          'An error occurred while updating invitation link, please try again',
        )
      }
      setUpdatingLink(false)
    }

    const onLinkClick = () => {
      navigator.clipboard.writeText(inviteLink)
      trackEvent({
        kind: 'inviteLinkCopied',
        payload: { workspaceId: workspace.id, accountId: currentAccount.id },
      })
      store.uiStore.showInfoToast('Link copied to your clipboard')
    }

    return (
      <Modal onClose={onClose}>
        <div className='flex flex-col gap-md'>
          <div className='flex items-center self-stretch justify-between'>
            <UserPlus size={36} className='text-200' />
            <IconButton onClick={onClose}>
              <X />
            </IconButton>
          </div>
          <Text size='xl' bold color='700'>
            Invite new member to
            <br />
            {workspace.name}
          </Text>
          <Text color='400'>
            Share this link with a person you want to invite to your workspace
          </Text>
          <div
            onClick={onLinkClick}
            className='flex cursor-pointer overflow-hidden hover:bg-200/30 gap-lg self-stretch justify-between items-center p-md rounded-xs bg-200/10'
          >
            <Text color='blue' size='sm' className='break-words'>
              {inviteLink}
            </Text>
            <Copy className='text-blue' width={48} />
          </div>
          {workspace.accountId === currentAccount?.id && (
            <div>
              <LinkButton
                disabled={isUpdatingLink}
                onClick={onRefreshLinkClick}
              >
                Refresh invitation link
              </LinkButton>
            </div>
          )}
        </div>
      </Modal>
    )
  },
)

type InviteModalButtonProps = {
  onCloseParent?: () => void
  renderButton: (v: () => void) => ReactNode
  isInitiallyOpened?: boolean
  workspace: Workspace
} & HTMLAttributes<HTMLDivElement>

export const InviteModalButton: FC<InviteModalButtonProps> = ({
  onCloseParent,
  renderButton,
  workspace,
  isInitiallyOpened = false,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(isInitiallyOpened)

  return (
    <div {...rest}>
      {renderButton(() => setOpen(true))}
      {isOpen && (
        <InviteModal
          workspace={workspace}
          onClose={() => {
            onCloseParent?.()
            setOpen(false)
          }}
        />
      )}
    </div>
  )
}

export function createInviteUrl(id: string): string {
  const url = new URL(
    WEB_APP_ORIGIN_URL + buildUrl(AuthorizedRoutePath.Join, {}),
  )
  url.searchParams.append(SearchParams.InvitationId, id)
  return url.toString()
}
