import { Control, Controller, Path, RegisterOptions } from 'react-hook-form'
import { FormFieldLabel } from './FormFieldLabel'
import { Select } from './Select'

type FormSelectProps<FV, FI> = {
  fieldItem: FI
  control: Control<FV, any>
  loading?: boolean
  classNames?: string
  rules?: Omit<
    RegisterOptions<FV, Path<FV>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  error?: string
}

export const FormSelect = <
  FV extends {},
  FO extends { value: string | number },
  FI extends { name: Path<FV>; options: FO[]; label?: string },
>({
  fieldItem,
  control,
  loading,
  classNames,
  rules,
  error,
}: FormSelectProps<FV, FI>) => {
  return (
    <Controller
      name={fieldItem.name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <div className={classNames}>
          {fieldItem.label && <FormFieldLabel label={fieldItem.label} />}
          <Select
            {...field}
            options={fieldItem.options}
            placeholder='Choose an option'
            isSearchable={false}
            isDisabled={loading}
            error={error}
          />
        </div>
      )}
    />
  )
}
