import { FC, HTMLAttributes } from 'react'

import { ActivityIndicator } from './ActivityIndicator'
import { Text, TextProps } from './Text'

export type ButtonProps = {
  testId?: string
  size?: 'sm' | 'base'
  color?: 'blue' | 'orange' | 'transparent'
  bordered?: boolean
  disabled?: boolean
  isFetching?: boolean
} & HTMLAttributes<HTMLDivElement>

const buttonShape = {
  sm: 'px-md py-xxs text-sm rounded-xs border font-semibold antialiased inline-block transition-all relative',
  base: 'px-lg py-sm text-base rounded-xs border font-semibold antialiased inline-block transition-all relative',
}

const containerShape = 'flex justify-center items-center'

export const Button: FC<ButtonProps> = ({
  testId,
  size = 'base',
  color = 'blue',
  disabled,
  isFetching,
  bordered,
  children,
  onClick,
  className,
  ...rest
}) => {
  return (
    <div
      data-testid={testId}
      onClick={!disabled ? onClick : undefined}
      className={`
      ${buttonShape[size]}
      ${isFetching ? 'pointer-events-none' : ''}
      ${
        disabled
          ? bordered
            ? `border-400 text-400`
            : 'border-600 bg-600 text-400'
          : bordered
          ? `border-${color} hover:-hue-rotate-15 text-0 cursor-pointer`
          : `border-${color} bg-${color} hover:bg-${color}/80 hover:-hue-rotate-15 text-0 cursor-pointer`
      }
       ${className || ''}`}
      {...rest}
    >
      <div className={`${containerShape} ${isFetching ? 'opacity-0' : ''}`}>
        {children}
      </div>
      {isFetching && <ButtonLoader />}
    </div>
  )
}

export const InverseButton: FC<Omit<ButtonProps, 'color'>> = ({
  testId,
  size = 'base',
  disabled,
  bordered,
  children,
  isFetching,
  onClick,
  className,
  ...rest
}) => {
  return (
    <div
      data-testid={testId}
      onClick={onClick}
      className={`
      ${buttonShape[size]}
      ${isFetching ? 'pointer-events-none' : ''}
      ${
        disabled
          ? bordered
            ? `border-400 text-400`
            : 'border-200 bg-200 text-400'
          : bordered
          ? `border-200 hover:border-blue/80 text-900 cursor-pointer`
          : 'border-0 bg-0 hover:bg-200 hover:border-200 text-900 cursor-pointer'
      }
       ${className || ''}`}
      {...rest}
    >
      <div className={`${containerShape} ${isFetching ? 'opacity-0' : ''}`}>
        {children}
      </div>
      {isFetching && <ButtonLoader />}
    </div>
  )
}

type IconButtonProps = {
  testId?: string
  size?: 'lg' | 'md' | 'sm'
  active?: boolean
  disabled?: boolean
} & HTMLAttributes<HTMLDivElement>

export const IconButton: FC<IconButtonProps> = ({
  testId,
  size = 'md',
  disabled,
  active,
  className,
  children,
  onClick,
  ...rest
}) => {
  const sizeClass =
    size === 'lg'
      ? 'w-12 h-12 rounded-sm text-lg'
      : size === 'md'
      ? 'w-9 h-9 rounded-xs text-base'
      : 'w-6 h-6 rounded-xxs text-sm'
  return (
    <div
      data-testid={testId}
      onClick={onClick}
      className={`${sizeClass} uppercase overflow-hidden flex justify-center items-center aspect-square
      ${
        disabled
          ? 'text-400'
          : 'hover:bg-600 hover:text-0 text-200 cursor-pointer'
      }  
      ${active ? 'text-0 bg-600' : ''}
      
      ${className || ''}`}
      {...rest}
    >
      {children}
    </div>
  )
}

type LinkButtonProps = {
  testId?: string
  disabled?: boolean
} & TextProps

export const LinkButton: FC<LinkButtonProps> = ({
  testId,
  disabled,
  children,
  color,
  className,
  ...rest
}) => (
  <Text
    data-testid={testId}
    className={`inline-block underline ${
      disabled ? 'pointer-events-none opacity-70' : 'cursor-pointer'
    } hover:no-underline ${className || ''}`}
    color={color || 'blue'}
    {...rest}
  >
    {children}
  </Text>
)

const ButtonLoader = () => (
  <ActivityIndicator className='absolute top-0 left-0 right-0 bottom-0 scale-75' />
)
