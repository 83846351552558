import { Quill } from "react-quill"


const QuillLink = Quill.import('formats/link')

export class LinkWithValidation extends QuillLink {
  static sanitize(url: string) {
    if (url) {
      for (let i = 0; i < QuillLink.PROTOCOL_WHITELIST.length; i++) {
        return url.startsWith(QuillLink.PROTOCOL_WHITELIST[i])
          ? url
          : `https://${url}`
      }
    }
    return url
  }
}