import { Capacitor } from "@capacitor/core"

export enum SearchParams {
  InvitationId = 'invitation_id',
}

export const __DEV__ = process.env.NODE_ENV === 'development'

export const THE_VERY_FIRST_DAY_OF_UNIVERSE = new Date(0)
export const CHANNEL_MESSAGES_PAGESIZE = 50
export const CHANNEL_MESSAGES_SHOW_DATE_DIFF = 5 * 60 * 1000 // 5 minutes
export const EMPTY_MESSAGE_QUILL = '{"ops":[{"insert":"\\n"}]}'

export const WEB_APP_ORIGIN_URL = process.env.REACT_APP_WEB_APP_ORIGIN_URL || window.location.origin

//Always true, unless it explicitly set as false, or is native platform
export const FT_SHOW_UPGRADE_BUTTON = (!!process.env.REACT_APP_FT_SHOW_UPGRADE_BUTTON) ?
    process.env.REACT_APP_FT_SHOW_UPGRADE_BUTTON === 'true'
    : (Capacitor.isNativePlatform()) ? false : true