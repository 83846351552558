import { FC, MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { BrowserPlus, Dots, Users } from 'tabler-icons-react'
import SimpleBar from 'simplebar-react'
import { observer } from 'mobx-react-lite'

import { Text } from '@takle/components/Text'
import { Button, IconButton } from '@takle/components/Button'
import { Input } from '@takle/components/Input'
import { Caption } from '@takle/components/Caption'
import { AvatarTitle } from '@takle/components/AvatarTitle'
import { PopupButton } from '@takle/components/Popup'
import { InviteModalButton } from '@takle/components/InviteModal'
import { store } from '@takle/store'
import { PendingWorkspace, Workspace } from '@takle/store/workspacesStore'
import { WorkspaceEditModalButton } from '@takle/components/WorkspaceEditModal'
import { UnreadCount } from '@takle/components/UnreadCount'
import { handleError } from '@takle/sentry'
import { SearchParams } from '@takle/constants'
import { useSearchParams } from 'react-router-dom'
import { getLocalStorageItem } from '@takle/localStorage'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'

type WorkspacesListProps = {
  workspaces: Array<Workspace>
  pendingWorkspaces: Array<PendingWorkspace>
  onChangeWorkspace: (id: string) => void
  onWorkspaceRemoved?: () => void
}

export const WorkspacesList: FC<WorkspacesListProps> = observer(
  ({
    workspaces,
    pendingWorkspaces,
    onChangeWorkspace,
    onWorkspaceRemoved,
  }) => {
    const id = store.usersStore.currentLoggedInUser.id
    const [filterText, setFilterText] = useState('')
    const [searchParams] = useSearchParams()
    const navigate = useStrictNavigate()

    const currentUser = store.usersStore.currentLoggedInUser
    const userProfileNameIsSet = !!currentUser && currentUser.profileNameIsSet()

    const invitationId =
      searchParams.get(SearchParams.InvitationId) ||
      getLocalStorageItem('invitationId')

    const noCreationRedirect = getLocalStorageItem('noCreationRedirect')

    useEffect(() => {
      const userHasNoWorkspace = !workspaces.length && !invitationId
      if (userHasNoWorkspace && userProfileNameIsSet && !noCreationRedirect) {
        navigate(AuthorizedRoutePath.CreateWorkspace, {})
      }
      return
    }, [
      invitationId,
      navigate,
      noCreationRedirect,
      userProfileNameIsSet,
      workspaces.length,
    ])

    const filteredSpaces = useMemo(() => {
      return workspaces?.filter(w =>
        w.name.toLowerCase().includes(filterText.toLowerCase()),
      )
    }, [workspaces, filterText])

    return (
      <div className='flex flex-1 flex-col'>
        <div className='flex justify-between items-center mb-sm'>
          <div>
            <Input
              value={filterText}
              className='lg:w-96'
              onChange={e => setFilterText(e.target.value)}
              placeholder='Search spaces...'
            />
          </div>
          <Button
            onClick={() => navigate(AuthorizedRoutePath.CreateWorkspace, {})}
            className='hidden md:inline-block'
          >
            <BrowserPlus className='mr-xs' />
            Create Workspace
          </Button>
          <IconButton
            onClick={() => navigate(AuthorizedRoutePath.CreateWorkspace, {})}
            className='inline-block md:hidden'
          >
            <BrowserPlus className='text-blue' />
          </IconButton>
        </div>
        <div className='flex flex-col flex-1 -mx-lg relative'>
          <div className='absolute left-0 right-0 bottom-0 top-0'>
            <SimpleBar forceVisible='x' className='w-full h-full'>
              {filteredSpaces?.map(w => (
                <WorkspaceListItem
                  key={w.id}
                  workspace={w}
                  isOwned={id === w.accountId}
                  onClick={() => onChangeWorkspace(w.id)}
                  onWorkspaceRemoved={onWorkspaceRemoved}
                />
              ))}
              {!!pendingWorkspaces.length && (
                <>
                  <div className='pt-md pb-sm mx-lg'>
                    <Text bold color='200' size='lg'>
                      Waiting approval from the Owner
                    </Text>
                  </div>
                  {pendingWorkspaces.map(w => (
                    <PendingWorkspaceItem key={w.id} workspace={w} />
                  ))}
                </>
              )}
            </SimpleBar>
          </div>
        </div>
      </div>
    )
  },
)

type WorkspaceListItemProps = {
  isOwned: boolean
  workspace: Workspace
  onClick: () => void
  onWorkspaceRemoved?: () => void
}

const WorkspaceListItem: FC<WorkspaceListItemProps> = observer(
  ({ workspace, isOwned, onClick, onWorkspaceRemoved }) => {
    const pendingUserIds = Object.keys(workspace.pendingUserIds).filter(id =>
      Boolean(workspace.pendingUserIds[id]),
    )
    const userIds = Object.keys(workspace.userIds).filter(id =>
      Boolean(workspace.userIds[id]),
    )
    const [isFetching, setFetching] = useState(false)

    const handleLeavePress = () => {
      const onLeave = async () => {
        setFetching(true)
        try {
          await workspace.leave()
          store.uiStore.showInfoToast("You've left the workspace")
        } catch (e) {
          handleError(e)
          store.uiStore.showWarningToast(
            'An error occurred while leaving the workspace, please try again',
          )
        }
        setFetching(false)
      }
      store.uiStore.setAlert({
        title: 'Leave Workspace',
        message: `Are you sure you want to leave "${workspace.name}" workspace?`,
        buttons: [
          {
            label: 'Cancel',
          },
          {
            label: <Text color='red'>Yes, leave</Text>,
            onClick: onLeave,
          },
        ],
      })
    }

    return (
      <div
        data-testid={`${workspace.name}-workspace-list-item`}
        className={` group relative border-b border-800 flex justify-between py-lg items-center cursor-pointer px-lg ${
          isFetching ? 'animate-pulse pointer-events-none' : ''
        }`}
      >
        <div
          onClick={onClick}
          onTouchEnd={onClick}
          className='absolute top-0 bottom-0 right-xs left-xs bg-800 rounded-sm opacity-0 paren group-hover:opacity-100 transition-opacity'
        />
        <div
          className='flex flex-1 items-center gap-md flex-grow-0 relative'
          onClick={onClick}
          onTouchEnd={onClick}
        >
          <AvatarTitle
            imageUrl={workspace.imageURL}
            name={workspace.name}
            label={workspace.name}
            className='max-w-[200px] md:max-w-[300px] lg:max-w-md'
          />
          <UnreadCount
            count={workspace.unreadCount}
            className='!absolute -top-xxs left-5 min-w-[15px] text-center !bottom-auto'
          />
          {isOwned && (
            <Caption
              label='owner'
              className='bg-400 px-xs rounded-xs py-xxs text-900 hidden md:inline-block'
            />
          )}
        </div>

        <div className='flex items-center gap-sm relative'>
          <div className='flex items-center'>
            <Users size={14} className='text-400 mr-xs' />
            <Text size='sm' color='200' bold>
              {userIds.length}
              {!!pendingUserIds.length && (
                <Text color='800' className='bg-green px-xs rounded-sm ml-xs'>
                  {pendingUserIds.length}
                </Text>
              )}
            </Text>
          </div>
          <PopupButton
            renderButton={openPopup => (
              <IconButton onClick={openPopup}>
                <Dots />
              </IconButton>
            )}
          >
            {onClose => (
              <>
                <InviteButton workspace={workspace} onClose={onClose} />
                {isOwned && (
                  <WorkspaceEditModalButton
                    workspace={workspace}
                    onCloseParent={onClose as any}
                    onWorkspaceRemoved={onWorkspaceRemoved}
                    renderButton={onOpenModal => (
                      <div
                        onClick={onOpenModal}
                        className='border-t border-600 px-md py-sm hover:bg-700 w-48 cursor-pointer'
                      >
                        <Text color='200'>Settings</Text>
                      </div>
                    )}
                  />
                )}
                {!isOwned && (
                  <div
                    onClick={e => {
                      handleLeavePress()
                      onClose(e)
                    }}
                    className='border-t border-600 px-md py-sm hover:bg-700 w-48 cursor-pointer'
                  >
                    <Text color='red'>Leave Workspace</Text>
                  </div>
                )}
              </>
            )}
          </PopupButton>
        </div>
      </div>
    )
  },
)

type PendingWorkspaceItemProps = {
  workspace: PendingWorkspace
}

const PendingWorkspaceItem: FC<PendingWorkspaceItemProps> = observer(
  ({ workspace }) => {
    const [isFetching, setFetching] = useState(false)

    const onCancelPress = () => {
      const onCancel = async () => {
        setFetching(true)
        try {
          await workspace.cancelRequest()
          store.uiStore.showInfoToast('Your request was cancelled')
        } catch (e) {
          handleError(e)
          store.uiStore.showWarningToast(
            'An error occurred while cancelling request, please try again',
          )
        }
        setFetching(false)
      }
      store.uiStore.setAlert({
        title: 'Leave Workspace',
        message: `Are you sure you want to cancel your request to join "${workspace.name}" workspace?`,
        buttons: [
          {
            label: 'onCancel',
          },
          {
            label: <Text color='red'>Yes, cancel</Text>,
            onClick: onCancel,
          },
        ],
      })
    }

    return (
      <div
        className={`group relative border-b border-800 flex justify-between py-lg items-center px-lg ${
          isFetching ? 'animate-pulse pointer-events-none' : ''
        }`}
      >
        <div className='absolute top-0 bottom-0 right-xs left-xs bg-800 rounded-sm opacity-0 paren group-hover:opacity-100 transition-opacity' />
        <div className='flex flex-1 items-center gap-md flex-grow-0 relative'>
          <AvatarTitle
            imageUrl={workspace.imageURL}
            name={workspace.name}
            label={workspace.name}
            className='max-w-[200px] md:max-w-[300px] lg:max-w-md'
          />
        </div>
        <div className='relative'>
          <PopupButton
            renderButton={openPopup => (
              <IconButton onClick={openPopup}>
                <Dots />
              </IconButton>
            )}
          >
            {onClose => (
              <>
                <div
                  onClick={e => {
                    onCancelPress()
                    onClose(e)
                  }}
                  className='border-t border-600 px-md py-sm hover:bg-700 w-48 cursor-pointer'
                >
                  <Text color='red'>Cancel request</Text>
                </div>
              </>
            )}
          </PopupButton>
        </div>
      </div>
    )
  },
)

const InviteButton: FC<{
  workspace: Workspace
  onClose: MouseEventHandler
}> = ({ workspace, onClose }) => (
  <InviteModalButton
    onCloseParent={onClose as any}
    workspace={workspace}
    renderButton={onOpenModal => (
      <div
        onClick={onOpenModal}
        className='px-md py-sm hover:bg-700 w-48 cursor-pointer'
      >
        <Text color='200'>Invite member</Text>
      </div>
    )}
  />
)
