import { SearchParams } from '@takle/constants'
import { getLocalStorageItem } from '@takle/localStorage'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

export const RedirectByInvitationId = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const invitationIdFromStorage = getLocalStorageItem('invitationId')
    if (invitationIdFromStorage) {
      // TODO: upgrade useStrictNavigate to support query params
      navigate(`join?${SearchParams.InvitationId}=${invitationIdFromStorage}`)
    }
  }, [navigate])
  return <></>
}
