import { WEB_APP_ORIGIN_URL, __DEV__ } from '@takle/constants'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AuthorizedRoutePath } from './AuthorizedRoutes'
import { UnauthorizedRoutePath } from './UnauthorizedRoutes'
import { Capacitor } from '@capacitor/core'

const PREFIX = '[Navigation]: '

type Path = AuthorizedRoutePath | UnauthorizedRoutePath

type ExtractRouteParams<T> = string extends T
  ? Record<string, string>
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends `${infer _Start}:${infer Param}`
  ? { [k in Param]: string }
  : // eslint-disable-next-line @typescript-eslint/ban-types
    {}

export type PathParams<P extends Path> = ExtractRouteParams<P>

export const buildUrl = <P extends Path>(
  path: P,
  params: PathParams<P>,
): string => {
  let ret: string = path
  const paramObj: { [i: string]: string } = params

  for (const key of Object.keys(paramObj)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ret = ret.replace(`:${key}`, paramObj[key]!)
  }

  return ret
}

const isAbsoluteUrl = (url: string) => {
  return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('capacitor://')
}

// Makes url suitable for internal app use, like window.location.href navigation for both mobile and web application
export const makeInternalUrl = (url: string) :string => {

  if (isAbsoluteUrl(url)) {
    const safeOrigin = Capacitor.isNativePlatform()? 'capacitor://localhost' : WEB_APP_ORIGIN_URL
    const urlObj = new URL(url)
    const safeUrl = urlObj.href.replace(urlObj.origin, safeOrigin)
    return safeUrl
  }

  // in case of relative URL it would work without modification 
  return url 
}

// Makes url suitable for use outside of the app, like sharing.
// Takes an absolute web url, or absolute capacitor url
export const makeExternalUrl = (url: string) :string => {
  if (!isAbsoluteUrl(url)) {
    throw new Error('Relative URL couldnt be transformed to external URL')
  }

  if (url.startsWith('capacitor://')) {
    const urlObj = new URL(url)
    const safeUrl = urlObj.href.replace(urlObj.origin, WEB_APP_ORIGIN_URL)
    return safeUrl
  }
  
  return url
}

export const useStrictParams = <P extends Path>() =>
  useParams() as PathParams<P>

export const useStrictNavigate = () => {
  const navigate = useNavigate()

  return useCallback(
    <P extends Path>(path: P, params: PathParams<P>) => {
      const url = buildUrl(path, params)

      if (__DEV__) {
        console.log(PREFIX, url)
      }
      return navigate(url)
    },
    [navigate],
  )
}
