import { getStripeProductPrices, getStripeProducts } from '@takle/firebase'
import { action, makeObservable, observable } from 'mobx'
import { startSubscription } from '@takle/firebase'

import { Store } from '.'
import * as Model from '../models/models'

export class PaymentsStore {
  products: Array<Product> = []

  store: Store
  constructor(store: Store) {
    this.store = store
    this.fetchProducts()

    makeObservable(this, {
      products: observable,
      setProducts: action,
    })
  }

  async fetchProducts() {
    this.setProducts(await getStripeProducts())
  }

  setProducts(v: Model.ProductModel[]) {
    this.products = v.map(p => new Product(p))
  }

  async startSubscription({
    price,
    tokenId,
  }: {
    price: Model.ProductPriceModel
    tokenId: string
  }) {
    await startSubscription({ priceId: price.id, source: tokenId })
  }
}

export class Product {
  id: string
  name: string
  description: string
  prices: Model.ProductPriceModel[] = []
  productId: Model.ProductIdModel
  title: string

  constructor(data: Model.ProductModel) {
    this.id = data.id
    this.name = data.name
    this.description = data.description
    this.productId = data.metadata.takleProductId
    this.title = data.metadata.takleProductTitle
    this.fetchPrices()

    makeObservable(this, {
      name: observable,
      description: observable,
      title: observable,
      prices: observable,
      setPrices: action,
    })
  }

  async fetchPrices() {
    this.setPrices(await getStripeProductPrices(this.id))
  }

  setPrices(v: Model.ProductPriceModel[]) {
    this.prices = v
  }
}
