import { Button } from '@takle/components/Button'
import { Award, Users } from 'tabler-icons-react'
import { Text } from '@takle/components/Text'
import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { Workspace } from '@takle/store/workspacesStore'
import { productMaxUsers } from '@takle/utils/productSettings'
import { CurrentAccount } from '@takle/store/accountsStore'
import { FT_SHOW_UPGRADE_BUTTON } from '@takle/constants'

type SubscriptionInfoProps = {
  account: CurrentAccount
  workspaces: Workspace[]
}

export const SubscriptionInfo: FC<SubscriptionInfoProps> = observer(
  ({ account, workspaces }) => {
    const navigate = useStrictNavigate()

    if (!account.subscriptionPlan) {
      if (!FT_SHOW_UPGRADE_BUTTON) return null
      return (
        <div className='flex items-center gap-md md:gap-lg p-sm md:p-md pr-md md:pr-lg border bg-800 border-blue rounded-sm md:rounded-md'>
          <Award size={48} className='text-600 hidden md:block' />
          <div className='flex flex-col flex-1'>
            <h3>
              <Text bold className='text-base md:text-lg'>
                You are on a Free plan right now.
              </Text>
            </h3>
            <Text className='text-200 hidden md:block'>
              Consider upgrading your plan to have a full history of your
              communications and lots of other features
            </Text>
          </div>
          <div>
            <Button
              color='orange'
              onClick={() => navigate(AuthorizedRoutePath.Upgrade, {})}
            >
              Upgrade<span className='hidden md:block ml-xxs'>now</span>
            </Button>
          </div>
        </div>
      )
    }

    const ownedWorkspaces = workspaces.filter(w => w.accountId === account.id)
    const totalUsersOnOwnedWorkspaces = Object.keys(
      ownedWorkspaces.reduce(
        (acc, w) => ({ ...acc, ...w.userIds }),
        {} as Record<string, boolean>,
      ),
    ).length
    const maxUsers = productMaxUsers[account.subscriptionPlan]
    const isMaxReached = totalUsersOnOwnedWorkspaces === maxUsers
    const showUsersWarningNotification =
      totalUsersOnOwnedWorkspaces > maxUsers * 0.9

    if (!showUsersWarningNotification) return null

    return (
      <div className='flex items-center gap-md md:gap-lg p-sm md:p-md pr-md md:pr-lg border bg-800 border-red rounded-sm md:rounded-md'>
        <Users size={48} className='text-600 hidden md:block' />
        <div className='flex flex-col gap-xs flex-1'>
          <Text bold className='text-lg'>
            You've{isMaxReached ? '' : ' almost'} reached your maximum users
            limit – {totalUsersOnOwnedWorkspaces} of {maxUsers} members.
          </Text>
          <Text className='text-200 hidden md:block'>
            New users would not be able to join to your workspaces
            {isMaxReached ? '' : ' soon'}.
            {FT_SHOW_UPGRADE_BUTTON
              ? 'Consider upgrading your subscription plan or remove inactive users from your workspaces.'
              : 'Consider removing inactive users from your workspaces.'}
          </Text>
        </div>
        {FT_SHOW_UPGRADE_BUTTON && (
          <div>
            <Button
              color='orange'
              onClick={() => navigate(AuthorizedRoutePath.Upgrade, {})}
            >
              Upgrade<span className='hidden md:block ml-xxs'>now</span>
            </Button>
          </div>
        )}
      </div>
    )
  },
)
