import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Award, AwardOff } from 'tabler-icons-react'

import { store } from '@takle/store'
import { LinkButton } from '@takle/components/Button'
import { Text } from '@takle/components/Text'
import { Caption } from '@takle/components/Caption'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { FT_SHOW_UPGRADE_BUTTON } from '@takle/constants'

export const CurrentPlanCard: FC = observer(() => {
  const currentAccount = store.accountsStore.currentAccount
  const stripeLink = store.usersStore.userById(
    currentAccount.ownerId,
  ).stripeLink
  const navigate = useStrictNavigate()

  if (!FT_SHOW_UPGRADE_BUTTON) return null

  return (
    <div className='bg-900 border border-600 rounded-xs m-xs flex items-center self-stretch px-md py-xs  justify-between'>
      {currentAccount.subscriptionPlan ? (
        <>
          <div className='flex items-center gap-md'>
            <Award className='text-green' />
            <div className='leading-3'>
              <Caption label='current plan' />
              <Text bold>{currentAccount.subscriptionPlan}</Text>
            </div>
          </div>
          {stripeLink && (
            <LinkButton
              onClick={() => {
                var win = window.open(stripeLink, '_blank')
                win?.focus()
              }}
            >
              Manage
            </LinkButton>
          )}
        </>
      ) : (
        <>
          <div className='flex items-center gap-md'>
            <AwardOff className='text-600' />
            <div className='leading-3'>
              <Caption label='current plan' />
              <Text bold>Takle Free</Text>
            </div>
          </div>
          <LinkButton
            onClick={() => navigate(AuthorizedRoutePath.Upgrade, {})}
            bold
            color='orange'
          >
            Upgrade
          </LinkButton>
        </>
      )}
    </div>
  )
})
