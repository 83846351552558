import { FC } from 'react'

import { Button } from '@takle/components/Button'
import { HeaderBarWithLinks } from '@takle/components/HeaderBar/HeaderBarWithLinks'
import { Text } from '@takle/components/Text'
import { useStrictNavigate } from '@takle/navigation/paths'
import { UnauthorizedRoutePath } from '@takle/navigation/UnauthorizedRoutes'
import { PageGradientBackground } from '@takle/components/PageGradientBackground/PageGradientBackground'

const DEFAULT_CONTAINER_STYLE =
  'flex flex-col self-center px-md md:px-lg lg:px-xxl w-full max-w-7xl relative'

export const LandingPage: FC = () => {
  const navigate = useStrictNavigate()

  return (
    <>
      <PageGradientBackground />
      <div className={`${DEFAULT_CONTAINER_STYLE} mt-xl`}>
        <HeaderBarWithLinks />
        <div className='flex flex-col max-w-lg gap-xl my-xxxl'>
          <Text testId='landing-title' size='display' color='200' bold>
            Smooth & organized communication outside your team
          </Text>
          <Text size='lg'>
            Messenger for IT companies building strong customer relationships
          </Text>
          <div>
            <Button onClick={() => navigate(UnauthorizedRoutePath.SignUp, {})}>
              Try for Free
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
