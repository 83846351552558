import { FC } from 'react'

import { PopupButton } from '@takle/components/Popup'
import { Text } from '@takle/components/Text'
import { At } from 'tabler-icons-react'
import { Button, ButtonProps } from '../Button'
import { TackleLogo } from '../icons/TakleLogo'

export const EMAIL = 'support@takle.app'
export const TELEGRAM = 'https://t.me/PeshkovAndrey'
export const WORKSPACE =
  'https://web.takle.app/join?invitation_id=4c3ae78e-a72f-49e4-8621-94cf1fa0254e'

const buttonStyle =
  'flex items-center px-lg py-md hover:bg-700 w-64 cursor-pointer'

export const ContactButton: FC<ButtonProps> = buttonProps => {
  return (
    <PopupButton
      renderButton={openPopup => (
        <Button bordered onClick={openPopup} {...buttonProps}>
          <At size={18.5} className='text-blue mr-xxs' />
          <span className='hidden md:block'> Contact us</span>
        </Button>
      )}
    >
      {onClose => (
        <>
          <div
            onClick={e => {
              onClose(e)
              window.open(`mailto:${EMAIL}`, '_blank')
            }}
            className={buttonStyle}
          >
            <At size={21} className='text-400 mr-sm' /> <Text>Email us</Text>
          </div>
          <div
            onClick={e => {
              onClose(e)
              window.open(WORKSPACE, '_blank')
            }}
            className={`border-t border-600 ${buttonStyle}`}
          >
            <div className='w-4 overflow-hidden  mr-4 '>
              <TackleLogo height={20} fill='rgba(103, 113, 129, 1)' />{' '}
            </div>

            <Text>Contact with CEO in Takle</Text>
          </div>
        </>
      )}
    </PopupButton>
  )
}
