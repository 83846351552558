import {
  setAnalyticsProviders,
  trackConsoleEvent,
  trackFacebookEvent,
  trackFirebaseEvent,
} from '@takle/analytics'
import { __DEV__ } from '@takle/constants'
import { getEnv } from './getEnv'
import { parseBoolEnv } from './parseBoolEnv'

//Not required variables
export const DEV_EMULATORS_ENABLED =
  __DEV__ && parseBoolEnv(process.env.REACT_APP_ENABLE_EMULATORS_IN_DEV || null)
export const disableQuestionnaire = parseBoolEnv(
  process.env.REACT_APP_FT_DISABLE_QUESTIONNAIRE || null,
)

//Required variables
export const firebaseApiKey = getEnv('REACT_APP_FIREBASE_API_KEY')
export const firebaseDatabaseUrl =
  getEnv('REACT_APP_FIREBASE_DATABASE_URL') || undefined
export const firebaseAuthDomain = getEnv('REACT_APP_FIREBASE_AUTH_DOMAIN')
export const firebaseProjectId = getEnv('REACT_APP_FIREBASE_PROJECT_ID')
export const firebaseStorageBucket = getEnv('REACT_APP_FIREBASE_STORAGE_BUCKET')
export const firebaseMessageSenderId = getEnv(
  'REACT_APP_FIREBASE_MESSAGING_SENDER_ID',
)
export const firebaseMessageAppId = getEnv('REACT_APP_FIREBASE_APP_ID')
export const firebaseMeasurementId = getEnv('REACT_APP_FIREBASE_MEASUREMENT_ID')

// Analytics
if (__DEV__) {
  setAnalyticsProviders([trackConsoleEvent])
} else {
  setAnalyticsProviders([trackFirebaseEvent, trackFacebookEvent])
}
