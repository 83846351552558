import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ActivityIndicator } from '@takle/components/ActivityIndicator'
import { Text } from '@takle/components/Text'
import { Logo } from '@takle/components/Logo'
import { Button, LinkButton } from '@takle/components/Button'
import { Input } from '@takle/components/Input'
import { isEmail } from '@takle/utils/isEmail'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '@takle/localStorage'
import { SearchParams } from '@takle/constants'
import { store } from '@takle/store'
import { handleError } from '@takle/sentry'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { UnauthorizedRoutePath } from '@takle/navigation/UnauthorizedRoutes'
import { createInviteUrl } from '@takle/components/InviteModal'
import { makeInternalUrl, makeExternalUrl } from '@takle/navigation/paths'

enum GAuthErrCode {
  InvalidActionCode = 'auth/invalid-action-code',
  // TODO: wrong email
}

enum TakleErrCode {
  InvalidEmail = 'takle/invalid-email',
}

export const CompleteRegistrationPage: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [initialized, setInitialized] = useState(false)

  const [fetching, setFetching] = useState(false)
  const [errorCode, setErrorCode] = useState<string | null>(null)
  const [email, setEmail] = useState('')

  const onEmailLogin = () => {
    if (!isEmail(email)) {
      return setErrorCode(TakleErrCode.InvalidEmail)
    }
    loginWithEmail(email)
  }

  const loginWithEmail = useCallback(
    async (email: string) => {
      setFetching(true)
      try {
        await store.usersStore.signInWithLink({
          email,
          url: makeExternalUrl(window.location.href),
        })
        const invitationId = searchParams.get(SearchParams.InvitationId)
        removeLocalStorageItem('emailForSignIn')

        if (invitationId) {
          window.location.href = makeInternalUrl(createInviteUrl(invitationId))
          return
        }
        navigate(AuthorizedRoutePath.Dashboard)
      } catch (e) {
        handleError(e)
        setErrorCode((e as any)?.code)
      }
      setFetching(false)
    },
    [navigate, searchParams],
  )

  useEffect(() => {
    const init = async () => {
      const emailForSignIn = getLocalStorageItem('emailForSignIn')
      if (
        emailForSignIn &&
        store.usersStore.isValidSignInLink(window.location.href)
      ) {
        await loginWithEmail(emailForSignIn)
      }
      setInitialized(true)
    }

    init()
  }, [loginWithEmail])

  const renderContent = () => {
    if (!initialized) return <ActivityIndicator />

    if (errorCode === GAuthErrCode.InvalidActionCode) {
      return (
        <>
          <div className='text-center'>
            <h1 className='mb-md'>
              <Text bold size='display'>
                Ooops
              </Text>
            </h1>
            <Text bold size='lg' color='400'>
              The authorization link is invalid
            </Text>
          </div>
          <LinkButton onClick={() => navigate(UnauthorizedRoutePath.Login, {})}>
            Please try log in again
          </LinkButton>
        </>
      )
    }

    return (
      <>
        <div className='text-center'>
          <h1 className='mb-md'>
            <Text bold size='display'>
              Complete registration
            </Text>
          </h1>
          <Text bold size='lg' color='400'>
            Please provide your email you've been using to register
          </Text>
        </div>
        <div className='self-stretch'>
          <Input
            value={email}
            showError={
              errorCode ? 'Please check your email and try once again' : false
            }
            placeholder='your@email.here'
            onChange={e => setEmail(e.target.value)}
            className='w-full'
          />
          <Button
            isFetching={fetching}
            className='mt-md w-full'
            disabled={!email || fetching}
            onClick={onEmailLogin}
          >
            Continue
          </Button>
        </div>
        <LinkButton onClick={() => navigate(UnauthorizedRoutePath.Login, {})}>
          Back to login
        </LinkButton>
      </>
    )
  }

  return (
    <div className='flex flex-1 flex-col justify-center items-center'>
      <div className='flex flex-col gap-xxl items-center max-w-sm mx-xl my-lg'>
        <Logo />
        {renderContent()}
      </div>
    </div>
  )
}
