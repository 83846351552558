import { collection, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore'
import * as Model from '@takle/models/models'
import { Collections, timestamp } from './constants'
import { converter } from '@takle/firebase/converter'
import { fireStore, firebaseFunctions } from './firebase'
import { httpsCallable } from 'firebase/functions'

/*

Users

*/

enum UserCallableFunctions {
  // Use when user deactivation is needed
  // UpdateAndDeactivateUser = 'v2UpdateAndDeactivateUser',
  DeleteUser = 'v2DeleteUser',
}

export function getUsersCollectionRef() {
  return collection(fireStore, Collections.Users).withConverter(converter)
}

export function getUserDocRef(uid: string) {
  return doc(fireStore, Collections.Users, uid).withConverter(converter)
}

export function getUserOpenedChannelDocRef(uid: string, clientId: string) {
  return doc(
    fireStore,
    Collections.Users,
    uid,
    Collections.OpenedChannels,
    clientId,
  )
}

type SetChannelOpenedProps = {
  userId: string
  isOpened: boolean
  clientId: string
  channelId: string
}

export async function setChannelOpened({
  isOpened,
  userId,
  clientId,
  channelId,
}: SetChannelOpenedProps) {
  const now = new Date()
  const openedChannelDocRef = getUserOpenedChannelDocRef(userId, clientId)

  if (!isOpened) {
    const openedChannelDoc = await getDoc(openedChannelDocRef)
    if (!openedChannelDoc.exists()) return

    const doc = await Model.decode(
      openedChannelDoc.data(),
      Model.TOpenedChannel,
    )
    if (!!doc.openedAt && doc.openedAt.valueOf() > now.valueOf()) return

    return await deleteDoc(openedChannelDocRef)
  }

  const openedDoc: Model.OpenedChannel = {
    userId,
    clientId,
    channelId,
    openedAt: timestamp(),
  }

  return await setDoc(openedChannelDocRef, openedDoc)
}

export async function updatedUserData(
  user: Partial<Model.UserModelToSave> & { id: string },
) {
  return await setDoc(getUserDocRef(user.id), user, { merge: true })
}

export const deleteUserCallable = (userId: string) =>
  httpsCallable(
    firebaseFunctions,
    UserCallableFunctions.DeleteUser,
  )(userId)

// Use when user deactivation is needed
// type UpdateAndDeactivateUserCallableParams = {
//   user?: Partial<Model.UserModel> & {password: string},
//   userId: string
// }

// const updateAndDeactivateUserCallable = (params: UpdateAndDeactivateUserCallableParams) =>
//   httpsCallable<UpdateAndDeactivateUserCallableParams>(
//     firebaseFunctions,
//     UserCallableFunctions.UpdateAndDeactivateUser,
//   )(params)



