import { FC } from 'react'
import {
  ChevronLeft,
  Dots,
  InfoSquare,
  Settings,
  UserPlus,
} from 'tabler-icons-react'
import { observer } from 'mobx-react-lite'

import { Logo } from '@takle/components/Logo'
import { store } from '@takle/store'

import { UserButton } from './UserButton'
import { useStrictNavigate } from '@takle/navigation/paths'
import { AuthorizedRoutePath } from '@takle/navigation/AuthorizedRoutes'
import { ContactButton } from './ContactButton'
import { ChannelAvatar } from '../ChannelAvatar'
import { ChannelEditModalButton } from '../ChannelEditModal'
import { IconButton } from '../Button'
import { AddPeopleButton } from '@takle/pages/WorkspacePage/components/AddPeopleButton'
import { Workspace } from '@takle/store/workspacesStore'
import { PopupButton } from '../Popup'

type ChannelInfoControlProps = {
  isSidebarOpened: boolean
  setSidebarOpened: (v: boolean) => void
}

type HeaderBarProps = {
  showUser?: boolean
  onMobileBackPress?: () => void
  channelId?: string
  directChannelId?: string
  workspace?: Workspace
  onOpenChannel?: (channelId: string) => void
} & Partial<ChannelInfoControlProps>

export const HeaderBar: FC<HeaderBarProps> = observer(
  ({
    onMobileBackPress,
    showUser = true,
    channelId,
    workspace,
    onOpenChannel,
    isSidebarOpened,
    setSidebarOpened,
    directChannelId,
  }) => {
    const user = store.usersStore.currentLoggedInUser
    const currentAccount = store.accountsStore.currentAccount
    const navigate = useStrictNavigate()
    const channel = store.channelsStore.channels.find(c => c.id === channelId)
    const directChannel =
      directChannelId && store.channelsStore.directChannelById(directChannelId)

    const channelOwner =
      store.usersStore.currentLoggedInUser.id === channel?.ownerId

    const workspaceOwner = currentAccount?.id === workspace?.accountId

    return (
      <div className='flex self-stretch items-center bg-700 px-lg py-md justify-between'>
        <Logo
          onClick={() => navigate(AuthorizedRoutePath.Dashboard, {})}
          className={`${onMobileBackPress ? `hidden` : ''} md:block`}
        />
        <ChevronLeft
          size={35}
          className={
            onMobileBackPress
              ? 'md:hidden cursor-pointer text-blue -my-5'
              : 'hidden'
          }
          onClick={onMobileBackPress}
        />
        {channel && (
          <div
            className={
              onMobileBackPress
                ? 'md:hidden flex items-center justify-between flex-1 ml-sm min-h-[27.5px]'
                : 'hidden'
            }
          >
            <ChannelAvatar
              channel={channel}
              size='sm'
              className='text-200 max-w-[200px] sm:max-w-full'
            />
            <PopupButton
              renderButton={openPopup => (
                <IconButton size='sm' onClick={openPopup}>
                  <Dots />
                </IconButton>
              )}
            >
              {onClose => (
                <div className='flex gap-md p-xs'>
                  {(channelOwner || workspaceOwner) && (
                    <ChannelEditModalButton
                      channel={channel}
                      onCloseParent={onClose as any}
                      renderButton={onOpenModal => (
                        <IconButton onClick={onOpenModal}>
                          <Settings />
                        </IconButton>
                      )}
                      onOpenChannel={onOpenChannel}
                    />
                  )}
                  {workspace && (
                    <AddPeopleButton
                      workspace={workspace}
                      channel={channel}
                      onCloseParent={onClose as any}
                      renderButton={onOpenModal => (
                        <IconButton onClick={onOpenModal}>
                          <UserPlus />
                        </IconButton>
                      )}
                    />
                  )}
                  {!!setSidebarOpened && (
                    <IconButton
                      active={isSidebarOpened}
                      onClick={e => {
                        onClose(e)
                        setSidebarOpened(!isSidebarOpened)
                      }}
                    >
                      <InfoSquare />
                    </IconButton>
                  )}
                </div>
              )}
            </PopupButton>
          </div>
        )}

        {directChannel && (
          <div
            className={
              onMobileBackPress
                ? 'md:hidden flex items-center justify-between flex-1 ml-sm min-h-[27.5px]'
                : 'hidden'
            }
          >
            <ChannelAvatar
              channel={directChannel}
              size='sm'
              className='text-200'
            />
            {!!setSidebarOpened && (
              <IconButton
                size='sm'
                active={isSidebarOpened}
                onClick={() => {
                  setSidebarOpened(!isSidebarOpened)
                }}
              >
                <InfoSquare />
              </IconButton>
            )}
          </div>
        )}

        <div
          className={
            onMobileBackPress
              ? 'hidden md:flex items-center gap-md'
              : 'flex items-center gap-md'
          }
        >
          <ContactButton size='sm' />
          <UserButton
            user={user}
            className={!showUser ? 'hidden' : 'hidden md:flex'}
          />
        </div>
      </div>
    )
  },
)
