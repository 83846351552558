import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useRef, useState } from 'react'
import { isSameDay } from 'date-fns'

import {
  Channel,
  DirectChannel,
  Message,
  PendingMessage,
} from '@takle/store/channelsStore'

import { ChannelOverview } from './ChannelOverview'
import { UserMessage } from './UserMessage/UserMessage'
import { CHANNEL_MESSAGES_SHOW_DATE_DIFF } from '@takle/constants'
import { ListSeparator } from './ListSeparator'
import { formatRelativeDate } from '@takle/utils/format'
import { store } from '@takle/store'

type VirtualizedListProps = {
  channel: Channel | DirectChannel
}

const YOUR_MOM_WEIGHT = 9999999

export const VirtualizedMessagesList: FC<VirtualizedListProps> = observer(
  ({ channel }) => {
    const currentUser = store.usersStore.currentLoggedInUser
    const [isScrolling, setIsScrolling] = useState(false)

    const firstUnreadMessageId = useRef(
      channel.unreadInfo?.firstUnreadMessageId,
    ).current
    const virtuosoRef = useRef<VirtuosoHandle>(null)

    const [firstVisibleMessage, setFirstVisibleMessage] = useState<
      Message | PendingMessage | null
    >(null)

    const messages = channel.messagesAndPendingMessages
    const firstItemIndex = YOUR_MOM_WEIGHT - messages.length
    const lastItemIndex = messages.length - 1

    const rangeChanged = useCallback(
      ({ startIndex }: { startIndex: number }) => {
        const index = startIndex - firstItemIndex

        const message = index !== 0 && messages[index]
        setFirstVisibleMessage(message || null)
      },
      [firstItemIndex, messages],
    )

    const onImageLoad = useCallback(
      (itemIndex: number, userId: string) => {
        const sentByCurrentUser = userId === currentUser.id
        const isLastMessage = itemIndex === lastItemIndex

        if (isLastMessage && !isScrolling && sentByCurrentUser) {
          virtuosoRef.current?.scrollToIndex({
            index: lastItemIndex,
          })
        }
      },
      [currentUser.id, isScrolling, lastItemIndex],
    )

    const itemContent = useCallback(
      (index: number, message: Message | PendingMessage) => {
        const i = index - firstItemIndex
        const prevMessage = i > 0 ? messages[i - 1] : undefined
        const showDate =
          (!channel.hasMoreToLoad && i === 0) ||
          (!!prevMessage &&
            !isSameDay(message.createdAt, prevMessage.createdAt))

        const showUser =
          !prevMessage ||
          prevMessage?.userId !== message.userId ||
          message.createdAt.valueOf() - prevMessage.createdAt.valueOf() >
            CHANNEL_MESSAGES_SHOW_DATE_DIFF

        return (
          <UserMessage
            key={message.id}
            isEditing={channel.editingMessage?.id === message.id}
            className={showUser ? 'px-lg py-xs' : 'px-lg pt-xxs pb-xs'}
            showDate={showDate}
            showUser={showDate || showUser}
            message={message}
            showUnread={message.id === firstUnreadMessageId}
            onImageLoad={() => onImageLoad(i, message.userId)}
          />
        )
      },
      [
        firstItemIndex,
        messages,
        firstUnreadMessageId,
        channel.editingMessage?.id,
        channel.hasMoreToLoad,
        onImageLoad,
      ],
    )

    return (
      <>
        <Virtuoso
          ref={virtuosoRef}
          alignToBottom
          rangeChanged={rangeChanged}
          className='flex-1 mb-xs'
          data={messages}
          atTopThreshold={50}
          firstItemIndex={firstItemIndex}
          atBottomThreshold={30}
          initialTopMostItemIndex={messages.length - 1}
          followOutput={atBottom => atBottom}
          isScrolling={setIsScrolling}
          components={{
            Header: () => (
              <ChannelOverview
                channel={channel}
                className='px-lg pt-xxl pb-lg'
              />
            ),
          }}
          itemContent={itemContent}
        />
        {firstVisibleMessage && (
          <ListSeparator
            containerClassName='absolute left-0 top-[64px] right-0'
            className='bg-800 border border-600 !text-400'
            label={formatRelativeDate(firstVisibleMessage.createdAt)}
          />
        )}
      </>
    )
  },
)
