import firestore from 'firebase/firestore'

export type Document = {
  id: string
}

export type DocumentWithOptionalId = {
  id?: string
}

export const converter = {
  toFirestore<T extends DocumentWithOptionalId>(
    model: T,
  ): firestore.DocumentData {
    const { id, ...rest } = model

    return rest
  },
  fromFirestore<T extends Document>(
    snapshot: firestore.QueryDocumentSnapshot,
    options: firestore.SnapshotOptions,
  ): T {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      ...data,
    } as unknown as T
  },
}
