import { FC } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { store } from '@takle/store'
import { Alert as AlertModel } from '@takle/store/uiStore'
import { Portal } from './Portal'
import { Text } from './Text'

export const Alert: FC<AlertModel> = ({
  message,
  title,
  preventDismiss,
  buttons,
}) => {
  const onClose = () => store.uiStore.setAlert(null)
  const fadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  })

  const fadeInFromBottom = useSpring({
    from: {
      translateY: 20,
      opacity: 0,
    },
    to: {
      translateY: 0,
      opacity: 1,
    },
    config: {
      tension: 600,
      friction: 50,
      mass: 1,
    },
    delay: 200,
  })

  return (
    <Portal layer='modal'>
      <div className='flex w-screen h-screen items-center justify-center'>
        <animated.div
          style={fadeIn}
          className='absolute w-screen h-screen top-0 left-0 bg-800/40'
          onClick={preventDismiss ? undefined : onClose}
        />
        <div className='px-sm w-full'>
          <animated.div
            data-testid='alert'
            style={fadeInFromBottom}
            className='bg-800 relative rounded-sm mx-auto w-full md:w-72 lg:w-80 px-sm pt-lg pb-sm ring ring-3 ring-blue/50 flex flex-col gap-md'
          >
            {title && (
              <Text color='200' size='lg' bold className='text-center px-md'>
                {title}
              </Text>
            )}
            <Text color='0' className='text-center px-md'>
              {message}
            </Text>
            <div className='flex items-center gap-sm'>
              {buttons.length ? (
                buttons.map((b, i) => (
                  <AlertButton
                    key={i}
                    onClick={() => {
                      b.onClick?.()
                      onClose()
                    }}
                  >
                    {b.label}
                  </AlertButton>
                ))
              ) : (
                <AlertButton onClick={onClose}>Done</AlertButton>
              )}
            </div>
          </animated.div>
        </div>
      </div>
    </Portal>
  )
}

const AlertButton: FC<{ onClick: () => void }> = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className='flex-1 text-center cursor-pointer hover:bg-blue/10 rounded-xs p-sm'
    >
      <Text className='text-center' bold color='blue'>
        {children}
      </Text>
    </div>
  )
}
